<template>
    <div class="ground-container"> 
        <div class="ground-form-container" >
            <form id="gullyapplication" novalidate autocomplete="off" >
                <div id="Printcontent">
                <u><h3 style="text-align: center;">ක්‍රීඩාංගණය වෙන් කිරීමේ අ‍යදුම්පත</h3></u>
                <br>
                <label for="iName" style="font-size: small;">ඉල්ලුම්කරුගේ නම:&nbsp; {{ cus_name }}
                        </label> <br> 
                        <label for="iContact" style="font-size: small;">දුරකථන අංකය:&nbsp; {{ cus_tel }}
                        </label><br>
                        <label for="iAddr" style="font-size: small;">ලිපිනය:&nbsp; {{ cus_add }}
                        </label> <br>
                        <label for="iSport" style="font-size: small;">ක්‍රීඩාව:&nbsp;{{sport_}}
                        </label><br>
                        <label for="iDate" style="font-size: small;">ක්‍රීඩාංගණය ඉල්ලා සිටින දිනය:&nbsp;{{formattedDate(st_date)}} සිට {{formattedDate(end_date)}} 
                        </label><br>
                        <label for="iTime" style="font-size: small;">ක්‍රීඩා පවත්වන වේලාවන් :&nbsp;{{st_time}} සිට {{ end_time }}
                        </label><br>
<b><P style="font-size: small;">කොන්දේසි:</P></b>
    <ol>
      <li style="font-size: small;">එක් ක්‍රීඩා තරඟයක් සඳහා රු: ........................ක් 12% වැට් සමඟ රු: ..........................ක් ගෙවිය යුතුය. ඊට ‍වැඩිවන 
        සෑම පැයකටම රු: .................. බැගින් වැට් බද්ද .................. </li>
        <li style="font-size: small;">වෙන්කළ දිනට සම්පූර්ණ මුදල් ගෙවිය යුතුය.</li>
        <li style="font-size: small;">දිනය වෙනස් කරන්නේ නම් දින 03කට කලින් දැනුම් දීමක් කල යුතු අතර, නොඑසේ නම් ..........................
        .............. මුදලක් අය කරන අතර, ඉතිරි මුදල් පමණක් ආපසු ගෙවනු ලැබේ.</li>
        <li style="font-size: small;">ඔබ විසින් මුදල් ගෙවා ක්‍රීඩාංගණය භාරගත් දින සිට ආපසු භාරදෙන මොහොත දක්වා ක්‍රීඩාංගණයට හෝ ක්‍රීඩා භාණ්ඩ වලට හෝ 
        ක්‍රීඩා භූමියට අලාභයක් වුවහොත්, එම අලාභය ගෙවිය යුතුය.</li>
        <li style="font-size: small;">හදිසි රාජකාරි සඳහා අවශ්‍ය වුවහොත් එවැනි අවස්ථාවක මෙම වෙන් කිරීම අවලංගු කිරීමට නාගරික කොමසාරිස් වරයාට බලය තිබේ.</li>            
    </ol>
    <h5> ඉහත සඳහන් කොන්දේසි වලට එකඟ වෙමි.</h5>
    <table class="nobordertbl">
                        <tr>
                            <td style="text-align:left;width: 70%;">
                                දිනය: {{ new Date().toLocaleString() }}
                            </td>
                            <td style="text-align:left;width: 30%;">
                              .............................................................. 
                            </td>
                        </tr>
                        <tr>
                        <td style="text-align:left;width: 70%;">
                            
                            </td>
                            <td style="text-align:left;width: 30%;">
                                ඉල්ලුම්කරුගේ අත්සන
                            </td>
                        </tr>
                        </table>
    <!-- <h5>දිනය :&nbsp; {{ new Date().toLocaleString() }}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:........................ ඉල්ලුම්කරුගේ අත්සන</h5>
    <br> -->
    <hr>
                    <table class="nobordertbl">
                        <tr>
                            <td style="text-align:left;width: 50%;">
                                <p>ගෙවිය යුතු ගාස්තු</p>
                            </td>
                            <td style="text-align:left;width: 50%;">
                               <p>රු:</p> 
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:left;width: 50%;">
                                ක්‍රීඩාංගණය වෙන්කළ දින ගණන
                            </td>
                            <td style="text-align:left;width: 50%;">
                              ................................................
                            </td>
                        </tr>
                        <tr >
                            <td style="text-align:left;width: 50%;">දිනකට රු: ................ බැඟින් දින</td>
                       
                            <td style="text-align:left;width: 50%;">
                              ................................................</td>
                        </tr>
                        <tr >
                            <td style="text-align:left;width: 50%;">ක්‍රීඩා තරඟ ................ කට</td>
                       
                            <td style="text-align:left;width: 50%;">
                              ................................................</td>
                        </tr>
                        <tr >
                            <td style="text-align:left;width: 50%;">වැඩි වූ පැය ................. කට</td>
                       
                            <td style="text-align:left;width: 50%;">
                              ................................................</td>
                        </tr>
                        <tr >
                            <td style="text-align:left;width: 50%;">ජාතිය ගොඩනැගීමේ බද්ද</td>
                       
                            <td style="text-align:left;width: 50%;">
                              ................................................</td>
                        </tr>
                        <tr >
                            <td style="text-align:left;width: 50%;">වැට් බද්ද</td>
                       
                            <td style="text-align:left;width: 50%;">
                              ................................................</td>
                        </tr>
                        <tr >
                            <td style="text-align:left;width: 50%;">මුද්දර ගාස්තු</td>
                       
                            <td style="text-align:left;width: 50%;">
                              ................................................</td>
                        </tr>
                        <tr >
                            <td style="text-align:left;width: 50%;">මුළු එකතුව</td>
                       
                            <td style="text-align:left;width: 50%;">
                              ................................................</td>
                        </tr>
    </table>
    <br>
    <h5>නා.කො./තා.ඉ.</h5>
                    <h5> අනුමැතිය සඳහා ඉදිරිපත් කරමි.</h5>
                    <h5 style="text-align: right;">නා.කො./තා.ඉ.</h5>
                </div>
            </form>
       
        </div>
        <br>
        <div class="btnrow">
                <div style="padding-left: 35rem;">
                    <router-link to="/appground" class="btn" style="align-content: center;">Cancel</router-link>&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="button" value="Print" class=" btn" style="align-content: center;" @click="printContent">
                </div>
            </div>
    </div>
    
</template>
<script>

    import axios from 'axios';
    export default {
        name: "ApplicationGround",
    
       data (){
    
        return{
                nic:"",
                sabha: "",
                userLevel:"",
                // invoicesavedetails:[],
                invoicedetails:[],
                searchinvoice:'',
                sabhadetail:[],
                matchUser:'',
                bid:'',
                cus_name:"",
                cus_add:"",
                cus_tel:"",
                sport_:"",
                st_date:"",
                end_date:"",
                st_time:"",
                end_time:"",
        }
       },
    
       created(){
        this.getDataFromSessionStorage()
       
       },
       computed : {
               
                
            },
    
       methods:{
       
        async getDataFromSessionStorage(){
                const data = JSON.parse(sessionStorage.getItem('userData'))
                if(data){
                                this.nic = data.nic 
                                this.sabha = data.sabha
                                this.userLevel =data.userLevel
                                // this.userName = data.userName
                            }
    
                this.searchinvoice = this.$route.params.data
                 this.sabhadetail= (await axios.get('/pra_sabha/' +this.sabha)).data
                 //get invoice save table data
                //  this.invoicesavedetails= (await axios.get('/pra_sabha/' +this.sabha)).data
                 this.invoicedetails = (await axios.get('/invoicesaved/' +this.sabha+'/'+this.searchinvoice)).data
                this.getMatchUser(this.nic)
               
               const bookingData = JSON.parse(sessionStorage.getItem('bookingData'))
                if(bookingData){
                                this.cus_name = bookingData.req_name 
                                this.cus_add = bookingData.req_address
                                this.cus_tel =bookingData.tel
                                this.bid=bookingData.reqid
                                this.sport_=bookingData.req_reason
                                this.st_date =bookingData.req_date_from
                                this.end_date=bookingData.req_date_to
                                this.st_time=bookingData.req_start_time
                                this.end_time=bookingData.req_end_time
                                // this.userName = data.userName
                            }
             
            },
            async getMatchUser(nic) {
                // await axios.get('/employee/' + nic);
                let data = await axios.get('/employee/' + nic);
                this.matchUser = data.data;
            },
            formattedDate(bkdate) {
                const date = new Date(bkdate)
            const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
          },
            async printContent() {
                
                let updateprocess={
                   payment_status : "processing"
               }
               await axios.put("/gullyrequestupda/"+this.bid,updateprocess, )
       
               const prtHtml = document.getElementById('Printcontent').innerHTML;
               const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                WinPrint.document.write(`    
        ${prtHtml}
    `);
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
                   this.$router.push('/bkgully');
               }
        },
    
    
            
    
    };
    </script>
    <style>
    .nobordertbl{
        border-collapse: collapse;
        border: none;
        font-size: 1.2rem;
        width:100%;
    }
    .nobordertbl td{
        border: none;
    }
    .ttable {
         
         width: 100%;
         border-width:1px;
         border-color : #130f40;
         /* border-style: solid; */
         font-size: 0.8rem;
        text-align: right;
         /* background-color :white; */
         /* padding-left: 3rem; */
          /* height: 3rem; */
          border-collapse: collapse;
          
     }
     th  {
         text-align: center;
    }
    .ttable, th, td {
    border: 1px solid;
    /* padding-right: 0.7rem; */
    }
    .nobordertbl{
        border-collapse: collapse;
        border: none;
    }
    .nobordertbl td{
        border: none;
    }
    .ground-container {
        background-color: #fff;
        /* height: 100vh; */
    /* form width controls from padding 20% */
        padding: 2rem 20%; 
        font-size: 16px;
        align-content: center;
        /* min-height: 72.3vh; */
    }
    .ground-container .ground-form-container {
        background: #fff;
    
    }
    
    .ground-container .ground-form-container .heading{
       padding: 0%;
    
    }
    
    .ground-container .ground-form-container form {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%);
        max-width: 100rem;
        width: 100%;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
        border: 0.1rem solid rgba(0, 0, 0, 0.2);
        padding: 2rem;
        border-radius: 0.5rem;
        animation: fadeUp 0.4s linear;
        padding-left: 2rem;
    }
    .h4 {
        /* padding-bottom: 1rem; */
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #130f40;
        margin: 0;
    }
    .ground-container .ground-form-container form h3 {
        /* padding-bottom: 1rem; */
        font-size: 1.3rem;
        text-transform: uppercase;
        color: #130f40;
        margin: 0;
    }
    .ground-container .ground-form-container form .table {
        /* margin: 1rem 0; */
        width: 100%;
        /* border-style: solid; */
        font-size: 1.2rem;
        /* background-color : #e9e4e9; */
        padding-left: 2rem;
         /* height: 3rem; */
         border-color : #130f40;
         /* border-width:2px; */
         border-top: 0.5px solid;
         border-left: 0.5px solid;
         border-right: 0.5px solid;
         border-bottom: 0.5px solid;
    }
    .ground-container .ground-form-container form .table .td {
        /* margin: 1rem 0; */
        width: 40%;
        vertical-align: middle;
        border: 0.5px solid;
        border-bottom: 0.5px solid;
    }
    .ground-container .ground-form-container .form-group {
        margin:0;
        font-size: 1.3rem;
        padding-top: 0%;
        padding-bottom: 0%;
    } 
    .ground-container .ground-form-container .form-group1 {
        margin:0;
        font-size: 1.2rem;
    } 
    </style>