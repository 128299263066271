<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="Shop-container">
        <div class="row1">
           <router-link to="/asesdash" class="close-btn" > Close </router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h1>Manage Rates</h1>
       </div>
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
                <div class="heading">
                    <b><h3>Update Warrant Cost Rate</h3></b>
                </div>
               
               <div class="row">
                <div class="col-sm-3">
                   <label for="Splace">Ward
                   </label>
                       <div><select v-model="CatObj.ward" id="ward" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in savedWards" :key="m.ward_id" id="Hid"  
                            v-bind:value =m.ward_id>
                               <div>{{m.ward }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.wardErr.length > 0">{{ errObj.wardErr[0] }}</p>
               </div>
               <div class="col-sm-3">
                   <label for="street">Street
                   </label>
                       <div><select v-model="CatObj.street" id="street" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in savedStreets" :key="m.street_id" id="Hid"  
                            v-bind:value =m.street_id>
                               <div>{{m.street_name }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.stretErr.length > 0">{{ errObj.stretErr[0] }}</p>
               </div>
               <div class="col-sm-3">
                   <label for="cat">Main Category
                   </label>
                       <div><select v-model="CatObj.mainc" id="cat" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in maincate" :key="m.cat_id" id="Hid"  
                            v-bind:value =m.cat_id>
                               <div>{{m.category_name }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.maincErr.length > 0">{{ errObj.maincErr[0] }}</p>
               </div>
               <div class="col-sm-2">
                   <label for="Snum">Rate:(%)
                   </label>
                   <input type="text" name="Snum" id="Snum" class="form-control" 
                     v-model="CatObj.subc" />
                    <p class="error-mess" v-if="errObj.subcErr.length > 0">{{ errObj.subcErr[0] }}</p>
               </div>
               <div class="Addbtn col-sm-1">
                   <label for="s">
                   </label>
                   <input type="button" value="Update" id="Addpay" class=" btn" @click="addrate()"  /> 
               </div>
           </div>
           <br>
           <div style="width: 100%; text-align: center;">
                <h3>Existing Warrant Cost Percentages</h3>
            </div>
           <div class="row">
            <table style="width: 90%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
               <thead>
                   <tr>   
                        <th>Index</th>
                       <th>Ward</th>
                       <th>Street</th>
                       <th>Category</th>
                       <th>Rate(%)</th>
                       <!-- <th>Edit</th> -->
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">
                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                    <td style="text-align: left;">{{ getWardName(s.ward_id) }}</td>
                    <td style="text-align: left; width: 40%;">{{ getStreetName(s.street_id)}}</td>
                       <td style="text-align: left;">{{ getCategoryName(s.cat_id ) }}</td>
                       <td style="text-align: left;">{{ s.warrant_cost_rate  }}</td>
                       <!-- <td><router-link to="" @click="openPopup(s.sub_id)"  class="fas fa-edit" style="color: darkgreen;"> </router-link></td> -->
                   </tr>
               </tbody>
           </table>
           </div>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
           </form>
       </div>
</div>
<!-- end -->
</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {

    name: "AssessRates",

    data() {
       return {
           nic:"",
           sabha: "",
           userLevel:"",
           CatObj : { ward:"", street:"",  mainc:"",subc:"" },
           errObj:{ wardErr:[], stretErr:[],maincErr:[], subcErr:[]},
           savedWards:[],
           savedStreets:[],
           allRates:[],
           itemsPerPage: 10,
            currentPage: 1,
            maincate:[]
       };
   },
   created() {
       this.getDataFromSessionStorage()

   },
   computed:{
       ...mapState(["user"]),
      
    paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.allRates)) {
        return this.allRates.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.allRates.length / this.itemsPerPage);
    },

   },
   async mounted() {
    try {
        this.maincate = (await axios.get('/maincat/')).data
           //wards
           this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
           //streets
           this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;

    } catch (error) {
        console.error("Error fetching data", error);
    }
},
   methods: {
       ...mapMutations(["setUser"]),

       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }
           this.maincate = (await axios.get('/maincat/')).data
           this.allRates = (await axios.get('/rvbysbah/' +this.sabha)).data
           //wards
           this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
           //streets
           this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;

       },
       getWardName(ward_id) {
        const ward = this.savedWards.find(m => m.ward_id === ward_id);
        return ward ? ward.ward : 'Unknown';  // Return 'Unknown' if ward_id is not found
    },
    getStreetName(street_id) {
        const street = this.savedStreets.find(m => m.street_id === street_id);
        return street ? street.street_name : 'Unknown';
    },
    getCategoryName(cat_id) {
        const category = this.maincate.find(m => m.cat_id === cat_id);
        return category ? category.category_name : 'Unknown';
    },

nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  event.preventDefault();
},
prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  event.preventDefault();
},

       resetCheckErr: function () {
        this.errObj.wardErr =[];
        this.errObj.stretErr=[];
        this.errObj.maincErr = [];
        this.errObj.subcErr =[];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           if (!this.CatObj.ward) {
               this.errObj.wardErr.push("required");
           }
           if (!this.CatObj.street) {
               this.errObj.stretErr.push("required");
           }
           // main categories
           if (!this.CatObj.mainc) {
               this.errObj.maincErr.push("required");
           }
           if (!this.CatObj.subc) {
               this.errObj.subcErr.push("required");
           }
       },
       async addrate(e) {
        let result = confirm("By updating this, you will update the rate value for all properties in this category on the selected street within the selected ward. Are you sure you want to proceed?")
        if(result == true){
            this.checkForm();
            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                // update rate in properties of this specific category
                let updata ={
                    prop_rate:this.CatObj.subc
                }
                await axios.put("/upratethreecat/"+this.CatObj.ward+"/"+this.CatObj.street+"/"+this.CatObj.mainc+"/"+this.sabha,updata)

                // add to rates table
                    let data = {
                        ward_id:this.CatObj.ward,
                        street_id:this.CatObj.street,
                        cat_id:this.CatObj.mainc,
                        warrant_cost_rate:this.CatObj.subc,
                        sb_code:this.sabha
                    }
                    await axios.post("/savrate/", data)
                    this.$refs.alert.showAlert('success', 'Added Successfully !')
                    this.allRates = (await axios.get('/rvbysbah/' +this.sabha)).data
                        this.CatObj.ward="",
                        this.CatObj.street="",
                        this.CatObj.mainc="",
                        this.CatObj.subc=""
            }
        }
        },

   },
   components: {
        VueBasicAlert
    }
};

</script>

<style scoped>
.row1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .close-btn {
  /* align-items: right; */
  background-color:rgb(121, 23, 12);
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #f8f4f4;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 8px;
  border-radius: 8px;
  width:6%;
  height: 4%;
  text-align: center;
}

.close-btn:hover {
  color: rgb(220, 18, 18);
  transform: scale(1.2);
  background-color:  #022e2a; 
}

.close-btn:focus {
  outline:none; /* Remove default focus outline */
}
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}
/* shroff form container */
.Shop-container {
   background-color: #ffffff09;
   height: 90vh;
   padding: 1rem 2%;
   font-size: 16px;
}

/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   /* margin: 1rem 0; */
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
    height: 3rem;
    color: #fffbfb;
  
   
}
/* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
} */
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: rgb(243, 47, 47);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
</style>