
<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <section>
    
    <div class="register-container">
        <div class="row1">
           <router-link to="/asesdash" class="close-btn" > Close </router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h1>Manage Property Transfers</h1>
       </div>

        <br><br>
        <div class="register-form-container">
            <form id="ShopDetailForm" novalidate autocomplete="off" >
                <div class="heading">
            <h3><b>Change Property Owners</b></h3>
            </div>
            <h4><i>Transfer Details</i></h4>
            <div class="row">
                <div class="form-group col-sm-4">
                    <label for="usedn"> <b>Property ID:</b> </label>
                    <div>
                        <input 
                        v-model="obj.prop" 
                        id="wrd" 
                        list="sabaPropList" 
                        class="form-control"
                        placeholder="Choose or type a property ID"
                        />
                        
                        <datalist id="sabaPropList">
                        <option 
                            v-for="p in sabaProp" 
                            :key="p.property_id" 
                            :value="p.property_id">
                        </option>
                        </datalist>
                        
                        <p class="error-mess" v-if="errorObj.propErr.length > 0">{{ errorObj.propErr[0] }}</p>
                    </div>
                </div>
               <div class="form-group col-sm-4">
                   <label for="yrval"><b>Ownership Starts at:</b></label>
                   <input type="date" name="yrval" id="yval" class="form-control" 
                   v-model="obj.startd" />
                    <p class="error-mess" v-if="errorObj.startdErr.length > 0">{{ errorObj.startdErr[0] }}</p>
               </div>
               <div class="form-group col-sm-4">
                   <label for="atdnums"> <b>ATD No/Deed Number:</b></label>
                   <input type="text" name="atd" id="atdnum" class="form-control"
                   v-model="obj.atd" />
                    <p class="error-mess" v-if="errorObj.atdErr.length > 0">{{ errorObj.atdErr[0] }}</p>
              </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12">
                    <label for="reason"><b>Reason for changing Ownership:</b></label>
                    <textarea name="reason" id="reas" class="form-control" v-model="obj.res"></textarea>
                        <p class="error-mess" v-if="errorObj.resErr.length > 0">{{ errorObj.resErr[0] }}</p>
                </div>
            </div>
            <h4><i>Owners' Details</i></h4>
            <div v-for="(owner, index) in owners" :key="index" class="row">
                <div class="form-group col-sm-3">
                    <label for="val"><b>Owner's NIC:</b></label>
                    <input type="text" name="value" id="valu" class="form-control" @input ="showCustomer(owner, index)"
                        v-model="owner.nic" />
                    <p class="error-mess" v-if="errorObj.nicErr[index]?.length > 0">{{ errorObj.nicErr[index][0] }}</p>  
                </div>
                <div class="form-group col-sm-3">
                   <label for="usedn"> <b>Owner's Name:</b>
                   </label>
                   <input type="text" name="used" id="usednum" class="form-control" 
                   v-model="owner.owname" />
                    <p class="error-mess" v-if="errorObj.ownameErr[index]?.length > 0">{{ errorObj.ownameErr[index][0] }}</p>
              </div>
                <div class="form-group col-sm-3">
                   <label for="states"> <b>Address:</b></label>
                   <input type="text" name="rate" id="rrate" class="form-control"
                   v-model="owner.addr" />
                    <p class="error-mess" v-if="errorObj.addrErr[index]?.length > 0">{{ errorObj.addrErr[index][0] }}</p>
              </div>
                <div class="form-group col-sm-2">
                   <label for="rate"> <b>Contact Number:</b></label>
                   <input type="text" name="rate" id="rrate" class="form-control"
                   v-model="owner.contact" />
                    <p class="error-mess" v-if="errorObj.contactErr[index]?.length > 0">{{ errorObj.contactErr[index][0] }}</p>
              </div>
                <div class="form-group col-sm">
                    <label for="btnplus" style="color: transparent;"> <b> Add </b></label>
                    <input type="button" @click="addNew()" value="+" class="btn1" />
                    
                </div>
             
              
            </div>
            <div class="row">              
            </div>
                <div class="form-group">
                    <input type="button" @click="saveOwnerNew()" value="Save" class="btn" />
                    
                </div>
            </form>
        </div>
    </div>
</section>
<div class="Shop-container">
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
            
            <div class="heading">
            <h3><b>Search Property Transfer</b></h3>
            </div>
            <div class ="row">
            <div class="form-group col-sm-4">
                    <label for="usedn"> <b>Property ID:</b>
                    </label>
               <div>
                        <input 
                        v-model="obj.propsearch" 
                        id="serchprop" 
                        list="sabaPropList" 
                        class="form-control"
                        placeholder="Choose or type a property ID"
                        />
                        
                        <datalist id="sabaPropList">
                        <option 
                            v-for="p in sabaProp" 
                            :key="p.property_id" 
                            :value="p.property_id">
                        </option>
                        </datalist>
                    </div>
            </div>
               <div class="Addbtn col-sm-4">
                   <input type="button" value="Search" id="Addpay" class=" btn" @click="showTransfers()"  /> 
               </div>
            </div>
            <br>
            <div v-if="message" class="alert alert-warning">{{ message }}</div>
            <div class="" v-if="showtbl" id="printContent">
            <div class="heading" style="text-align: center;">
                <b>&nbsp;&nbsp;&nbsp;&nbsp;Property Transfer Details of Property Number {{ this.obj.propsearch }}</b>
            </div>
            <table style="width: 100%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
               <thead>
                   <tr>   
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Index</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Property ID</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Owner</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Owner NIC</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Contact</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Address</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">ATD/Deed No</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">ownership Started date</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; ">Ownership Ended at</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">

                    <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " >{{ (currentPage - 1) * 10 + index + 1 }}</td>
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " >{{ s.property_id  }}</td>
                       <td style="text-align: left; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " >{{ s.cus_name  }}</td>
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " >{{ s.cus_nic  }}</td>
                       <td style="text-align: left; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " >{{ s.cus_contact  }}</td>
                       <td style="text-align: left; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " >{{ s.cus_address  }}</td>
                       <td style="text-align: left; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " >{{ s.atd_num  }}</td>
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; " >{{  new Date(s.start_date).toLocaleDateString()  }}</td>
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: small; "  v-if="s.end_date && !isNaN(new Date(s.end_date).getTime())">
                        {{ new Date(s.end_date).toLocaleDateString() }}</td>
                        <td v-else>  Current Owner</td>
                   </tr>
               </tbody>
           </table>
           </div>
           <div v-if="showtbl">
                <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
            </div>
           </form>
           
                    <div class="container">
                        <input type="button" value="Print" class="printbtn"  @click="printContent">
                    </div>
            
       </div>
</div>
    
</template>
<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
// import { ref } from 'vue';
export default {
   
    name: "AssessTransfer",
  
    data() {
        return {
            obj:{prop:"",owname:"",nic:"",contact:"",addr:"",startd:"",res:"",propsearch:"",atd:""},
            errorObj: {propErr: [], ownameErr:[], nicErr:[],contactErr:[],addrErr:[],startdErr:[],resErr:[],atdErr:[]},
            owners: [{ owname: '', nic: '', contact: '', addr: '' }],
            sabaProp:[],
            itemsPerPage: 10,
            currentPage: 1,
            transferDetails:[],
            paddedNum:"",
            len:0,
            checkforproperty:[],
            isLoading:false,
            prselected:"",
            showtbl:false,
            message: "",
            theUser:[]
        }
    },

        created() {
            this.getDataFromSessionStorage()
        },
  
        computed : {
            paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.transferDetails)) {
        return this.transferDetails.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.transferDetails.length / this.itemsPerPage);
    },

},     
    methods: {
        async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }

           this.sabaProp = (await axios.get('/props/' +this.sabha)).data
       },
       formattedDate() {
           let date = new Date()
           const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
        const fdate=`${year}-${month}-${day}`;
        const options = { timeZone: 'Asia/Colombo', year: 'numeric', month: '2-digit', day: '2-digit' };
            return fdate.toString('en-US', options);
         },
         nextPage(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        prevPage(event) {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        addZero(num) {

        num = num.toString();
            return '19' + num.slice(0, 5) + '0' + num.slice(5, -1);
        },
        addZeroPadding(ownerNic) {
        if (ownerNic && ownerNic.length > 9) {
            this.len = ownerNic.charAt(9).toUpperCase();
            // Check if the user input is a valid number and if the 10th character is 'V' or 'X'
            if (!isNaN(parseInt(ownerNic)) && ownerNic !== null && (ownerNic.charAt(9).toUpperCase() === 'V' || ownerNic.charAt(9).toUpperCase() === 'X')) {
            
            this.paddedNum = this.addZero(ownerNic);
        
        }else{
            this.paddedNum=parseInt(ownerNic);
        }
        return this.paddedNum
    }
        },
    ///////////// show customer details //////////////////////
    async showCustomer(owner, index) {
    try {
        const paddedNic = this.addZeroPadding(owner.nic); // Pass the owner's NIC
        const response = await axios.get(`/customers/${paddedNic}/${this.sabha}`);
        const theUser = response.data;

        if (theUser.length > 0) {
            // Update the specific owner's details reactively
            this.owners[index] = {
                ...owner,
                owname: theUser[0].cus_name,
                addr: theUser[0].cus_address,
                contact: theUser[0].cus_contact
            };
        } else {
            // Clear the specific owner's details if no user is found
            this.owners[index] = {
                ...owner,
                owname: '',
                addr: '',
                contact: ''
            };
        }
    } catch (error) {
        console.error('Error fetching customer data:', error);
    }
},
       resetCheckErr: function () {
        this.errorObj.propErr =[];
        this.errorObj.startdErr=[];
        this.errorObj.resErr=[];
        this.errorObj.atdErr=[];
        this.errorObj.nicErr = [];
        this.errorObj.ownameErr=[];
        this.errorObj.addrErr = [];
        this.errorObj.contactErr = []
        
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errorObj) {
               if (this.errorObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           if (!this.obj.prop) {
               this.errorObj.propErr.push("required");
           }
        //    if (!this.obj.owname) {
        //        this.errorObj.ownameErr.push("required");
        //    }
           if (!this.obj.startd) {
               this.errorObj.startdErr.push("required");
           }
           if (!this.obj.res) {
               this.errorObj.resErr.push("required");
           }
           if (!this.obj.atd) {
               this.errorObj.atdErr.push("required");
           }
           this.owners.forEach((owner, index) => {
            if (!owner.nic) {
                this.errorObj.nicErr[index] = this.errorObj.nicErr[index] || [];
                this.errorObj.nicErr[index].push("required");
            }
            if (!owner.owname) {
                this.errorObj.ownameErr[index] = this.errorObj.ownameErr[index] || [];
                this.errorObj.ownameErr[index].push("required");
            }
            if (!owner.addr) {
                this.errorObj.addrErr[index] = this.errorObj.addrErr[index] || [];
                this.errorObj.addrErr[index].push("required");
            }
            if (!owner.contact) {
                this.errorObj.contactErr[index] = this.errorObj.contactErr[index] || [];
                this.errorObj.contactErr[index].push("required");
            }
        });
       },
       ////////////////// add multiple owner details/////////////////////////
       addNew() {
    this.checkForm(); // Validate the form

    // If there are errors, exit the function
    if (!this.checkEmptyErr()) {
        return; 
    }

    // Add a new owner object to the owners array
    this.owners.push({ nic: '', owname: '', addr: '', contact: '' });

    // Reset error messages for the new owner
    this.errorObj.nicErr.push([]);
    this.errorObj.ownameErr.push([]);
    this.errorObj.addrErr.push([]);
    this.errorObj.contactErr.push([]);
},
        ////////////////////// save to owners table /////////////////////////
        
    async saveOwnerNew(e) {
    this.checkForm();

    // Exit early if there are errors
    if (!this.checkEmptyErr()) {
        e.preventDefault();
        return;
    }

    try {
        // Step 1: Update old owners' end date
        this.prselected = this.obj.prop; // Selected property ID
        const updata = {
            end_date: this.obj.startd,
            trans_reson: this.obj.res,
            atd_num: this.obj.atd,
            edit_by: this.nic,
            owner_status:"0"
        };

        await axios.put(`/updaendd/${this.prselected}`, updata);
        console.log(`End date updated for property: ${this.prselected}`);

        // Step 2: Loop over owners and handle each owner individually
        for (const owner of this.owners) {
            const paddedNic = this.addZeroPadding(owner.nic);

            // Check if the customer already exists
            const response = await axios.get(`/customers/${paddedNic}/${this.sabha}`);
            const theUser = response.data;

            if (theUser.length === 0) {
                // Save new customer
                const customerData = {
                    sabha_code: this.sabha,
                    cus_nic: paddedNic,
                    cus_name: owner.owname,
                    cus_contact: owner.contact,
                    cus_address: owner.addr,
                };
                await axios.post("/customers/", customerData);
                console.log(`Customer ${paddedNic} added successfully.`);
            } else {
                console.log(`Customer ${paddedNic} already exists.`);
            }

            // Save owner data
            const ownerData = {
                property_id: this.obj.prop,
                owner_nic: paddedNic,
                start_date: this.obj.startd,
                sb_code: this.sabha,
            };
            await axios.post("/saveowner/", ownerData);
            console.log(`Owner ${paddedNic} saved successfully.`);
        }

        // Step 3: Show success message
        this.$refs.alert.showAlert('success', 'Added Successfully!');
                        this.obj.prop="",
                        this.obj.startd="",
                        this.obj.res="",
                        this.obj.atd="",
                        this.owners = [{ owname: '', nic: '', contact: '', addr: '' }];
                       
                    
    } catch (error) {
        console.error('Error saving owner:', error);
        this.$refs.alert.showAlert('error', 'There was an error saving the owner. Please try again!');
    }
},
       async showTransfers(){
        try{
            this.message =""
        this.transferDetails = (await axios.get('/translist/'+this.obj.propsearch+"/"+this.sabha)).data
        if(this.transferDetails.length>0){
            this.showtbl=true
            
        }
       else{
        this.message = "This property has not been transferred";
        this.showtbl=false
       }
    }
    catch (error) {
        console.error("Error fetching transfer details:", error);
        this.message = "Failed to load transfer details. Please try again later.";
    }
        
       },
       printContent() {
            const prtHtml = document.getElementById('printContent').innerHTML;
               const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                WinPrint.document.write(`    
        ${prtHtml}
    `);
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
        }
      
    },
    components: {
        VueBasicAlert
    }
}

</script>

<style scoped>
.row1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
.close-btn {
  /* align-items: right; */
  background-color:rgb(121, 23, 12);
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #f8f4f4;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 8px;
  border-radius: 8px;
  width:6%;
  height: 4%;
  text-align: center;
}

.close-btn:hover {
  color: rgb(220, 18, 18);
  transform: scale(1.2);
  background-color:  #022e2a; 
}

.close-btn:focus {
  outline:none; /* Remove default focus outline */
}
.register-container {
    padding: 1rem 2%;
     /* min-height: 72.3vh; */
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 98rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}

.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 40%;
    border: none;
    padding-bottom: 0.5rem;
}
.register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;

    width: 10%;
    border: none;
    margin-left: 10%;
}
.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #022e2a;

}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 30%;
    text-align: center;
    align-items: center;
    /* background: #022e2a; */
    background-color : #032a2c;
    /* height: 3rem; */
    color: #e9e4e9;
    
}
.register-container .register-form-container form .btn1 {
    margin: 1rem 0;
    width: 100%;
    text-align: center;
    align-items: center;
    /* background: #022e2a; */
    background-color : #032a2c;
    height: 3rem;
    color: #e9e4e9;
    
}

.container {
    display: flex;
    justify-content: center;  /* Center horizontally */
    align-items: center;      /* Center vertically */
    /* height: 100vh;            Full viewport height */
}
.printbtn {
    margin: 1rem 0;
    text-align: center;
    align-items: center;
    background-color: #032a2c;
    color: #e9e4e9;
    padding: 1rem 2rem; /* Adjust padding for a better button size */
    border: none;
    cursor: pointer;
    border-radius: 8px; /* Optional: Rounded corners */
    font-size: 1.5rem;
}
.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 90%;

} 
.register-container .register-form-container form .hide {
    display: none;

} 
/* for second container */
.Shop-container {
   background-color: #ffffff09;
   /* height: 50vh; */
   padding: 1rem 2%;
   font-size: 16px;
}

.Shop-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}
/* newly added from income heads */
/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   /* margin: 1rem 0; */
   width: 50%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;;
    /* height: 3.0rem; */
  
   
}
/* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
} */
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: rgb(22, 21, 21);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}

</style>
