<template>
  <div class="scroll-container">
    <div class="scroll-content">
    <div class="form-group">
      <div class="row">
        <div class="col-4">
          <label for="iStamp">Date</label>
          <input type="date" name="idate" id="idate" class="form-control" v-model="shroffObj.idate" />
        </div>
        <div class="col-4">
          <label for="ibtn">   </label>
          <input type="button" @click="genReport()" value="Generate" class="upbtn" id ="gen" />
        </div>
      </div>
    </div>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
  
    <div class="invoice-contain">
      <div class="invoice-form-contain">
        <form id="invoiceForm" novalidate autocomplete="off">
          <div id="PrintContent">
            <h5>එල්.ජී.3</h5>
            <div class="form-group">
              <div class="heading">
                <h4 style="text-align: center; padding-top: 0px; padding-bottom: 0px;">
                  Daily Receipts Register | දෛනික ලැබීම් ලේඛණය<br>
                  {{ sabhadetail.sb_name_en }} | {{ sabhadetail.sb_name_sin }}<br>
                  {{ sabhadetail.sb_address }}
                </h4>
              </div>
              <h6 style="padding-top: 0px; padding-bottom: 0px;">
                <label for="iDate">
                  Date | දිනය: {{ this.shroffObj.idate }}<br>
                  Income and other receipts | ආදායම් හා වෙනත් ලැබීම්
                </label>
              </h6>
              <table style="width: 98%; text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">
                <thead>
                  <tr>
                    <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Invoice Number <br>ලදුපත් අංකය</th>
                    <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Cash(Rs.) <br>මුදල්(රු.)</th>
                    <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Cheque(Rs.) <br>චෙක්(රු.)</th>
                    <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Total(Rs.) <br>එකතුව(රු.)</th>
                    <th style="writing-mode: vertical-rl; transform: rotate(180deg); text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;" v-for="h in headsar" :key="h.id">{{ h.sb_rate_head }}</th>
                    <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">STAMP(Rs.) <br>මුද්දර බද්ද(රු.)</th>
                    <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">VAT(Rs.) <br>වැට්(රු.)</th>
                    <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">DISCOUNT <br>වට්ටම(රු.)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="r in invoicedetails" :key="r.id">
                    <td style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">{{ r.invoice_num }}</td>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ $formatNumber(r.cash_total) || '' }}</td>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ $formatNumber(r.cheque_total) || '' }}</td>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ r.invoice_status === 0 ? $formatNumber(r.total_amount) : 'Cancelled' }}</td>
                    
                    <td v-for="head in headsar" :key="head.id" style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">
                    <template v-if="incomeMap[r.invoice_num] && incomeMap[r.invoice_num][head.sb_rate_head]">
                      <div v-for="(amount, index) in incomeMap[r.invoice_num][head.sb_rate_head]" :key="index">
                        {{ $formatNumber(amount) }}
                      </div>
                    </template>
                    <template v-else>
                      {{ '' }}
                    </template>
                 </td>

                    <!-- <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;" v-for="head in headsar" :key="head.id">
                      {{ $formatNumber(incomeMap[r.invoice_num] && incomeMap[r.invoice_num][head.sb_rate_head]) || '' }}
                    </td> 
                -->
                <!-- <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;" v-for="head in headsar" :key="head.id">
  {{ incomeMap[r.invoice_num] && incomeMap[r.invoice_num][head.sb_rate_head] && incomeMap[r.invoice_num][head.sb_rate_head].amount || '' }}
</td> -->
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ $formatNumber(r.total_stamp) }}</td>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ $formatNumber(r.total_vat) }}</td>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ $formatNumber(r.total_discount) }}</td>
                  </tr>
                  <tr>
                    <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Total:</th>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(cash_total) }}</b></td>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(cheque_total) }}</b></td>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(full_total) }}</b></td>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;" v-for="h in headsar" :key="h.id">
                      <div>{{ $formatNumber(Number(calculateTotal(h.sb_rate_head)).toFixed(2)) }}</div>
                    </td>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(stamp_full_total.toFixed(2))}}</b></td>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(vat_total.toFixed(2))}}</b></td>
                    <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(discount_total.toFixed(2))}}</b></td>

                  </tr>
                </tbody>
              </table>
              <br>
              <h5>The above mentioned receipts were verified and recorded in the revenue records. | ඉහත සඳහන් ලදුපත් පරික්ෂා කර බලා ආදායම් ලේඛණ වල සටහන් කරන ලදි.</h5>
              <br>
              <h6>Cashier Signature | සරප් අත්සන ................................................................................... &nbsp; &nbsp;&nbsp;Checked By |පරික්ෂා කලේ .......................................................................... </h6>
              <h6>Date | දිනය&nbsp;&nbsp;................................................................................&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;Date | දිනය &nbsp;&nbsp;................................................................................</h6>
            </div>
          </div>
        </form>
        <div class="form-group">
          <input type="button" value="Print" class="btn" @click="printContent" />
          
        </div>
      </div>
      <br>
    </div>
    </div>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import VueBasicAlert from 'vue-basic-alert';
  
  export default {
    name: "DailyIncomeSecretary",
    data() {
      return {
        nic: "",
        sabha: "",
        userLevel: "",
        userName: "",
        sabhadetail: [],
        invoicedetails: [],
        cashier: [],
        total: 0,
        employeedetail: [],
        date: new Date(),
        defaultdate: "",
        invoiceIncomeheads: [],
        invarray: [],
        headsar: [],
        incomeData: [],
        groupedArrayCustom: [],
        cash_total: 0,
        cheque_total: 0,
        full_total: 0,
        stamp_full_total: 0,
        shroffObj: { idate: this.formattedDate(new Date()) },
        
        incomeMap: {},
        vat_total:0,
        discount_total:0,
      };
    },
  
    created() {
      this.getDataFromSessionStorage();
      // this.genReport();
    },
  
    methods: {
      formattedDate(newdate) {
        const day = newdate.getDate().toString().padStart(2, "0");
        const month = (newdate.getMonth() + 1).toString().padStart(2, "0");
        const year = newdate.getFullYear().toString();
        return `${year}-${month}-${day}`;
      },
  
      async getDataFromSessionStorage() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        if (data) {
          this.nic = data.nic;
          this.sabha = data.sabha;
          this.userLevel = data.userLevel;
          this.userName = data.userName;
        }
        this.sabhadetail = (await axios.get('/pra_sabha/' + this.sabha)).data;
      },
  
    
      async genReport() {
    let sabhacode = this.sabha;

    try {
        const receiptreport = await axios.get(`/invoicesum/${sabhacode}/${this.shroffObj.idate}`);
        this.invoicedetails = receiptreport.data;

        const headsForDate = await axios.get(`/dailyheads/${sabhacode}/${this.shroffObj.idate}`);
        this.headsar = headsForDate.data;

        let moncash = 0;
        let moncheque = 0;
        let montotal = 0;
        let stamp_total = 0;
        let vat_tot = 0;
        let dis_tot=0;

        this.cash_total = 0;
        this.cheque_total = 0;
        this.full_total = 0;
        this.stamp_full_total = 0;
        this.vat_total =0;
        this.discount_total = 0;
        // this.incomeMap = {};

        // for (let invoice of this.invoicedetails) {
        //     if (invoice.invoice_status === 0) {
        //         moncash += parseFloat(invoice.cash_total) || 0;
        //         moncheque += parseFloat(invoice.cheque_total) || 0;
        //         montotal += parseFloat(invoice.total_amount) || 0;
        //         stamp_total += parseFloat(invoice.total_stamp) || 0;
        //         vat_tot += parseFloat(invoice.total_vat) || 0;
        //         dis_tot += parseFloat(invoice.total_discount) || 0;

        //         for (let head of this.headsar) {
        //             const allinvoiceIncomeheads = await axios.get(`/dailyinvoiceheads/${sabhacode}/${this.shroffObj.idate}/${invoice.invoice_num}/${head.sb_rate_head}`);
                    
        //             if (!this.incomeMap[invoice.invoice_num]) {
        //                 this.incomeMap[invoice.invoice_num] = {};
        //             }
        //             if (allinvoiceIncomeheads.data && allinvoiceIncomeheads.data.length > 0 && allinvoiceIncomeheads.data[0].amount !== undefined) {
        //                 this.incomeMap[invoice.invoice_num][head.sb_rate_head] = parseFloat(allinvoiceIncomeheads.data[0].amount) || 0;
        //             } else {
        //                 this.incomeMap[invoice.invoice_num][head.sb_rate_head] = 0;
        //             }
        //         }
        //     }
        // }
        // this.incomeMap = {};
       // Initialize incomeMap if not already present
this.incomeMap = this.incomeMap || {};

for (let invoice of this.invoicedetails) {
    if (invoice.invoice_status === 0) {
        moncash += parseFloat(invoice.cash_total) || 0;
        moncheque += parseFloat(invoice.cheque_total) || 0;
        montotal += parseFloat(invoice.total_amount) || 0;
        stamp_total += parseFloat(invoice.total_stamp) || 0;
        vat_tot += parseFloat(invoice.total_vat) || 0;
        dis_tot += parseFloat(invoice.total_discount) || 0;

        for (let head of this.headsar) {
            try {
                const allinvoiceIncomeheads = await axios.get(`/dailyinvoiceheads/${sabhacode}/${this.shroffObj.idate}/${invoice.invoice_num}/${head.sb_rate_head}`);
                
                // Initialize incomeMap if not already present
                if (!this.incomeMap[invoice.invoice_num]) {
                    this.incomeMap[invoice.invoice_num] = {};
                }
                
                // Initialize array for specific head if not present
                if (!this.incomeMap[invoice.invoice_num][head.sb_rate_head]) {
                    this.incomeMap[invoice.invoice_num][head.sb_rate_head] = [];
                }
                
                // Add amount to the array
                const amounts = allinvoiceIncomeheads.data && allinvoiceIncomeheads.data.length > 0
                    ? allinvoiceIncomeheads.data.map(item => parseFloat(item.amount) || 0)
                    : [0];

                // Ensure that all amounts are added
                this.incomeMap[invoice.invoice_num][head.sb_rate_head].push(...amounts);

            } catch (error) {
                console.error(`Error fetching income heads for ${invoice.invoice_num} and ${head.sb_rate_head}:`, error);
            }
        }
    }
}

        this.cash_total = moncash.toFixed(2);
        this.cheque_total = moncheque.toFixed(2);
        this.full_total = montotal.toFixed(2);
        this.stamp_full_total = stamp_total;
        this.vat_total = vat_tot;
        this.discount_total = dis_tot;

    } catch (error) {
        console.error('Error generating report:', error);
    }
},
  
calculateTotal(headName) {
    let total = 0;

    this.invoicedetails.forEach(invoice => {
        if (invoice.invoice_status === 0) {
            // Retrieve the array of amounts for the given headName
            const amounts = this.incomeMap[invoice.invoice_num]?.[headName] || [];
            
            // Sum up all the amounts in the array
            total += amounts.reduce((sum, amount) => sum + amount, 0);
        }
    });

    return total;
},
      printContent() {

const prtHtml = document.getElementById('PrintContent').innerHTML;

// Open the print window
// const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,status=0');
const WinPrint = window.open('', '', '');
WinPrint.document.write(`
${prtHtml}
`);

WinPrint.focus();
WinPrint.print();
WinPrint.close();
}
   
},
  
    components: {
      VueBasicAlert
    }
  };
  </script>
 <style scoped>
 .upbtn {
    padding: 10px 20px;
    background-color: #054818;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center; 
    width: 15rem;
    height: 2.7rem;
    font-size: small;
    padding-top: 0.3rem;
    margin: 1rem 0;
    border-radius: 5px;
}
 .invoice-contain {
     background-color: #fff;
     /* height: 100vh; */
     /* padding: 2rem 25%; */
     padding: 1rem;
     font-size: 16px;
     align-content: center;
     min-height: 72.3vh;
 }
 .invoice-contain .invoice-form-contain {
     background: #fff;
 
 }
 
 .invoice-contain .invoice-form-contain form {
     position: relative;
     /* left: 50%; */
     /* transform: translate(-50%, 0%); */
     /* width: 100%; */
     /* box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
     border: 0.1rem solid rgba(0, 0, 0, 0.2);
     padding: 1rem;
     border-radius: 0.5rem;
     animation: fadeUp 0.4s linear;
     padding-left: 5rem; */
 }
 .invoice-contain .invoice-form-contain form h3 {
     /* padding-bottom: 1rem; */
     font-size: 1.7rem;
     text-transform: uppercase;
     color: #130f40;
     margin: 0;
 }
 .ttable {
     
     width: 100%;
     border-width:1px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 0.9rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      border-collapse: collapse;
 }
 th  {
     text-align: center;
}
.ttable, th, td {
border: 1px solid;
}

 
 .table-responsive {
     margin-top: 8vh;
     height: 500px;
 }
 
 .action-btn,
 .cancel-btn,
 .paid-btn {
     width: 100px;
     height: 25px;
     color: white;
     text-transform: capitalize;
 }
 
 .action-btn {
     background-color: #0da9ef;
     margin-right: 10px;
 }
 
 .cancel-btn,
 
 .action-btn:hover {
     background-color: #27ae60;
 }
 
  .invoice-contain .invoice-form-contain form .form-group {
     margin:0;
     font-size: 1rem;
 } 
 
 .invoice-contain .invoice-form-contain form .form-group .error-mess {
     font-size: 1.5rem;
     position: relative;
     color: rgb(243, 47, 47);
     margin: 0;
     padding: 0;
 }
 .invoice-contain .btn {
     background: rgb(223, 80, 14);;
     color: rgb(240, 234, 231);
     border: 1rem;
     padding: 1rem;
     font: inherit;
     cursor: pointer;
     outline: 1rem;
     text-align: center;
    
 }
 .invoice-contain .btnrow{
    align-content: center;
    padding-left: 25rem;
 }

 .scroll-container {
  width: 100%; /* Set the width of the container */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping */
}
/*
//.scroll-content {
   Optional: Set width if necessary 
//}*/
 </style>