<template>
    <!-- start -->
    <vue-basic-alert :duration="4000" ref="alert" />
    <div class="register-container">    
    <!-- end -->
    </div>
    <!-- end -->
       <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
        <div class="invoice-container" >
            <div class="invoice-form-container" >
               
                <form id="invoiceForm" novalidate autocomplete="off">
                    <div id="PrintContent">
                    <h5>ප්‍රා.ස.1-Online</h5>
                    <div class="form-group">
                       <h3 style="text-align: center;">Invoice | ලදුපත</h3>
                        <h4 style="text-align: center;">
                            <!-- {{sabhadetail.sb_name_en}}, -->
                        {{sabhadetail.sb_address}}, Tel:{{sabhadetail.sb_contact}}&nbsp; Fax:{{sabhadetail.fax}}<br>
                        VAT Number:{{ sabhadetail.vat_num }}</h4>
                   <br>
                        <label for="iHead"><b>Receipt Number:</b> {{searchinvoice}}
                        </label> <br> 
                        <label for="iHead"><b>Customer name:</b>
                        </label> 
                        {{invoicedetails[0].cus_name}}<br>
                        <label for="iCont"><b>Customer Contact: </b>
                        </label>
                        {{invoicedetails[0].cus_contact}}<br>
    
                        <label for="iCode"><b>Customer Address: </b>
                        </label>
                        {{invoicedetails[0].cus_address}}<br>
                    <table style="text-align: center; width: 100%; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">
                    <thead>
                        <tr >   
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Income Head</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; width: 30%;">Description</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Amount</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">VAT</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Stamp</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Discount</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Total</th>
                            
    
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for=" r in invoicedetails" :key="r.online_pay_id">
                            <td style="text-align: Center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ r.vote }}</td>
                            <td style="text-align: left; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; width: 40%;" >{{ r.online_pay_des }}</td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ $formatNumber(r.online_pay_amount) }}</td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{$formatNumber()}}</td>
                            <!-- <td>{{ r.vat }}</td> -->
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ $formatNumber() }}</td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ $formatNumber() }}</td>
                            
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{  }}</td>
                            <!-- <td>{{ r.sub_nic }}</td> -->
                          
                        </tr>
                        <tr  v-for=" r in invoicedetails" :key="r.online_pay_id">
                            <th colspan="2" style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Total:</th>
                            <!-- <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber(calculateSummaryPrice()[0])}}</th> -->
                            <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber(r.online_pay_amount)}}</th>
                            <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber()}}</th>
                            <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber()}}</th>
                            <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber()}}</th>
                            <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber()}}</th>
                        </tr>
                    </tbody>
                </table>
              <!-- <div style="align-content: left;"><b>Total: {{calculateSummaryPrice()[0]}} </b> </div>  -->
                </div>
                    
                <table class="nobordertbl">
            <tr>
                <td style="text-align:left;width: 50%;">
                   <!-- Prepaired By: -->
    
                </td>
                <td style="text-align:left;width: 50%;">
                    Cashier: {{ userName }} 
                </td>
            </tr>
            <tr>
                <td style="text-align:left;width: 50%;">
                    Date:
                     {{ new Date().toLocaleString() }}
    
                </td>
                <td style="text-align:left;width: 50%;">
                    Cashier Signature: .....................................
                </td>
            </tr>
        </table>
        </div>
        
                </form>
            </div>
            <br>
            <div class="btnrow">
                    <div class="">
                        <!-- <input type="button" value="Cancel" class=" btn" @click="clearform" >&nbsp;&nbsp; -->
                        <input type="button" style="width: 10rem;" value="Print" class=" btn" @click="printContent">
                    </div>
                </div>
        </div>
    </template>
    <script>
    import axios from 'axios';
    import VueBasicAlert from 'vue-basic-alert';
    // import VuePrintNB from 'vue-print-nb';
    export default {
        name: "onlineInvoice",
    
       data (){
    
        return{
                nic:"",
                sabha: "",
                userLevel:"",
                userName:"",
                sabhadetail:[],
                searchinvoice:'',
                invoicedetails:[],
                cashier:[],
        }
       },
    
       created(){
        this.getDataFromSessionStorage()
       },
       methods:{
        formattedDate() {
                // const day = this.date.getDate().toString().padStart(2, "0");
                // const month = (this.date.getMonth() + 1)
                //   .toString()
                //   .padStart(2, "0");
                // const year = this.date.getFullYear().toString();
                // // this.defaultdate =`${year}-${month}-${day}`
                // return `${year}-${month}-${day}`;
                // let date=this.serverDate;
                let date = new Date()
                const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
              },
        async getDataFromSessionStorage(){
                const data = JSON.parse(sessionStorage.getItem('userData'))
                if(data){
                                this.nic = data.nic 
                                this.sabha = data.sabha
                                this.userLevel =data.userLevel
                                this.userName = data.userName
                            }
                            this.searchinvoice = this.$route.params.data
                 this.sabhadetail= (await axios.get('/pra_sabha/' +this.sabha)).data
                 this.invoicedetails = (await axios.get('/onlineinvocr/' +this.sabha+'/'+this.searchinvoice)).data
             
            },

           printContent() {

const prtHtml = document.getElementById('PrintContent').innerHTML;
    const WinPrint = window.open('', '', '');
    WinPrint.document.write(`    
${prtHtml}
`);
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
}
            
       },
    
    components: {
            VueBasicAlert
        }
    };
    </script>
    <style scoped>
    .nobordertbl{
            border-collapse: collapse;
            border: none;
            font-size: 1.2rem;
            width:100%;
        }
        .nobordertbl td{
            border: none;
        }
    .ttable {
         
         width: 100%;
         border-width:1px;
         border-color : #130f40;
         /* border-style: solid; */
         font-size: 0.8rem;
        text-align: right;
         /* background-color :white; */
         /* padding-left: 3rem; */
          /* height: 3rem; */
          border-collapse: collapse;
          
     }
     th  {
         text-align: center;
    }
    .ttable, th, td {
    border: 1px solid;
    /* padding-right: 0.7rem; */
    }
    .date-control{
        margin: 0.7rem 0;
        border-radius: 0.5rem;
        background: #f7f7f7;
        /* padding: 2rem 1.2rem; */
         font-size: 1.3rem; 
        color: #130f40;
        text-transform: none;
        width: 50%;
        border: 1px solid; 
    }
    .invoice-container {
        background-color: #fff;
        /* height: 100vh; */
        padding: 2rem;
        font-size: 16px;
        align-content: center;
       /* min-height: 72.3vh;*/
    }
    .invoice-container .invoice-form-container {
        background: #fff;
    
    }
    
    .invoice-container .invoice-form-container .heading{
       padding: 0%;
    
    }
    
    .invoice-container .invoice-form-container form {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%);
        max-width: 70rem;
        width: 100%;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
        border: 0.1rem solid rgba(0, 0, 0, 0.2);
        padding: 2rem;
        border-radius: 0.5rem;
        animation: fadeUp 0.4s linear;
        padding-left: 2rem;
    }
    .h4 {
        /* padding-bottom: 1rem; */
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #130f40;
        margin: 0;
    }
    .invoice-container .invoice-form-container form h3 {
        /* padding-bottom: 1rem; */
        font-size: 1.3rem;
        text-transform: uppercase;
        color: #130f40;
        margin: 0;
    }
    .invoice-container .invoice-form-container form .table {
        /* margin: 1rem 0; */
        width: 95%;
        /* border-style: solid; */
        font-size: 1.2rem;
        /* background-color : #e9e4e9; */
        padding-left: 5rem;
         /* height: 3rem; */
         border-color : #130f40;
         /* border-width:2px; */
         border-top: 0.5px solid;
         border-left: 0.5px solid;
         border-right: 0.5px solid;
         border-bottom: 0.5px solid;
    }
    .invoice-container .invoice-form-container form .table .td {
        /* margin: 1rem 0; */
        width: 25%;
        vertical-align: middle;
        border: 0.5px solid;
        border-bottom: 0.5px solid;
    }
    
    
    .project-list>tbody>tr>td {
        padding: 12px 8px;
    }
    
    /* .project-list>tbody>tr>td .avatar {
        width: 22px;
        border: 1px solid #CCC;
    } */
    
    .table-responsive {
        margin-top: 8vh;
        height: 500px;
    }
    
    .action-btn,
    .cancel-btn,
    .paid-btn {
        width: 100px;
        height: 25px;
        color: white;
        text-transform: capitalize;
    }
    
    .action-btn {
        background-color: #0da9ef;
        margin-right: 10px;
    }
    
    .cancel-btn,
    .paid-btn {
        background-color: red;
    }
    
    .action-btn:hover {
        background-color: #27ae60;
    }
    
     .invoice-container .invoice-form-container form .form-group {
        margin:0;
        font-size: 1.3rem;
        padding-top: 0%;
        padding-bottom: 0%;
    } 
    
    .invoice-container .invoice-form-container form .form-group .error-mess {
        font-size: 1.5rem;
        position: relative;
        color: rgb(243, 47, 47);
        margin: 0;
        padding: 0;
    }
    .invoice-container .invoice-form-container form .form-group1 {
        margin:0;
        font-size: 1.2rem;
    } 
    .invoice-container .invoice-form-container form .form-group2 {
        width:50%;
        font-size: 1.2rem;
    } 
    .invoice-container .btn {
        background: rgb(223, 80, 14);;
        color: rgb(240, 234, 231);
        border: 1rem;
        padding: 1rem;
        font: inherit;
        cursor: pointer;
        outline: 1rem;
        text-align: center;
       
    }
    .invoice-container .btnrow{
       align-content: center;
       padding-left: 20rem;
      
    }
    /* for first form */
    .register-container {
        /* padding: 2rem 9%;
        padding-left: 35rem; */
    
           background-color: #fff;
        /* height: 100vh; */
        padding: 2rem 25%;
        font-size: 16px;
        align-content: center;
        
    }
    
    .register-container .register-form-container {
        background: #fff;
        /* width: 100% !important; */
    }
    
    .register-container .register-form-container form {
        /* position: relative; */
        /* left: 40%; */
        /* transform: translate(-50%, 0%); */
        /* max-width: 70rem; */
    
        /* width: 70%;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
        border: 0.1rem solid rgba(0, 0, 0, 0.2);
        padding: 1rem;
        border-radius: 0.5rem;
        animation: fadeUp 0.4s linear; */
            position: relative;
        left: 50%;
        transform: translate(-50%, 0%);
        max-width: 70rem;
        width: 100%;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
        border: 0.1rem solid rgba(0, 0, 0, 0.2);
        padding: 2rem;
        border-radius: 0.5rem;
        animation: fadeUp 0.4s linear;
        padding-left: 5rem;
       
    }
    
    .register-container .register-form-container form h3 {
        padding-bottom: 1rem;
        font-size: 2rem;
        text-transform: uppercase;
        color: #130f40;
        margin: 0;
    }
    
    .register-container .register-form-container form .form-control {
         margin: 0.7rem 0;
        border-radius: 0.5rem;
        background: #f7f7f7;
        /* padding: 2rem 1.2rem; */
         font-size: 1.3rem; 
        color: #130f40;
        text-transform: none;
        width: 100%;
        border: bottom 1px solid; 
       
    } 
    
    .register-container .register-form-container form .form-control1 {
         margin: 0.7rem 0;
        border-radius: 0.5rem;
        background: #f7f7f7;
        /* padding: 2rem 1.2rem; */
         font-size: 1.3rem; 
        color: #130f40;
        text-transform: none;
        width: 50%;
        border: bottom 1px solid; 
    } 
    
    .register-container .register-form-container form label {
        font-size: 1.2rem;
        margin: 0;
        padding: 0;
        float:left;
    }
    
    .register-container .register-form-container form span {
        font-size: 18px;
        padding-left: 5px;
        padding-right: 40px;
        display: block; 
        overflow: hidden; 
        
    }
    
    .register-container .register-form-container form .btn {
        margin: 1rem 0;
        width: 10%;
        text-align: center;
        background-color : #af74a7;
        height: 30px;
        font-size: 1.3rem; 
    }
    
    .register-container .register-form-container form p {
        padding-top: 1rem;
        font-size: 1.5rem;
        color: #e23838;
        margin: 0;
    }
    
    .register-container .register-form-container form p a {
        color: #27ae60;
    }
    
    .register-container .register-form-container form p a:hover {
        color: #130f40;
        text-decoration: underline;
    }
    
    .register-container .register-form-container form .form-group {
        margin: 0;
    }
    
    .register-container .register-form-container form .form-group .error-mess {
        font-size: 1.5rem;
        position: relative;
        color: rgb(243, 47, 47);
        margin: 0;
        padding: 0;
    }
    .invoice-container .invoice-form-container form .form-group2 .error-mess {
        font-size: 1.5rem;
        position: relative;
        color: rgb(243, 47, 47);
        margin: 0;
        padding: 0;
    }
    .register-container .register-form-container form .row .error-mess {
        font-size: 1.5rem;
        position: relative;
        color: rgb(243, 47, 47);
        margin: 0;
        padding: 0;
    }
    </style>