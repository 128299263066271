<template>
    <div class="admin-container">
      
            <!-- <h1>Hello {{user.emp_name}}!</h1> -->
            <div class="home-main">
        
        <div class="content">
            <span > {{sbahaName}} </span>
            <!-- {{}} -->
           <br>
           <h3>Total Income - Rs.{{ totalincome}}</h3>
        </div>
        </div>

        <div class="row">
        
            <table class="ttable" >
                <thead>
                    <tr style="background-color:#b2beb5;">
                        <th>Index</th>
                        <th>Vote</th>
                        <th>Vote Name</th>
                        <th>Amount(Rs.)</th>
                        <th>Stamp(Rs.)</th>
                        <th>VAt(Rs.)</th>
                        <th>Discount(Rs.)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" (b,index) in paginatedData" :key="index">

                        <td style="width: 5%; text-align: center;">{{ (currentPage - 1) * 30 + index + 1 }}</td>
                        <td style="width: 30%; text-align: left;">&nbsp;&nbsp;{{ b.sb_rate_head }}</td>
                        <td style="width: 40%; text-align: left;">&nbsp;&nbsp;{{ b.sb_rate_head_name }}</td>
                        <td style="width: 10%; text-align: right;">{{$formatNumber(b.summary ) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td style="width: 10%; text-align: right;">{{$formatNumber(b.st_sum ) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td style="width: 10%; text-align: right;">{{$formatNumber(b.vat_sum ) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td style="width: 10%; text-align: right;">{{$formatNumber(b.dis_sum ) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
        </div>
  
     
    </div>
</template>


<script>
import axios from 'axios';
import { mapState, mapMutations } from "vuex";
export default {
    name: 'DetailedVoteView',

    
    data() {
        return {
            sabhaDetails:[],
            itemsPerPage: 30,
            currentPage: 1,
            searchprov:[],
            proName:"",
            totalincome:0,
            incomedetails:[],
            searchsbaha:"",
            sbahaName:"",
            sabhadetail:[]
        }
    },
    created(){
        
       this.getDataFromSessionStorage()
    },
    computed: {
        ...mapState(["user"]),

    paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    if (Array.isArray(this.incomedetails)) {
      return this.incomedetails.slice(startIndex, endIndex);
    } else {
      // Return empty array or handle loading state
      return [];
    }
  },
  totalPages() {
      return Math.ceil(this.incomedetails.length / this.itemsPerPage);
    },
    },
    methods: {
        ...mapMutations(["setUser"]),
        // formatNumber(value) {
        //     if (!value) return '';
        //     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // },
        async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }
                       
                       if(this.userLevel==0){
                       this.searchsbaha = this.$route.params.data1
                       this.sbahaName = this.$route.params.data2
                       this.sabhadetail= (await axios.get('/pra_sabha/' +this.searchsbaha)).data
                    }
                    if(this.userLevel==4 ){
                        this.sabhadetail= (await axios.get('/pra_sabha/' +this.sabha)).data
                        this.searchsbaha =  this.sabha
                       this.sbahaName = this.sabhadetail.sb_name_en
                       }
                       if(this.userLevel==3){
                        this.searchsbaha = this.$route.params.data1
                       this.sbahaName = this.$route.params.data2
                       this.sabhadetail= (await axios.get('/pra_sabha/' +this.searchsbaha)).data
                       }
           let incomesabhaData= (await axios.get('/sabha_income/' +this.searchsbaha)).data
           this.totalincome=incomesabhaData[0].invoice_count
            this.incomedetails= (await axios.get('/incmobyvote/'+this.searchsbaha)).data;
            // this.incomedetails= (await axios.get('/incmobyvote/'+this.searchsbaha)).data;
          
       },

    nextPage(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        prevPage(event) {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        event.preventDefault();
    }, 
      
         
    }

}

</script>

<style scoped>
.admin-container {
    background-color: #fff;
    /* height: 100vh; */
    padding: 2rem 9%;
    font-size: 12px;
   min-height: 90vh;
}
.ttable{
    width: 100%;
    font-size: 14px;
    
}
/* .ttable td{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
} */
.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #022e2a;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color:  #022e2a;
    
}

/* home.vue's all styles below */
.home-main,
.home-about,
.home-banner,
.home-category {
    padding: 2rem 9%;
    
}

.home-main {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    /* gap: 2rem; */
    position: relative;
    overflow: hidden;
    text-align: center;
   
    
}

.home-main .content {
    flex: 1 1 30rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    /* min-height: 90vh; */
}

.home-main .content span {
    font-size: 1.5rem;
    color: #022e2a;
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.home-main .content h4 {
    font-size: 1.7rem;
    color: #130f40;
    /* padding-top: .5rem; */
}

.home-main .content p {
    font-size: 1.6rem;
    color: #666;
    line-height: 2;
    padding: 1rem 0;
}

.home-main .image {
    flex: 1 1 41rem;
    margin: 2rem 0;
    pointer-events: none;
}

.home-main .image .home-img {
    width: 100%;
    margin-top: 5rem;
}

.home-main .home-parallax-img {
    position: absolute;
    top: -15rem;
    right: -15rem;
    width: 80vw;

}


/* home category */



/* home banner */

.home-banner .row-banner .content {
    position: absolute;
    
    left: 7%;
    transform: translateY(-50%);
}

.home-banner .row-banner .content span {
    font-family: 'Satisfy', cursive;
    font-size: 2rem;
    color: #022e2a;
    color: #130f40;
}

.home-banner .row-banner .content h3 {
    font-size: 6rem;
    color: red;
    text-transform: uppercase;
}

.home-banner .row-banner .content p {
    font-size: 2rem;
    padding-bottom: 1rem;
}


.home-banner .grid-banner .grid {
    border-radius: 1rem;
    overflow: hidden;
    height: 20rem;
    
}

.home-banner .grid-banner .grid:hover img {
    transform: scale(1.2);
}

.home-banner .grid-banner .grid img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.home-banner .grid-banner .grid .content {
    position: absolute;
    top: 2rem;
    padding: 0 2rem;
}


.btn {
    background-color: #d30c0c;   
    width: 15rem;
    height: 2.7rem;
    font-size: small;
    padding-top: 0.3rem;
    /* padding-bottom: 0.5rem; */
}
.btn1 {
    background-color: #eecd12;   
    width: 10rem;
    height: 2.7rem;
    font-size: small;
    padding-top: 0.3rem;
}
.btn2 {
    background-color: #f1f50b;   
    width: 20rem;
}
.home-banner .grid-banner .grid .content.center {
    text-align: center;
    width: 100%;
}

.home-banner .grid-banner .grid .content.center span {
    color: #ca9206;
}

.home-banner .grid-banner .grid .content.center h3 {
    color: #130f40;
}

.home-banner .grid-banner .grid .content span {
    font-size: 1.5rem;
    color: #fff;
}

.home-banner .grid-banner .grid .content h3 {
    font-size: 1rem;
    color: #fff;
    padding-top: .5rem;
}

.home-about {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    background: #f7f7f7;
}

.home-about .image {
    flex: 1 1 40rem;
}

.home-about .image img {
    width: 100%;
}

.home-about .content {

    flex: 1 1 40rem;
}

.home-about .content span {
    font-family: 'Satisfy', cursive;
    font-size: 3rem;
    color: #022e2a;
}

.home-about .content .title {
    font-size: 3rem;
    padding-top: .5rem;
    color: #130f40;
}

.home-about .content p {
    padding: 1rem 0;
    line-height: 2;
    font-size: 1.4rem;
    color: #666;
}

.home-about .content .icons-container {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.home-about .content .icons-container .icons {
    flex: 1 1 20rem;
    border-radius: .5rem;
    background: #fff;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}

.home-about .content .icons-container .icons h3 {
    font-size: 1.7rem;
    color: #130f40;
}

@media (max-width: 768px) {
    #menu-btn {
        display: inline-block;
    }

    .home-main .home-parallax-img {
        top: 0;
        right: 0;
        width: 100%;
    }

    .home-banner .grid-banner .content h3 {
        font-size: 15px !important;
    }

    .home-banner .grid-banner .content.center {
        padding-left: 0px !important;
    }

}

@media (max-width: 576px) {
    .home-main .content h3 {
        font-size: 1rem;
    }

    .home-main .content p {
        font-size: 1.5rem;
    }
}
</style>