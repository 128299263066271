<template>
  
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
     <div class="invoice-contain" >
         <div class="invoice-form-contain" >
             <form id="invoiceForm" novalidate autocomplete="off">
                <div id="PrintContent">
                 <h5>ප්‍රා.ස.3</h5>
               
 
                 <div class="form-group">
                    <div class="heading">
                     <!-- {{ invoicedetails }} -->
                     <h4 style="text-align: center; padding-top: 0px; padding-bottom: 0px;">Daily Receipts Register | දෛනික ලැබීම් ලේඛණය<br>
                     {{sabhadetail.sb_name_en}}| {{sabhadetail.sb_name_sin}}<br>
                    {{sabhadetail.sb_address}}</h4>
                  
                 </div>
                    <h6 style="padding-top: 0px; padding-bottom: 0px;"> <label for="iDate"> Date | දිනය: 
                          {{ new Date().toLocaleDateString() }}<br>
                          Income and other receipts | ආදායම් හා වෙනත් ලැබීම්
                     </label>
                 </h6>  

                 <!-- <table style="width: 100%;border-width:1px solid;border-color : #130f40;font-size: 0.9rem;border-collapse: collapse;" >
                 <thead>
                     <tr >   
                         <th s>Invoice Number | ලදුපත් අංකය</th>
                         <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Cash | මුදල්</th>
                         <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Cheque | චෙක්</th>
                         <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Total | එකතුව</th>
                         <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-for="h in headsar" :key="h.id">{{ h.sb_rate_head }}</th>
                         <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">STAMP | මුද්දර බද්ද</th>
 
                     </tr>
                 </thead>
                
                 <tbody>
                     <tr v-for=" r in invoicedetails" :key="r.id">
                         <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ r.invoice_num }}</td>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-if="r.cash_total==0"></td>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-else>{{ r.cash_total }}</td>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-if="r.cheque_total==0"></td>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-else>{{ r.cheque_total }}</td>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-if="r.invoice_status==0">{{ r.total_amount }}</td>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-else>Cancelled</td>
                         
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-for="(heads, index) in headsar" :key="index">
                             <div v-for="(group, index) in incomeData" :key="index">
                             <div v-for="(element, subIndex) in group" :key="subIndex" >

                               
                            
                                 <div v-if="r.invoice_num==element.invoice_num && heads.sb_rate_head== element.sb_rate_head">
                             {{ element.amount }}
                             </div>
                           
                             
                              </div>
                              </div>
                        
                             </td>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40;">{{ r.total_stamp }}</td>
                       
                     </tr>
                     <tr>
                         <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40;">Total:</th>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40;"><b>{{ cash_total }}</b></td>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40;"><b>{{ cheque_total }}</b></td>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40;"><b>{{ full_total }}</b></td>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40;" v-for="h in headsar" :key="h.sb_rate_head">
                             
                              <div >{{ calculateTotal(h.sb_rate_head).toFixed(2) }}</div>
                              
                        </td>
                         <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40;"><b>{{ stamp_full_total }}</b></td>
                     </tr>
                 </tbody>
             </table> -->
             <table style="width: 98%; text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">
                <thead>
                <tr>
                  <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Invoice Number | ලදුපත් අංකය</th>
                  <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Cash | මුදල්</th>
                  <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Cheque | චෙක්</th>
                  <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Total | එකතුව</th>
                  <th style="writing-mode: vertical-rl; transform: rotate(180deg); text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-for="h in headsar" :key="h.id">{{ h.sb_rate_head }}</th>
                  <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">STAMP | මුද්දර බද්ද</th>
                  <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">DISCOUNT </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="r in invoicedetails" :key="r.id">
                  <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.invoice_num }}</td>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ $formatNumber(r.cash_total) || '' }}</td>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ $formatNumber(r.cheque_total) || '' }}</td>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.invoice_status === 0 ? $formatNumber(r.total_amount) : 'Cancelled' }}</td>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-for="head in headsar" :key="head.id">
                    {{ incomeMap[r.invoice_num] && incomeMap[r.invoice_num][head.sb_rate_head] || '' }}
                  </td>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.total_stamp }}</td>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.total_discount }}</td>
                </tr>
                <tr>
                  <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Total:</th>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;"><b>{{ $formatNumber(cash_total) }}</b></td>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;"><b>{{ $formatNumber(cheque_total) }}</b></td>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;"><b>{{ $formatNumber(full_total) }}</b></td>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" v-for="h in headsar" :key="h.id">
                    <div>{{ $formatNumber(Number(calculateTotal(h.sb_rate_head)).toFixed(2)) }}</div>
                  </td>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;"><b>{{ $formatNumber(stamp_full_total) }}</b></td>
                  <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;"><b>{{ $formatNumber(dis) }}</b></td>
                </tr>
              </tbody>
            </table>
             <h6>The above mentioned receipts were verified and recorded in the revenue records. | ඉහත සඳහන් ලදුපත් පරික්ෂා කර බලා ආදායම් ලේඛණ වල සටහන් කරන ලදි. </h6><br>
             <h6>Cashier Signature | සරප් අත්සන ................................................................................... &nbsp; &nbsp;&nbsp;Checked By |පරික්ෂා කලේ .......................................................................... </h6>
             <h6>Date | දිනය&nbsp;&nbsp;................................................................................&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;Date | දිනය &nbsp;&nbsp;................................................................................</h6>
             
             </div>
             
            
           
             
                 <div class="form-group">
                     <label for="iDate"> Date-Time: 
                          {{ new Date().toLocaleString() }}
                     </label>    
             </div>
            </div>
             </form>
             <div class="form-group">
                    <input type="button" value="Print" class="btn" @click="printContent"/>
                    
                </div>
         </div>
         <br>
     
     </div>
 </template>
 <script>
 import axios from 'axios';
 import VueBasicAlert from 'vue-basic-alert';
 export default {
     name: "DailyIncome",
 
    data (){
 
     return{
             nic:"",
             sabha: "",
             userLevel:"",
             userName:"",
             sabhadetail:[],
             invoicedetails:[],
             cashier:[],
             total:0,
             employeedetail:[],
             date : new Date(),
             defaultdate:"",
             invoiceIncomeheads:[],
             invarray:[],
             headsar:[],
             incomeData:[],
             groupedArrayCustom:[],    
             cash_total:0,
             cheque_total:0,
             full_total:0,
             stamp_full_total:0,
             dis:0,
     }
    },
 
    created(){
     this.getDataFromSessionStorage()

    },
 
    methods:{
     formattedDate() {
         const day = this.date.getDate().toString().padStart(2, "0");
         const month = (this.date.getMonth() + 1)
           .toString()
           .padStart(2, "0");
         const year = this.date.getFullYear().toString();
         // this.defaultdate =`${year}-${month}-${day}`
         return `${year}-${month}-${day}`;
       },
    //    calculateTotal(sbRateHead) {
    //     let totalAmount = 0;
    //         for (let z = 0; z < this.incomeData.length; z++) {
              
    //             const innerArray = this.incomeData[z];
    //             for (let a = 0; a < innerArray.length; a++) {
    //                 if (sbRateHead == innerArray[a].sb_rate_head) {
    //                 totalAmount += parseFloat (innerArray[a].amount ||0);
                    
    //                 }

    //              }
    //         }

    //     return totalAmount;
    // },
    calculateTotal(sb_rate_head) {
    let total = 0;
    for (let invoice in this.incomeMap) {
      if (this.incomeMap[invoice][sb_rate_head]) {
        total += this.incomeMap[invoice][sb_rate_head];
      }
    }
    return isNaN(total) ? 0 : total;
  },
     async getDataFromSessionStorage(){
             const data = JSON.parse(sessionStorage.getItem('userData'))
             if(data){
                             this.nic = data.nic 
                             this.sabha = data.sabha
                             this.userLevel =data.userLevel
                             this.userName = data.userName
                         }
                         let sabhacode =this.sabha
              this.sabhadetail= (await axios.get('/pra_sabha/' +this.sabha)).data
              //get invoice details from invoice
              this.defaultdate=this.formattedDate()
            //   this.defaultdate= new Date();
//               let receiptreport = await axios.get('/invoicesum/' + sabhacode +"/"+ this.defaultdate)
//               this.invoicedetails = receiptreport.data
//              let headsForDate = await axios.get('/dailyheads/' + sabhacode +"/"+ this.defaultdate)
//              this.headsar = headsForDate.data
//                 let monytotal =0;
//                 let chqtotal =0;
//                 let fulTot =0;
//                 let fulStampTot =0;
//               for(let i =0; i<this.invoicedetails.length; i++){
//                 if(this.invoicedetails[i].invoice_status==0){
//                 monytotal += parseFloat (this.invoicedetails[i].cash_total ||0)
//                 chqtotal += parseFloat (this.invoicedetails[i].cheque_total ||0) 
//                 fulTot += parseFloat (this.invoicedetails[i].total_amount ||0) 
//                 fulStampTot += parseFloat (this.invoicedetails[i].total_stamp ||0) 

//                  for(let x =0; x<this.headsar.length; x++){
//                  let allinvoiceIncomeheads = await axios.get('/dailyinvoiceheads/' + sabhacode +"/"+ this.defaultdate +"/"+ this.invoicedetails[i].invoice_num+"/"+this.headsar[x].sb_rate_head)
//                  this.invoiceIncomeheads[x] = allinvoiceIncomeheads.data
            
//              if(this.invoiceIncomeheads[x].length==0){
//                  const incomereport ={
//                  invoice_num: '',
//                  sb_rate_head :'',
//                  amount: '',
//              } 
//              this.incomeData.push(incomereport)
//              }else{
//                  this.incomeData.push(this.invoiceIncomeheads[x])
//              }
            
            
//                  }
//              this.invarray.push(this.invoiceIncomeheads[i])
//                 }  
//          }
//          this.cash_total=monytotal.toFixed(2)
//          this.cheque_total = chqtotal.toFixed(2)
//          this.full_total = fulTot.toFixed(2)
//          this.stamp_full_total= fulStampTot.toFixed(2)
         
// for (let y = 0; y < this.incomeData.length; y += this.headsar.length) {
// this.groupedArrayCustom.push(this.incomeData.slice(y, y + this.headsar.length));
// }
try {

// const receiptreport = await axios.get(`/invoicesum/${sabhacode}/${this.defaultdate}`);
//  this.invoicedetails = receiptreport.data;
//  const headsForDate = await axios.get(`/dailyheads/${sabhacode}/${this.defaultdate}`);
//  this.headsar = headsForDate.data;
const [receiptreport, headsForDate] = await Promise.all([
        axios.get(`/invoicesum/${sabhacode}/${this.defaultdate}`),
        axios.get(`/dailyheads/${sabhacode}/${this.defaultdate}`)
    ]);

    this.invoicedetails = receiptreport.data;
    this.headsar = headsForDate.data;

 let monytotal = 0;
 let chqtotal = 0;
 let fulTot = 0;
 let fulStampTot = 0;
 let fuldisTot = 0;
 const incomeMap = {};

 for (let invoice of this.invoicedetails) {
   if (invoice.invoice_status === 0) {
     monytotal += parseFloat(invoice.cash_total || 0);
     chqtotal += parseFloat(invoice.cheque_total || 0);
     fulTot += parseFloat(invoice.total_amount || 0);
     fulStampTot += parseFloat(invoice.total_stamp || 0);
     fuldisTot += parseFloat(invoice.total_discount || 0);

     for (let head of this.headsar) {
       // const allinvoiceIncomeheads = await axios.get('/dailyinvoiceheads/'+sabhacode +"/"+this.defaultdate+"/"+invoice.invoice_num+"/"+head.sb_rate_head)
       const allinvoiceIncomeheads = await axios.get(`/dailyinvoiceheads/${sabhacode}/${this.defaultdate}/${invoice.invoice_num}/${head.sb_rate_head}`);
       if (allinvoiceIncomeheads.data.length > 0) {
         if (!incomeMap[invoice.invoice_num]) {
           incomeMap[invoice.invoice_num] = {};
         }
         incomeMap[invoice.invoice_num][head.sb_rate_head] = parseFloat(allinvoiceIncomeheads.data[0].amount)|| 0;
       }
     }
   }
 }

 this.cash_total = monytotal.toFixed(2);
 this.cheque_total = chqtotal.toFixed(2);
 this.full_total = fulTot.toFixed(2);
 this.stamp_full_total = fulStampTot
 this.dis=fuldisTot
 this.incomeMap = incomeMap;

} catch (error) {
 console.error('Error generating report:', error);
}
     // get income heads of daily invoice
            //  let allinvoiceIncomeheads = await axios.get('/dailyinvoiceheads/' + sabhacode +"/"+ this.defaultdate +"/"+ this.invoicedetails.invoice_num)
            //  this.invoiceIncomeheads = allinvoiceIncomeheads.data
         },
         async getMatchUser(nic) {
             // await axios.get('/employee/' + nic);
             let data = await axios.get('/employee/' + nic);
             this.matchUser = data.data;
         },
         
    //print content
    printContent() {
      
    const prtHtml = document.getElementById('PrintContent').innerHTML;

// Open the print window
const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
WinPrint.document.write(`
    ${prtHtml}
`);

WinPrint.focus();
WinPrint.print();
WinPrint.close();
    }
         
    },
    components: {
         VueBasicAlert
     }
 };
 </script>
 <style scoped>
 .invoice-contain {
     background-color: #fff;
     /* height: 100vh; */
     /* padding: 2rem 25%; */
     padding: 1rem;
     font-size: 16px;
     align-content: center;
     min-height: 72.3vh;
 }
 .invoice-contain .invoice-form-contain {
     background: #fff;
 
 }
 
 .invoice-contain .invoice-form-contain form {
     position: relative;
     /* left: 50%; */
     /* transform: translate(-50%, 0%); */
     width: 100%;
     box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
     border: 0.1rem solid rgba(0, 0, 0, 0.2);
     padding: 1rem;
     border-radius: 0.5rem;
     animation: fadeUp 0.4s linear;
     padding-left: 5rem;
 }
 .invoice-contain .invoice-form-contain form h3 {
     /* padding-bottom: 1rem; */
     font-size: 1.7rem;
     text-transform: uppercase;
     color: #130f40;
     margin: 0;
 }
 .ttable {
     
     width: 100%;
     border-width:1px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 0.9rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      border-collapse: collapse;
 }
 th  {
     text-align: center;
}
.ttable, th, td {
border: 1px solid;
}

 
 .table-responsive {
     margin-top: 8vh;
     height: 500px;
 }
 
 .action-btn,
 .cancel-btn,
 .paid-btn {
     width: 100px;
     height: 25px;
     color: white;
     text-transform: capitalize;
 }
 
 .action-btn {
     background-color: #0da9ef;
     margin-right: 10px;
 }
 
 .cancel-btn,
 
 .action-btn:hover {
     background-color: #27ae60;
 }
 
  .invoice-contain .invoice-form-contain form .form-group {
     margin:0;
     font-size: 1rem;
 } 
 
 .invoice-contain .invoice-form-contain form .form-group .error-mess {
     font-size: 1.5rem;
     position: relative;
     color: rgb(243, 47, 47);
     margin: 0;
     padding: 0;
 }
 .invoice-contain .btn {
     background: rgb(223, 80, 14);;
     color: rgb(240, 234, 231);
     border: 1rem;
     padding: 1rem;
     font: inherit;
     cursor: pointer;
     outline: 1rem;
     text-align: center;
    
 }
 .invoice-contain .btnrow{
    align-content: center;
    padding-left: 25rem;
 }

 
 </style>