<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="Shop-container">
        <div class="row1">
           <router-link to="/asesdash" class="close-btn" > Close </router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h1>Manage Sub Categories</h1>
       </div>
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
                <div class="heading">
                    <b><h3>Add Categories</h3></b>
                </div>
               
               <div class="row">
               <div class="col-sm-4">
                   <label for="Snum">Sub Category:
                   </label>
                   <input type="text" name="Snum" id="Snum" class="form-control" 
                     v-model="CatObj.subc" />
                    <p class="error-mess" v-if="errObj.subcErr.length > 0">{{ errObj.subcErr[0] }}</p>
               </div>
               <div class="Addbtn col-sm-4">
                   <label for="s">
                   </label>
                   <input type="button" value="Add" id="Addpay" class=" btn" @click="addToTable"  /> 
               </div>
           </div>
           <br>
           <div style="width: 100%; text-align: center;">
                <h3>Added Sub Categories</h3>
            </div>
           <div class="row">
            <table style="width: 90%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
               <thead>
                   <tr>   
                        <th>Index</th>
                       <th>Sub Category</th>
                       <th>Edit</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">
                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                       <td style="text-align: left;">{{ s.sub_cat_name  }}</td>
                       <td><router-link to="" @click="openPopup(s.sub_id)"  class="fas fa-edit" style="color: darkgreen;"> </router-link></td>
                     <!-- shop_place 
                     <td><input type="button" @click="deleteShop(s.shop_place_id,s.shop_number)" value="X" class=" btn"></td>-->
                   </tr>
               </tbody>
           </table>
           </div>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
           </form>
            <!-- popup edit -->
     <div class="modal" v-if="showPopup">
      <div class="modal-content" ref="modalRef">
        <button class="close-x" @click="closePopup"> X</button>
        <div class="modal-header">
          <h1>Edit Sub Category</h1>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="model-group">
                   <label for="steetname"><b>Category Name:</b>
                   </label>
                   <input type="text" name="strrtn" class="form-control" 
                   v-model="popObj.Streetname"/>
                   <p class="error-mess" v-if="popErr.popstreetErr.length > 0">{{ popErr.popstreetErr[0] }}</p>
               </div>
            </div>
        </div> 
        <div class="modal-footer">

          <button class="close-button" @click="closePopup">Close</button>
          <button class="save-button" @click="UpdateStreet">Update</button>
        </div>
              <!-- popup end -->
               </div>
               </div>
       </div>
</div>
<!-- end -->
</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {

    name: "AssessCategories",

    data() {
       return {
           nic:"",
           sabha: "",
           userLevel:"",
           CatObj : {subc:"" },
           errObj:{subcErr:[]},
           subCatall:[],
           itemsPerPage: 10,
            currentPage: 1,
            showPopup:false,
            popObj:{Streetname:""},
            popErr:{popstreetErr:[]},
            selectedCat:[],
            selectedcatid:""
       };
   },
   created() {
       this.getDataFromSessionStorage()
   },
   computed:{
       ...mapState(["user"]),

    paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.subCatall)) {
        return this.subCatall.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.subCatall.length / this.itemsPerPage);
    },
   },

   methods: {
       ...mapMutations(["setUser"]),

       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }
           this.subCatall = (await axios.get('/scatbysabha/'+this.sabha)).data
       },
      
nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  event.preventDefault();
},
prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  event.preventDefault();
},

       resetCheckErr: function () {
           this.errObj.subcErr =[];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           
           if (!this.CatObj.subc) {
               this.errObj.subcErr.push("required");
           }
       },
       async addToTable(e) {
            this.checkForm();
            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                e.preventDefault();
                    let data = {
                        sb_code  : this.sabha,
                        sub_cat_name:this.CatObj.subc   
                    }
                    await axios.post("/savesubc/", data)
                    this.$refs.alert.showAlert('success', 'Added Successfully !')
                    this.subCatall = (await axios.get('/scatbysabha/' +this.sabha)).data
            }
        },
        //popup
async openPopup(id) {
            this.showPopup = true;
            document.addEventListener('mouseup', this.closeModalOnClickOutside);
            this.selectedCat= (await axios.get('/sucatbyid/'+id)).data;
            if( this.selectedCat){
            this.popObj.Streetname=this.selectedCat[0].sub_cat_name;
            this.selectedcatid=id;
            }
        },
        closePopup() {
            this.showPopup = false;
            document.removeEventListener('mouseup', this.closeModalOnClickOutside);
        },
        closeModalOnClickOutside(event) {
            const modal = this.$refs.modalRef;
            if (!modal.contains(event.target)) {
                this.closePopup();
            }
        },
        // popup empty check
        checkPopEmptyErr: function () {
            for (var typeErr in this.popErr) {
                if (this.popErr[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
        resetCheckArrPopErr: function () { 
            this.popErr.popstreetErr = [];
          
        },
        checkArePopup: function () {
            this.resetCheckArrPopErr();
            if (!this.popObj.Streetname) {
                this.popErr.popstreetErr.push("Name is required");
            }
        },
        async UpdateStreet(s) {
            this.checkArePopup();

            if (!this.checkPopEmptyErr()) {
                s.preventDefault();
            }else{

                let data ={
                    sub_cat_name:this.popObj.Streetname
                }
                await axios.put("/upsubcatname/"+this.selectedcatid,data)
                this.$refs.alert.showAlert('success', 'Sub Category updated Successfully !')
                this.closePopup();
                this.subCatall = (await axios.get('/scatbysabha/' +this.sabha)).data
            }
        },
   },
   components: {
        VueBasicAlert
    }
};

</script>

<style scoped>
.row1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .close-btn {
  /* align-items: right; */
  background-color:rgb(121, 23, 12);
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #f8f4f4;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 8px;
  border-radius: 8px;
  width:6%;
  height: 4%;
  text-align: center;
}
.close-btn:hover {
  color: rgb(220, 18, 18);
  transform: scale(1.2);
  background-color:  #022e2a; 
}

.close-btn:focus {
  outline:none; /* Remove default focus outline */
}
.upbtn {
    align-items: right;
    padding: 10px 20px;
    background-color: #054818;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center; 
    width: 15rem;
    height: 2.7rem;
    font-size: small;
    padding-top: 0.3rem;
    margin: 1rem 0;
    border-radius: 5px;
}
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}
/* shroff form container */
.Shop-container {
   background-color: #ffffff09;
   /* height: 50vh; */
   padding: 1rem 2%;
   font-size: 16px;
}

/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   /* margin: 1rem 0; */
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
    height: 3rem;
    color: #fffbfb;
  
   
}
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: rgb(243, 47, 47);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
/* .project-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
} */
.add-container {
    padding: 2rem 9%;
    
}

.add-container .add-form-container {
    background: #fff;

}

.add-container .add-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.add-container .add-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.add-container .add-form-container form .form-control {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.6rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}  

.add-container .add-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.add-container .add-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
     color: #4e0707;
}

.add-container .add-form-container form .btn {
    margin: 1rem 0;
    width: 25%;
    text-align: center;
    /* background-color: #af74a7 ; */
    align-content: center;
    background-color : #032a2c;
  
    color: #e9e4e9;
}

.add-container .add-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.add-container .add-form-container form p a {
    color: #27ae60;
}

.add-container .add-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.add-container .add-form-container form .form-group {
    margin: 0;
}

.add-container .add-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
/* pop up */
.modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
      border-radius: 0.5rem;
  }

  .modal-content {
      background-color: white;
      padding: 5px;
      border-radius: 1px;
      /* max-width: 500px; */
      width: 30%;
      position: relative;
      font-size: 1.3rem;
  }

  .close x {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 18px;
      color: #555;
  }

  .close-x:hover {
      color: #ff0000;
      /* Change to your desired hover color */
  }


  .modal-header {
      padding-bottom: 2px;
      border-bottom: 1px solid #ccc;
      text-align: center;
  }

  .modal-body {
      /* padding: 10px 20px 20px 20px; */
      padding-left: 5%; 

  }
  .model-group {
      padding: 10px ;
      width:90%;
      /* padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 10%; */
      /* margin: 10px; */
      /* justify-content: left; */
      /* margin-right: 10px; */
  }
  .modal-body.model-group .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 10%;
    padding: 0;
    width: 90%;

} 
.error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
.modal-body.row {
    display: flex;
    margin: 10%;
  }
  .modal-footer {
      padding-top: 5px;
      border-top: 1px solid #ccc;
      display: flex;
      justify-content: flex-end;
  }

  .close-button,
  .save-button {
      padding: 5px 10px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 4px;
      transition: background-color 0.3s ease;
  }

  .close-button {
      background-color: #f44336;
      color: white;
  }

  .save-button {
      background-color: #4CAF50;
      color: white;
      margin-left: 5px;
  }

  .close-button:hover {
      background-color: #d32f2f;
  }

  .save-button:hover {
      background-color: #45a049;
  }
  .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}
</style>