<template>
     <div class="menu-section">
        
<!-- start -->
<vue-basic-alert :duration="400" :closeIn="2000" ref="alert" />
<div class="register-container">
        <div class="register-form-container">
            <form id="userForm" novalidate autocomplete="off">
                <div class="heading">
                    <h3>Cashier {{ employeedetail.status }} </h3>
                </div>
                <div class="container">
                    <label for="cNic">NIC Number: 
                    </label>
                   <span> <input type="text" name="cNic" placeholder="Customer NIC" id="cNic" class="form-control"
                    @input ="showCustomer" v-model="cusObj.nic"/>
                        <p class="error-mess" v-if="errObj.nicErr.length > 0">{{ errObj.nicErr[0] }}</p>
                    </span> 
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-sm">
                            <label for="cName">Name:
                                        </label>
                                        <input type="text" name="cName" placeholder="Customer Name" id="cName" class="form-control"
                                            v-model="cusObj.name" />
                                            <p class="error-mess" v-if="errObj.nameErr.length > 0">{{ errObj.nameErr[0] }}</p>
                        </div>
                        <div class="col-sm">
                            <label for="cPhone">Contact:
                                        </label>
                                    <input type="text" name="cPhone" placeholder="Contact number" id="cPhone" class="form-control" 
                                        v-model="cusObj.phone" />
                                        <p class="error-mess" v-if="errObj.phoneErr.length > 0">{{ errObj.phoneErr[0] }}</p>
                        </div>
                        <div class="col-sm">
                            <label for="cAddress">Address:
                                        </label>
                                    <input type="text" name="cAddress" placeholder="Customer Address" id="cAddress" class="form-control" 
                                        v-model="cusObj.address" />
                                        <p class="error-mess" v-if="errObj.addErr.length > 0">{{ errObj.addErr[0] }}</p>
                        </div>
                    </div>
                </div>
                            <div class="form-group">
                                <input type="button" @click="handleSubmit1" value="Save" class="btn" id ="Add" />
                            </div>

            </form>
        </div>

<!-- end -->
</div>

            <!-- <div class="col-sm-12"> -->
                <!-- <div class="row">
                    <div class="menu-tabs">
                        <input type="button" id="allFilterFoodBtn" name="allFilterFoodBtn" value="Income"
                            class="menu-tab-item" @click="filterFoodBtn($event)" />
                        
                    </div>
                </div> -->
<!-- start -->
<div class="shroff-container">
        <div class="shroff-form-container">
            <form id="addIncomeForm"  novalidate autocomplete="off">
                <!-- <div class="heading">
                    <span>Employee Details</span>
                </div> -->
                
                <div class="form-group">
                    <label for="iHead">Income Head:
                    </label>
                  <select v-model="incomeObj.headid" id="iHead" class="form-control" >
                        <option value="" selected disabled>
                                 Choose
                        </option>
                        <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.rate_head_id "  
                            v-bind:value =ratehead.sb_rate_head >
                              {{ ratehead.sb_rate_head  }}
                        </option>
                    </select>

                    <p class="error-mess" v-if="errIncomeObj.iheadErr.length > 0">{{ errIncomeObj.iheadErr[0] }}</p> 
                </div>
                <div class="form-group">
                    <label for="iDes">Description:
                    </label>
                    <input type="textarea" name="iDes" placeholder="Enter Name" id="iDes" class="form-control"
                     v-model="incomeObj.des"/>
                     <p class="error-mess" v-if="errIncomeObj.iDesErr.length > 0">{{ errIncomeObj.iDesErr[0] }}</p>
                </div>
                <div class="row">
                    <div class="col-sm-2">
                    <label for="iAmount">Amount:
                    </label>
                    <input type="text" name="iAmount" placeholder="enter Amount payable" id="iAmount" class="form-control" 
                      v-model="incomeObj.amount" />
                     <p class="error-mess" v-if="errIncomeObj.amountErr.length > 0">{{ errIncomeObj.amountErr[0] }}</p>
                </div>
                <div class="col-sm-2">
                    <label for="iStamp">Stamp Fee: (%)
                    </label>
                    <input type="text" name="iStamp" placeholder="enter if any special Rates" id="iStamp" class="form-control" 
                        v-model="incomeObj.stamp" />
                    <p class="error-mess" v-if="errIncomeObj.stampErr.length > 0">{{ errIncomeObj.stampErr[0] }}</p>
                </div>
                <div class="col-sm-2">
                    <label for="iDisc">Discounts: (%)
                    </label>
                    <input type="text" name="iDisc" placeholder="enter if any special Discounts" id="iDisc" class="form-control" 
                        v-model="incomeObj.discounts" />
                    <p class="error-mess" v-if="errIncomeObj.discErr.length > 0">{{ errIncomeObj.discErr[0] }}</p>
                </div>
                <div class="col-sm-2">
                    <label for="iDisc">VAT: (%)
                    </label>
                    <input type="text" name="iVat" id="iVat" class="form-control" 
                        v-model="incomeObj.vat" />
                    <p class="error-mess" v-if="errIncomeObj.vatErr.length > 0">{{ errIncomeObj.vatErr[0] }}</p>
                </div>
            
                <div class="Addbtn col-sm-4">
                    <label for="iRates">
                    </label>
                    <input type="button" value="Add" id="Addpay" class=" btn" @click="addToTable" /> 
                </div>
            </div>
            <br>
            <div class="row">
                <table class="table colored-header datatable project-list" >
                <thead>
                    <tr>   
                        <th>Income head</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Stamp fee</th>
                        <th>Discounts</th>
                        <th>VAT</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" r in allreceipt" :key="r.id">

                        <td>{{ r.sb_rate_head  }}</td>
                        <td>{{ r.description }}</td>
                        <td>{{ r.amount }}</td>
                        <td>{{ r.stamp }}</td>
                        <td>{{ r.discount }}</td>
                        <td>{{ r.vat }}</td>
                        <td><input type="button" @click="deleteReceipt(r.id)" value="Remove" class=" btn"></td>
                      
                    </tr>
                </tbody>
            </table>
            </div>
            <div class="row">
                <router-link  @click="nextClick()" to="" class=" btn" >Next</router-link>
            </div>
            </form>
        </div>
</div>
<!-- end -->
</div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import VueBasicAlert from 'vue-basic-alert';
export default {
    name: "Shroff",

    data() {
        return {
            cusObj : { nic: "", name: "", phone:"", address:"" },
            errObj : {nicErr:[], nameErr:[], phoneErr:[], addErr:[]},
            matchCus : undefined,
            theUser :[],
            nic:"",
            sabha: "",
            userLevel:"",
            incomeObj : { headid:"", des:"", amount:"", stamp:"", discounts:"", vat:""},
            errIncomeObj:{iheadErr:[], iDesErr:[], amountErr:[], stampErr:[], discErr:[], vatErr:[]},
            sabhaIncomeHead : undefined,
            ratehead : '',
            tablear:[],
            allreceipt:[],
            arrayy: undefined,
            employeedetail:[],

        };
    },
    created() {
        this.getDataFromSessionStorage(),
        this.showCustomer()
      
    },
    computed:{
        ...mapState(["user"]),
    },

    methods: {
        ...mapMutations(["setUser"]),

        async getDataFromSessionStorage(){
            const data = JSON.parse(sessionStorage.getItem('userData'))
            if(data){
                            this.nic = data.nic 
                            this.sabha = data.sabha
                            this.userLevel =data.userLevel
        

                        }
            let sabhaCode = this.sabha
            let Sabhadata = await axios.get('/sabharate/' + sabhaCode)
            this.sabhaIncomeHead = Sabhadata.data;

            //get employee table's status
            // let empStatus =  await axios.get('/employee/' + this.nic)
            // this.employeedetail = empStatus.data
        },
        async getMatchUser(nic) {
            // await axios.get('/employee/' + nic);
            let data = await axios.get('/employee/' + nic);
            this.matchUser = data.data;
        },
       
       async deleteReceipt(id){
            await axios.delete("/singlerece/" +  id );
            this.allreceipt = (await axios.get('/receiptrow/' +this.cusObj.nic)).data
        
        },


        async showCustomer() {

        this.theUser= (await axios.get('/customers/' +this.cusObj.nic)).data;
        
         if(this.theUser)
         { 
            this.cusObj.name = this.theUser.cus_name;
            this.cusObj.phone = this.theUser.cus_contact;
            this.cusObj.address = this.theUser.cus_address;
            document.getElementById("Add").style.visibility ="hidden" ;
         }
         else{
            document.getElementById("Add").style.visibility ="visible" ;
            this.cusObj.name = " ";
            this.cusObj.phone = " ";
            this.cusObj.address = " "; 
         }
   },
   async nextClick(){
    sessionStorage.setItem("cus_nic",JSON.stringify(this.cusObj.nic) )

    await this.getMatchUser(this.nic); 
            if(this.matchUser.status ==1)
    {
        this.$router.push("/Invoice");
        }else {
            this.$router.push("/ShroffDashboard");
                }
   },
        async getMatchCus(nic)
        {
            await axios.get('/customers/' + nic);
        },

        resetCheckErr: function () {
            this.errObj.nicErr = [];
            this.errObj.nameErr = [];
            this.errObj.phoneErr = [];
            this.errObj.addErr = [];
        },
        checkEmptyErr: function () {
            for (var typeErr in this.errObj) {
                if (this.errObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
        checkForm: function () {
            this.resetCheckErr();
             // NIC validate

             if (!this.cusObj.nic){
                this.errObj.nicErr.push("12 Charater NIC is required");
            }else {
                if (!/[0-9]/.test(this.cusObj.nic)){
                    this.errObj.nicErr.push('NIC can only contain numbers');
                }
                if (this.cusObj.nic.length !== 12) {
                    this.errObj.nicErr.push('NIC must be 12 characters');
                }

            }

            // Name validate
            if (!this.cusObj.name) {
                this.errObj.nameErr.push("Entering a name is required");
            }
            else {
                if (!/^[A-Za-z]+$/.test(this.cusObj.name.replace(/\s/g, ""))) {
                    this.errObj.nameErr.push('A name can only contain letters');
                }
            }
           
            // Phone validate
            if (!this.cusObj.phone) {
                this.errObj.phoneErr.push('Entering phone number is required');
            }
            else {
                if (!this.cusObj.phone.startsWith('94')) {
                    this.errObj.phoneErr.push('Phone numbers must start with 94');
                }

                if (this.cusObj.phone.length != 11) {
                    this.errObj.phoneErr.push('Phone numbers must have exactly 11 digits');
                }

                if (!/[0-9]{11}/.test(this.cusObj.phone)) {
                    this.errObj.phoneErr.push('Phone numbers can only contain numbers');
                }
            }
              // Address Validate
              if (!this.cusObj.address) {
                this.errObj.addErr.push("Entering an address is required");
            }   

        },
       
        async handleSubmit1(e) {
            await this.getMatchUser(this.nic); 
            if(this.matchUser.status ==1) {
                this.checkForm();

if (!this.checkEmptyErr()) {
    e.preventDefault();
} else {
    e.preventDefault();
    await this.getMatchCus(this.cusObj.nic);
    if (this.matchCus) {
        this.$refs.alert.showAlert('error', 'Something Went Wrong')

    }
    else {
        let data = {
            cus_nic: this.cusObj.nic,
            cus_name: this.cusObj.name,
            cus_contact: this.cusObj.phone,
            cus_address: this.cusObj.address,

            
        }
        let sabhaCustomerData = {
            sabha_code : this.sabha ,
            cus_nic : this.cusObj.nic,

};
        await axios.post("/customers/", data)

        await axios.post('/sbha_cutomers/' , sabhaCustomerData);
       
        this.$refs.alert.showAlert('success', 'User Added Successfully !')
        // document.getElementById("userForm").reset();
      
      
    }
}
       
        }else {
            this.$router.push("/ShroffDashboard");
                   
        }
        },
        // second Form handle

        resetCheckErr2: function () {
            this.errIncomeObj.iheadErr = [];
            this.errIncomeObj.iDesErr = [];
            this.errIncomeObj.amountErr =[];
            // this.errIncomeObj.stampErr = [];
            // this.errIncomeObj.discErr = [];

        },
        checkEmptyErr2: function () {
            for (var typeErr in this.errIncomeObj) {
                if (this.errIncomeObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
        checkForm2: function () {
            this.resetCheckErr2();
            // income head Validate
            if (!this.incomeObj.headid) {
                this.errIncomeObj.iheadErr.push("Income head is required");
            }
            if (!this.incomeObj.des) {
                this.errIncomeObj.iDesErr.push("Description is required");
            }
            if (!this.incomeObj.amount) {
                this.errIncomeObj.amountErr.push("Amount is required");
            }
            // if (!this.incomeObj.stamp) {
            //     this.errIncomeObj.stampErr.push("stamp fee is required");
            // }
            // if (!this.incomeObj.discounts) {
            //     this.errIncomeObj.discErr.push("discount required");
            // }
        },
        async addToTable(e){
            this.checkForm2();

    if (!this.checkEmptyErr2()) {
        e.preventDefault2();
    } else {
        e.preventDefault2();
       
    
           
           let data ={
                   cus_nic : this.cusObj.nic,
                   cus_name : this.cusObj.name,
                   cus_contact : this.cusObj.phone,
                   cus_address:this.cusObj.address,
                   sb_rate_head : this.incomeObj.headid,
                   description : this.incomeObj.des,
                   amount : this.incomeObj.amount,
                   stamp : this.incomeObj.stamp,
                   discount : this.incomeObj.discounts,
                   vat: this.incomeObj.vat,
                //    date : new Date().toLocaleString(),
                //    sub_nic : this.nic,
                  }
                   await axios.post("/receipt/", data)
                   this.allreceipt = (await axios.get('/receiptrow/' +this.cusObj.nic)).data
                   //clear 2nd form values
                   this.incomeObj.headid="",
                   this.incomeObj.des="",
                   this.incomeObj.amount="",
                   this.incomeObj.stamp="",
                   this.incomeObj.discounts=""
                   this.incomeObj.vat=""
                }
                   
       },
        // async handleSubmit2(e) {
        //     this.checkForm2();

        //     if (!this.checkEmptyErr2()) {
        //         e.preventDefault2();
        //     } else {
        //         e.preventDefault2();
        //         await this.nextClick(this.cusObj.nic);
               
        //     }

        // }

    },
    components: {
        VueBasicAlert
    }

};

</script>

<style scoped>
input[type="button"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.unselect-btn:active,
.unselect-btn:focus,
.action-btn:active,
.action-btn:focus {
    border: none;
    outline: none;
}

hr {
    border-top: 3px solid #057835fa;
    width: 100%;
}

.unselect-btn {
    background: transparent;
    padding-right: 10px;
    cursor: pointer;
    color: inherit;
    display: none;
}


.filter-section {
    width: inherit;
}

.filter-heading {
    padding-top: 30px;
}

.filter-heading h1 {
    color: #27ae60;
}

.filter-option {
    list-style-type: none;
    width: inherit;
}

.filter-option label {
    width: 100%;
    font-size: 15px;
    padding: 3px 0px;

}

.filter-option label:hover {
    /* color: white;
    background-color: white !important;
    transition: all 0.5s ease; */
}

.search-box {
    width: 100%;
    justify-content: center;
    position: relative;
    display: flex;
}
/* customer tab below */
.search-input {
    margin: 0;
    width: 100%;
    height: 40px;
    font-size: 20px;
    color: white;
    background: #5e5c5cda;
    text-align: center;
    /* background: #27ae60; #8b307fda; */
    
}

::placeholder {
    color: white;
}

.menu-section {
    padding: 1rem 9%;
}

.menu-section .menu-tabs {
    /* margin-bottom: 30px; */
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    background: #5e5c5cda;
    /* background-color: #27ae60; Income Topic */
}

.menu-section .menu-tabs .menu-tab-item {
    display: inline-block;
    cursor: pointer;
    padding: 5px 30px;
    border-radius: 30%;
    font-size: 20px;
    /* color: rgb(19, 18, 18); heading color */
    color: whitesmoke;
    font-weight: 500;
    text-transform: capitalize;
    transition: all 0.3s ease;
    margin: 0;
}

.menu-section .menu-tabs .menu-tab-item:hover {
   
    /* background-color: #f38609 !important; */
}

.menu-section .menu-tabs .menu-tab-item p {
    padding: none;
    margin: none;
}

.menu-section .box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 1.5rem;
    
}

.menu-section .box-container .box {
    border-radius: 0.5rem;
    position: relative;
    background: #f7f7f7;
    padding: 2rem;
    text-align: center;
    
}

.menu-section .box-container .box .fa-heart {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 2.5rem;
    color: #666;
    cursor: pointer;
}

.menu-section .box-container .box .fa-heart:hover {
    color: #27ae60;
}

.menu-section .box-container .box .image {
    margin: 1rem 0;
}

.menu-section .box-container .box .image img {
    height: 15rem;
}

.menu-section .box-container .box .content h3 {
    font-size: 2rem;
    color: #130f40;
}

.menu-section .box-container .box .content .stars {
    padding: 1rem 0;
    font-size: 1.7rem;
}

.menu-section .box-container .box .content .stars i {
    color: gold;
}

.menu-section .box-container .box .content .stars span {
    color: #666;
}

.menu-section .box-container .box .content .desc p {
    font-size: 14px;
    margin: 0;
}

.menu-section .box-container .box .content .price {
    font-size: 2rem;
    color: #130f40;
}

.menu-section .box-container .box .content .price span {
    font-size: 1.5rem;
    color: #666;
    /* color: #911580; */
    text-decoration: line-through;
    
}

.menu-section .action-row {
    padding-top: 30px;
    width: 100%;
    text-align: center;
    font-size: 20px;
}

.menu-section .action-row .action-btn {
    background-color: #27ae60;
    padding: 3px;
    border: 2px solid #27ae60;
    border-radius: 30%;
    color: white;
}

.menu-section .action-row span {
    margin-right: 15px;
}

.menu-section .action-row span:hover {
    cursor: pointer;
}

.menu-section .action-row span.highlight {
    color: #f38609;
    
}

.menu-section .action-row span:first-of-type {
    margin-left: 15px;
}

.filter-drop-down {
    display: none;
}

@media (min-width: 576px) {

    .filter-heading,
    .filter-section {
        display: block !important;
    }
}

@media (max-width: 768px) {
    .menu-section .box-container {

        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        gap: 1rem;
         
    }

    .menu-section .box-container .box .content h3 {
        height: 4rem;
    }
}

@media (max-width: 576px) {

    .search-box,
    .filter-heading,
    .filter-section {
        width: auto;
    }

    .filter-option {
        width: 100%;
    }

    .filter-drop-down {
        display: block;
        background-color: #27ae60;
        color: white;
        font-weight: 400;
        margin-bottom: 15px;
        margin-top: 15px;

    }

    .filter-drop-down p {
        font-size: 20px;
        padding: 5px 0px;
        margin: 0;
        display: flex;
        justify-content: space-between;
    }

    .filter-drop-down p span {
        font-size: 20px;
        padding-right: 10px;
        text-transform: lowercase;
        font-weight: 300;
    }

    .filter-heading,
    .filter-section {
        display: none;
    }

    .menu-tabs .menu-tab-item {
        font-size: 12px !important;
        padding: 5px 20px !important;
    }

    .menu-section .action-row {
        font-size: 16px !important;
    }

    .menu-section .action-row span {
        margin-right: 5px;
    }

    .menu-section .box-container .box .image img {
        height: 10rem;
    }

    .menu-section .box-container .box .desc p,
    .menu-section .box-container .box .content .stars {
        font-size: 10px !important;
    }

    .menu-section .box-container .box .content h3 {
        font-size: 14px !important;
        height: 28px;
    }
}
/* new */
.admin-container {
    background-color: #fff;
    height: 100vh;
    padding: 2rem 9%;
    font-size: 16px;
}

.project-list>tbody>tr>td {
    padding: 12px 8px;
    font-size: small;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 20vh;
    height: 300px;
     padding: 8px 90px;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #0da9ef;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color: #27ae60;
}
.order-section form .row .input-box {
    width: 49%;
    padding: 1.8rem 0;
} 
.order-section form .row .p {
    font-size: 1rem;
   color: rgb(243, 47, 47);
   
} 
/* newly added from income heads */
.register-container {
    padding: 2rem 9%;
}

.register-container .register-form-container {
    background: #fff;
    /* width: 100% !important; */
}

.register-container .register-form-container form {
    /* position: relative; */
    /* left: 40%; */
    /* transform: translate(-50%, 0%); */
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.3rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: bottom 1px solid; 
   
} 
.register-container .register-form-container form .form-control1 {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.3rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: bottom 1px solid; 
} 

.register-container .register-form-container form label {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
    float:left;
}

.register-container .register-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
    display: block; 
    overflow: hidden; 
    
}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 10%;
    text-align: center;
    background-color : #af74a7;
    height: 30px;
    font-size: 1.3rem; 
}

.register-container .register-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #e23838;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group {
    margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
.register-container .register-form-container form .row .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
/* shroff form container */
.shroff-container {
    background-color: #ffffff09;
    height: 50vh;
    padding: 2rem 9%;
    font-size: 16px;
}

.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
    height: 500px;
}

.action-btn,
.cancel-btn,


.action-btn {
    background-color: #0da9ef;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color: #27ae60;
}
.order-section form .row .input-box {
    width: 49%;
    padding: 1.8rem 0;
} 
/* newly added from income heads */
.shroff-container {
    padding: 2rem 9%;
}
/* ongoing */
.shroff-container .shroff-form-container {
    background: #fff;
    /* width: 100% !important; */
    
}

.shroff-container .shroff-form-container form {
    /* position: relative;
    left: 40%;
    transform: translate(-50%, 0%); */
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
    
}

.shroff-container .shroff-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.shroff-container .shroff-form-container form .form-control {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.3rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
} 

.shroff-container .shroff-form-container form label {
     font-size: 1.2rem;
    margin: 0;
    padding: 0;
    float:left;
}

.shroff-container .shroff-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
}

.shroff-container .shroff-form-container form .btn {
    /* margin: 1rem 0; */
    width: 25%;
    text-align: center;
    font-size: small;
    background-color : #af74a7;
     height: 3rem;
   
    
}
.shroff-container .shroff-form-container form .table {
    /* margin: 1rem 0; */
    width: 100%;
    text-align: center;
    font-size: small;
    background-color : #e9e4e9;
     /* height: 3rem; */
    
}
.shroff-container .shroff-form-container form .Addbtn {
    /* margin: 1rem 0; */
    width: 5rem;
    /* height: 2rem; */
    /* text-align: center; */
    /* background-color : #af74a7; */
    /* vertical-align: bottom; */
    /* align-items: bottom; */
    padding-top: 1.9rem;
    padding-left: 3rem;
    
}

.shroff-container .shroff-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: rgb(243, 47, 47);
    margin: 0;
}

.shroff-container .shroff-form-container form p a {
    color: #27ae60;
}

.shroff-container .shroff-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.shroff-container .shroff-form-container form .form-group {
    margin: 0;
}

.shroff-container .shroff-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 2rem 1.2rem;
}
.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #0da9ef;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color: #27ae60;
}

</style>
