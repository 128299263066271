<template>
    <div class="gully-container" >
        <div class="gully-form-container" >
           
            <form id="gullyapplication" novalidate autocomplete="off">
                <div id="Printcontent">
                <div class="form-group">
                    
                   <u><h3 style="text-align: center;">චූෂණ බවුසරය ලබා ගැනීම සඳහා ඉල්ලුම් පත්‍රය(නගර සීමාව තුල)</h3></u>
               <br>
                    <label for="iHead">ඉල්ලුම්කරුගේ නම:&nbsp; {{ cus_name }}
                    </label> <br> 
                    <label for="iHead">ඉල්ලුම්කරුගේ ලිපිනය:&nbsp; {{ cus_add }}
                    </label> 
                   <br>
                    <label for="iCode">දුරකථන අංකය:&nbsp; {{ cus_tel }}
                    </label>
                    <br>
                    <p>ඉහත නම සඳහන් මාගේ නිවසේ/ ව්‍යාපාරික ස්ථාන‍යේ වැසිකිළී වල පිරී ඇති බැවින් චූෂණ බවුසරයෙන් එය 
                        හිස්කර දෙන ලෙස කාරුණිකව ඉල්ල‍ා සිටිමි.
                    </p>
                    <p>දිනය :&nbsp; {{ new Date().toLocaleString() }}   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ඉල්ලුම්කරුගේ අත්සන: ........................</p>
                    <br><br>
                    <h4 style="text-align: center;">කාර්යාලයේ ප්‍රයෝජනය සඳහා</h4>
                <table class="ttable " >
                <tbody>
                    <tr>
                        <td style="text-align:left;width: 50%;">
                        <h5>&nbsp;මි.ආ.ලි<br>
                        &nbsp;පරීක්ෂණ ගාස්තු       : ..............................<br>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;ජා.ගො.බ.             : ..............................<br>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;වැට් බද්ද  &nbsp;               : ..............................<br>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;එකතුව    &nbsp;            : ..............................<br>
                        &nbsp;කරු/ අය කර වාර්තා කරන්න<br>
                         &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;..............................................<br>
                         &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;කළමණාකරන සහකාර
                        </h5>
                        </td>
                        <td style="text-align: left;width: 50%;" >
                            <h5>&nbsp;සෞඛ්‍ය වෛද්‍ය නිළධාරි/ප්‍ර.ලි.<br><br>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;ලදුපත් අංකය       : ..............................<br>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;දිනය &nbsp;&nbsp;    : ..............................<br>
                                <br>&nbsp;අය කර ගන්නා ලදි.<br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;..............................................<br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;ලිි.ආ.ක.ස./සරප්
                            </h5>
                        </td>
                    </tr>
                        <td style="text-align:left;width: 50%;">
                            <h5>
                            &nbsp;ප්‍ර.ව.ක.ස.<br><br>
                                &nbsp;කරු/ වරිපනම් වාර්තාවක් ලබාදෙන්න<br><br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;..............................................<br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;කළමණාකරන සහකාර
                            <br><br>
                            </h5>
                        </td>
                        <td style="text-align:left;width: 50%;">
                            <h5>
                            &nbsp;සෞඛ්‍ය වෛද්‍ය නිළධාරි / ප්‍ර.ව.ලි.<br><br>
                            &nbsp;අංක ................................. දරණ ස්ථාන‍යේ පිහිටි උළු/<br> ඇස්බැස්ටෝස් / තහඩු / වෙනත් සෙවිලි නිවාස / ව්‍යාපාරික ස්ථාන‍යේ වාර්ෂික වරිපනම් තක්සේරු<br> වටිනාකම ....................................  බව
                                වරිපනම් <br>ලේඛනයේ සඳහන් වේ.<br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;..............................................<br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;කළමණාකරන සහකාර
                            </h5>
                        </td>
                    <tr>
                    <td style="text-align:left;width: 50%;">
                        <h5>
                            &nbsp;සෞඛ්‍ය පරිපාලක<br><br>
                                &nbsp;කරු/ වරිපනම් වාර්තාවක් ලබාදෙන්න<br><br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;..............................................<br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;කළමණාකරන සහකාර
                            </h5>
                        </td>
                            <td style="text-align:left;width: 50%;">
                            <h5>
                            &nbsp;සෞඛ්‍ය වෛද්‍ය නිළධාරි / ප්‍ර.ලි.<br><br>
                            &nbsp;ඉහත සඳහන් ස්ථාන‍ය නිවසකි /ව්‍යාපාරික ස්ථාන‍යකි /රජයේ කාර්යාලයකි.  මෙම කාර්යය ඉටුකළ හැකි <br> බව /නොහැකි බව 
                                වාර්තා කරමි.<br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;..............................................<br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;පරිපාලක මහතා
                            </h5>
                        </td> 
                    </tr>
                    <tr>
                        <td style="text-align:left;width: 50%;">
                        <h5>&nbsp;නා.ග. /මි.ආ.ලි<br><br>
                        &nbsp;වැසිකිළීවල හිස්කිරීමට   : ..............................<br>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;ජා.ගො.බ.             : ..............................<br>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;වැට් බද්ද  &nbsp;               : ..............................<br>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;එකතුව    &nbsp;            : ..............................<br>
                         &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;..............................................<br>
                         &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;කළමණාකරන සහකාර
                            </h5>
                        </td>
                        <td style="text-align:left;width: 50%;">
                            <h5>&nbsp;සෞඛ්‍ය වෛද්‍ය නිළධාරි/ප්‍ර.ලි.<br><br>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;ලදුපත් අංකය       : ..............................<br>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;දිනය &nbsp;&nbsp;    : ..............................<br>
                                &nbsp;අය කර ගන්නා ලදි.<br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;..............................................<br>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;ලිි.ආ.ක.ස./සරප්
                           <br> <br>
                            </h5>
                        </td>
                    </tr>
                </tbody>
            </table>
    <br>
    <table class="nobordertbl">
        <tr>
            <td style="text-align:left;width: 50%;">
                සභාවේ සේවයේ නියුතු/නිළධාරි/සේවක<br> බව තහවුරු කරමි.<br>
                <br>.....................................................
            </td>
            <td style="text-align:left;width: 50%;">
                ප්‍රජා සංවර්ධන සමිති සාමාජික බව සහතික කරමි.<br>  <br>
                ...........................................................
            </td>
        </tr>
    </table>
            </div>
    </div>
            </form>
        </div>
        <br>
        <div class="btnrow">
                <div class="">
                    <router-link to="/bkgully" class="btn">Cancel</router-link>&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="button" value="Print" class=" btn" @click="printContent">
                </div>
            </div>
    </div>
    </template>
    <script>
    import axios from 'axios';
    // import VueBasicAlert from 'vue-basic-alert';
    export default {
        name: "ApplicationGully",
    
       data (){
    
        return{
                nic:"",
                sabha: "",
                userLevel:"",
                // invoicesavedetails:[],
                invoicedetails:[],
                searchinvoice:'',
                sabhadetail:[],
                matchUser:'',
                bid:'',
                cus_name:"",
                cus_add:"",
                cus_tel:"",
        }
       },
    
       created(){
        this.getDataFromSessionStorage()
       
       },
       computed : {
               
                
            },
    
       methods:{
       
        async getDataFromSessionStorage(){
                const data = JSON.parse(sessionStorage.getItem('userData'))
                if(data){
                                this.nic = data.nic 
                                this.sabha = data.sabha
                                this.userLevel =data.userLevel
                                // this.userName = data.userName
                            }
    
                this.searchinvoice = this.$route.params.data
                 this.sabhadetail= (await axios.get('/pra_sabha/' +this.sabha)).data
                 //get invoice save table data
                //  this.invoicesavedetails= (await axios.get('/pra_sabha/' +this.sabha)).data
                 this.invoicedetails = (await axios.get('/invoicesaved/' +this.sabha+'/'+this.searchinvoice)).data
                this.getMatchUser(this.nic)
               
               const bookingData = JSON.parse(sessionStorage.getItem('bookingData'))
                if(bookingData){
                                this.cus_name = bookingData.req_name 
                                this.cus_add = bookingData.req_address
                                this.cus_tel =bookingData.tel
                                this.bid=bookingData.reqid
                                // this.userName = data.userName
                            }
             
            },
            async getMatchUser(nic) {
                // await axios.get('/employee/' + nic);
                let data = await axios.get('/employee/' + nic);
                this.matchUser = data.data;
            },

            async printContent() {
                
             let updateprocess={
                payment_status : "processing"
            }
            await axios.put("/gullyrequestupda/"+this.bid,updateprocess, )
    
            const prtHtml = document.getElementById('Printcontent').innerHTML;
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                WinPrint.document.write(`    
        ${prtHtml}
    `);
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
                this.$router.push('/bkgully');
            }
        },
    
    
            
    
    };
    </script>
    <style>
    .ttable {
         
         width: 100%;
         border-width:1px;
         border-color : #130f40;
         /* border-style: solid; */
         font-size: 0.8rem;
        text-align: right;
         /* background-color :white; */
         /* padding-left: 3rem; */
          /* height: 3rem; */
          border-collapse: collapse;
          
     }
     th  {
         text-align: center;
    }
    .ttable, th, td {
    border: 1px solid;
    /* padding-right: 0.7rem; */
    }
    .nobordertbl{
        border-collapse: collapse;
        border: none;
    }
    .nobordertbl td{
        border: none;
    }
    .gully-container {
        background-color: #fff;
        /* height: 100vh; */
    /* form width controls from padding 20% */
        padding: 2rem 20%; 
        font-size: 16px;
        align-content: center;
        /* min-height: 72.3vh; */
    }
    .gully-container .gully-form-container {
        background: #fff;
    
    }
    
    .gully-container .gully-form-container .heading{
       padding: 0%;
    
    }
    
    .gully-container .gully-form-container form {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%);
        max-width: 100rem;
        width: 100%;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
        border: 0.1rem solid rgba(0, 0, 0, 0.2);
        padding: 2rem;
        border-radius: 0.5rem;
        animation: fadeUp 0.4s linear;
        padding-left: 2rem;
    }
    .h4 {
        /* padding-bottom: 1rem; */
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #130f40;
        margin: 0;
    }
    .gully-container .igully-form-container form h3 {
        /* padding-bottom: 1rem; */
        font-size: 1.3rem;
        text-transform: uppercase;
        color: #130f40;
        margin: 0;
    }
    .gully-container .gully-form-container form .table {
        /* margin: 1rem 0; */
        width: 100%;
        /* border-style: solid; */
        font-size: 1.2rem;
        /* background-color : #e9e4e9; */
        padding-left: 2rem;
         /* height: 3rem; */
         border-color : #130f40;
         /* border-width:2px; */
         border-top: 0.5px solid;
         border-left: 0.5px solid;
         border-right: 0.5px solid;
         border-bottom: 0.5px solid;
    }
    .gully-container .gully-form-container form .table .td {
        /* margin: 1rem 0; */
        width: 40%;
        vertical-align: middle;
        border: 0.5px solid;
        border-bottom: 0.5px solid;
    }
    .gully-container .gully-form-container form .form-group {
        margin:0;
        font-size: 1.3rem;
        padding-top: 0%;
        padding-bottom: 0%;
    } 
    .gully-container .gully-form-container form .form-group1 {
        margin:0;
        font-size: 1.2rem;
    } 
    </style>