<template>
      <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="admin-container">
     <div class="heading">
        <h4>{{sabhadetail.sb_name_en}}</h4>
        <span>Assesment Tax Online Payments {{ this.objct.idate }} </span>
     </div>
        <div class="form-group">
        <div class="row">
            <div class="col-sm-4">
                    <input type="date" name="idate"  id="idate" class="form-control" 
                        v-model="objct.idate" >
                    </div>
                    <div class="col-sm-4">
                    <input type="button" @click="getallPayments()" value="Generate" class="btn" id ="gen" />
                </div>
                </div>
            </div>
   
     <table class="ttable1" >
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>Customer NIC</th>
                        <th>Invoice Number</th>
                        <th>Tax Number</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Date Time</th>
                        <th>Generate Invoice</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" (p,index) in paginatedData" :key="index">
                        <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                        <td>{{ p.tax_cus_nic  }}</td>
                        <td>{{p.tax_invoice_num  }}</td>
                        <td>{{ p.tax_no  }}</td>
                        <td>{{ p.tax_description }}</td>
                        <td>{{ p.tax_price }}</td>
                        <td>{{ p.tax_cur_date }}</td>
                        <td ><input type="button" class="gnbtn" @click="openPopup(p.tax_invoice_num)" value="Generate"></td>
                    </tr>
                </tbody>
            </table>
            <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
        </div>
        <!-- Popup -->
        <div class="modal" v-if="showPopup">
      <div class="modal-content" ref="modalRef">
        <button class="close-x" @click="closePopup"> X</button>
        <div class="modal-header">
          <h2>Add Assesment Tax Details</h2>
        </div>
        <div class="modal-body">
            <div class="row">
                <label for="rnumber" class="label-width1"><b>Receipt Number:</b>&nbsp; {{receiptdetail[0].tax_invoice_num}}
                </label> <br> 
                <label for="tnumber" class="label-width1"><b>Tax Number:</b>&nbsp; {{receiptdetail[0].tax_no}}
                </label>  <br>
            </div>
            <div class="row">
                <label for="ramount" class="label-width1"><b>Paid Amount:</b>&nbsp; {{ receiptdetail[0].tax_price }}
                </label> <br> 
               
            </div>
            <!-- ee -->
            <div class="row">
                   <label for="iHead" class="label-width"><b>Income Head:</b>
                   </label>
                 <select v-model="invObj.headid" id="iHead" class="modal-form-control-long" @Change ="checkShop()" >
                       <option value="" selected disabled  >
                                Choose
                       </option>
                       <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.emp_sb_rates"  
                           v-bind:value =[ratehead.emp_sb_rates]  >
                             {{ ratehead.emp_sb_rates  }} - {{ratehead.sb_rate_head_name}}
                       </option>
                   </select>

                   <p class="error-mess" v-if="errorObj.iheadErr.length > 0">{{ errorObj.iheadErr[0] }}</p> 
               </div>
               <!-- eend -->
            <div class="row">
                <label for="rname" class="label-width" ><b>Customer Name: </b> 
                </label>
                   <input type="text" name="rname" id="rname" class="modal-form-control-long" 
                    v-model="invObj.cusname" />
                    <p class="error-mess" v-if="errorObj.cusnameErr.length > 0">{{ errorObj.cusnameErr[0] }}</p>
            </div>
            <div class="row">
                <label for="raddress" class="label-width"><b>Customer Address: &nbsp;</b> 
                </label>
                   <input type="text" name="raddress" id="raddress" class="modal-form-control-long" 
                    v-model="invObj.caddr" />
                    <p class="error-mess" v-if="errorObj.cusaddErr.length > 0">{{ errorObj.cusaddErr[0] }}</p>
            </div>
            <div class="row">
                <label for="rarrears" class="label-width"><b>Arrears Amount: &nbsp;</b> 
                </label>
                   <input type="text" name="rarrears" id="rarrears" class="modal-form-control" 
                    v-model="invObj.arrears" />
                    <!-- <p class="error-mess" v-if="errorObj.cusarrErr.length > 0">{{ errorObj.cusarrErr[0] }}</p> -->
                    <label for="rstamp" class="label-width"><b>Stamp Fee:</b> 
                    </label>
                   <input type="text" name="rstamp" id="rstamp" class="modal-form-control" 
                    v-model="invObj.stampp" />
                    <!-- <p class="error-mess" v-if="errorObj.cusstampErr.length > 0">{{ errorObj.cusstampErr[0] }}</p> -->
            </div>
            <div class="row">
                <label for="rvat" class="label-width"><b>VAT: </b> 
                </label>
                   <input type="text" name="rvat" id="rvat" class="modal-form-control" 
                    v-model="invObj.vatt" />
                    <!-- <p class="error-mess" v-if="errorObj.cusvatErr.length > 0">{{ errorObj.cusvatErr[0] }}</p> -->
                
            </div>
            <div class="row">
                <label for="rquater" class="label-width"><b>Paying Quaters:</b> 
                </label> 
            </div>
                <div class="row1">
                    <!-- <input type="checkbox" id="checkbox1" v-model="invObj.checkbox1" />
                    <label for="checkbox1">&nbsp; First Quater &nbsp;&nbsp;&nbsp;</label> -->
                    <label for="fq" class="label-width"><b>First Quater Payment: </b> 
                    </label>
                   <input type="text" name="fq" id="fq" class="modal-form-control" 
                    v-model="invObj.fq" />
                </div>
                
                <div class="row1">
                    <!-- <input type="checkbox" id="checkbox2" v-model="invObj.checkbox2" />
                    <label for="checkbox2">&nbsp; Second Quater &nbsp;</label> -->
                    <label for="sq" class="label-width"><b>Second Quater Payment: </b> 
                    </label>
                   <input type="text" name="sq" id="sq" class="modal-form-control" 
                    v-model="invObj.sq" />
                </div>
                
                <div class="row1">
                    <!-- <input type="checkbox" id="checkbox3" v-model="invObj.checkbox3" />
                    <label for="checkbox3">&nbsp; Thired Quater &nbsp;</label> -->
                    <label for="tq" class="label-width"><b>Third Quater Payment: </b> 
                    </label>
                   <input type="text" name="tq" id="tq" class="modal-form-control" 
                    v-model="invObj.tq" />
                </div>
                
                <div class="row1">
                    <!-- <input type="checkbox" id="checkbox4" v-model="invObj.checkbox4" />
                    <label for="checkbox4">&nbsp; Fourth Quater &nbsp;</label> -->
                    <label for="foq" class="label-width"><b>Fourth Quater Payment: </b> 
                    </label>
                   <input type="text" name="foq" id="foq" class="modal-form-control" 
                    v-model="invObj.foq" />
                </div>
           
           
            <div class="row">
                <label for="rdisc" class="label-width"><b>Discount:</b> 
                </label>
                   <input type="text" name="rdisc" id="rdisc" class="modal-form-control" 
                    v-model="invObj.disc" />
                    <!-- <p class="error-mess" v-if="errorObj.sTaxErr.length > 0">{{ errorObj.sTaxErr[0] }}</p> -->
                <!-- <label for="rvat" class="label-width"><b>Remaining Amount: </b> 
                </label>
                   <input type="text" name="rvat" id="rvat" class="modal-form-control" 
                    v-model="invObj.vatt" /> -->
                    <!-- <p class="error-mess" v-if="errorObj.sTaxErr.length > 0">{{ errorObj.sTaxErr[0] }}</p> -->
            </div>
        </div>

        <div class="modal-footer">

          <button class="close-button" @click="closePopup">Close</button>
          <button class="save-button" @click="TempSaveModal">Generate</button>
        </div>

      </div>
    </div>
    <!-- invoice Popup -->
    <div class="modal" v-if="showinvoicePopup">
      <div class="modal-content" ref="modalRef2">
        <button class="close-x" @click="closeInvoicePopup"> X</button>
        <!-- <div class="modal-header">
          <h1>Create Invoice</h1>
        </div> -->
        <div class="modal-body">
            <!-- <div class="invoice-container" > -->
            <div class="invoice-form-container" >
                <form id="invoiceForm" novalidate autocomplete="off">
                    <div id="Printcontent">
                    <h5>ප්‍රා.ස.1</h5>
                    <div class="form-group">
                    <h3 style="text-align: center;">Invoice | ලදුපත</h3>
                        <h6 style="text-align: center;">{{sabhadetail.sb_name_en}},
                        {{sabhadetail.sb_address}},
                        Tel:{{sabhadetail.sb_contact}}&nbsp; Fax:{{sabhadetail.fax}},
                        VAT Number:{{ sabhadetail.vat_num }}</h6>
                <br>
                        <label for="iHead"><b>Receipt Number:</b> {{searchinvoice}}
                        </label> <br> 
                        <label for="iHead"><b>Customer name:</b>
                        </label> 
                        {{invoicedetails[0].cus_name}}<br>
                        <label for="iCode"><b>Customer Address: </b>
                        </label>
                        {{invoicedetails[0].cus_address}}
        <br>
                    <table style=" width: 100%;border-width:1px;border-color : #130f40;font-size: 0.8rem;text-align: right;border-collapse: collapse;" >
                    <thead>
                        <tr >   
                            <th style=" width: 25%;vertical-align: middle;text-align:center;border: 0.5px solid;border-bottom: 0.5px solid;" >Income Head</th>
                            <th style=" width: 25%;vertical-align: middle;text-align:center;border: 0.5px solid;border-bottom: 0.5px solid;">Description</th>
                            <th style=" width: 25%;vertical-align: middle;text-align:center;border: 0.5px solid;border-bottom: 0.5px solid;">VAT</th>
                            <th style=" width: 25%;vertical-align: middle;text-align:center;border: 0.5px solid;border-bottom: 0.5px solid;">Discount</th>
                            <th style=" width: 25%;vertical-align: middle;text-align:center;border: 0.5px solid;border-bottom: 0.5px solid;">Stamp</th>
                            <th style=" width: 25%;vertical-align: middle;text-align:center;border: 0.5px solid;border-bottom: 0.5px solid;">Amount</th>
                            <!-- <th class="td">Subject</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for=" r in invoicedetails" :key="r.taxinv_id">
                            <td style=" vertical-align: middle;text-align:left;border: 0.5px solid;border-bottom: 0.5px solid;">{{ r.vote}}</td>
                            <td style=" vertical-align: middle;text-align:left;border: 0.5px solid;border-bottom: 0.5px solid;width: 40%;" >Assesment tax for Tax Number {{ r.tax_num }}<br>
                            payment for First Quater  :{{ r.quater_one }}<br>
                            payment for Second Quater :{{ r.quater_two }}
                            payment for Third Quater  :{{ r.quater_three }}
                            payment for Fourth Quater :{{ r.quater_four }}
                            balance:{{ r.balance }}
                            </td>
                            <td style=" vertical-align: middle;text-align:right;border: 0.5px solid;border-bottom: 0.5px solid;">{{ r.vat }}</td>
                            <td style=" vertical-align: middle;text-align:right;border: 0.5px solid;border-bottom: 0.5px solid;">{{ r.discount }}</td>
                            <td style=" vertical-align: middle;text-align:right;border: 0.5px solid;border-bottom: 0.5px solid;">{{ r.stampfee }}</td>
                            <td style=" vertical-align: middle;text-align:right;border: 0.5px solid;border-bottom: 0.5px solid;">{{ r.paid_amount }}</td>
                            <!-- <td>{{ r.sub_nic }}</td> -->
                        </tr>
                        <tr>
                            <th style=" vertical-align: middle;text-align:left;border: 0.5px solid;border-bottom: 0.5px solid;">Total:</th>
                            <th colspan="5" style=" vertical-align: middle;text-align:right;border: 0.5px solid;border-bottom: 0.5px solid;">{{invoicedetails[0].paid_amount}}</th>
                        </tr>
                    </tbody>
                </table>
        <br>
                </div>

                    <div class="form-group1">
                        <label for="iCashier"> Prepaired by: {{ userName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </label>
                        <label for="iDate"> Date-Time: 
                            {{ invoicedetails[0].date_time }}
                        </label>    
                </div>
                <div class="form-group1">
                    <br>
                    <label for="isign"> Signature: .....................................&nbsp;&nbsp;
                        </label>
                </div>
</div>
        </form>
    </div>
    <br>
    <div class="rowpop">
            <!-- <div class=""> -->
                <router-link to="/onlineasses" @click="closeInvoicePopup" class="btn">Cancel</router-link>&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="button" value="Print" class=" btn" @click="printContent">
            <!-- </div> -->
        </div>
<!-- </div> -->
        </div>

        <div class="modal-footer">

          <!-- <button class="close-button" @click="closeInvoicePopup">Cancel</button>
          <button class="save-button" @click="saveInvoice">Save</button> -->
        </div>

      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  import { mapState, mapMutations } from "vuex";
  import VueBasicAlert from 'vue-basic-alert'
  export default {

name: "OnlinePayAssesment",

data() {
    return {
        nic:"",
        sabha: "",
        userLevel:"",
        userName:"",
        allPayments: [],
        sabhadetail:[],
        objct:{ idate:""},
        itemsPerPage: 10,
        currentPage: 1,
        showPopup: false,
        invObj:{arrears:'',cusname:'',caddr:'',stampp:0,vatt:0,disc:0,fq:0,sq:0,tq:0,foq:0,headid:'' },
        receiptdetail:[],
        errorObj:{cusnameErr:[],cusaddErr:[],iheadErr:[]},
        showinvoicePopup:false,
        invoicedetails:[],
        searchinvoice:'',
        tempinvdetails:[],
        getInvoice:'',
        sabhaIncomeHead: undefined,
    }
},
created(){
    this.getDataFromSessionStorage()
   
   },
computed: {
        ...mapState(["user"]),
    paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    
    // Check if this.data is an array before using slice method
    if (Array.isArray(this.allPayments)) {
      return this.allPayments.slice(startIndex, endIndex);
    } else {
      // Return empty array or handle loading state
      return [];
    }
  },
  totalPages() {
      return Math.ceil(this.allPayments.length / this.itemsPerPage);
    },
    },
   

// created() {
  
//     this.getallPayments()
// },

methods: {
    ...mapMutations(["setUser"]),
    async getDataFromSessionStorage(){
            const data = JSON.parse(sessionStorage.getItem('userData'))
            if(data){
                            this.nic = data.nic 
                            this.sabha = data.sabha
                            this.userLevel =data.userLevel
                            this.userName = data.userName
                        }

            // this.searchinvoice = this.$route.params.data
             this.sabhadetail= (await axios.get('/pra_sabha/'+this.sabha)).data
             
            //  this.invoicedetails = (await axios.get('/assesinv/'+this.searchinvoice)).data
            let Sabhadata = await axios.get('/subheads/'+this.nic +"/"+ this.sabha)
           this.sabhaIncomeHead = Sabhadata.data                       
         
        },
    async getallPayments() {
    
         this.allPayments= (await axios.get('/assestaxbysabha/'+this.user.emp_prs_code+"/"+this.objct.idate)).data;
    },
        nextPage(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        prevPage(event) {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        getinvoiceDetails(){
           
        },

        //get balance
        getBalance:function(){
            let paidamount = this.receiptdetail[0].tax_price ;
            let arrearsamount = this.invObj.arrears;
            let stampfee= this.invObj.stampp;
            let vat=this.invObj.vatt;
            let fstq =this.invObj.fq;
            let secq =this.invObj.sq;
            let thrdq =this.invObj.tq;
            let fouthq =this.invObj.foq;
            let discount =this.invObj.disc;
            let bal=0;

            bal = (parseFloat(paidamount)-parseFloat(arrearsamount)-parseFloat(stampfee)-parseFloat(vat)-parseFloat(fstq)
            -parseFloat(secq)-parseFloat(thrdq)-parseFloat(fouthq))+parseFloat(discount)
            return bal.toFixed(2)
        },
        //Popup
        async openPopup(id) {
            this.showPopup = true;
            document.addEventListener('mouseup', this.closeModalOnClickOutside);
            this.receiptdetail = (await axios.get('/assesbyinvid/'+id)).data
        },
        resetCheckPopErr: function () {
            this.errorObj.cusnameErr = [];
            this.errorObj.cusaddErr = [];
            this.errorObj.iheadErr  = [];
          
        },
        checkPopEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
        checkPopup: function () {
            this.resetCheckPopErr();

            if (!this.invObj.cusname) {
                this.errorObj.cusnameErr.push("Customer Name is required");
            }
            if (!this.invObj.caddr) {
                this.errorObj.cusaddErr.push("Customer Address is required");
            }
            if (!this.invObj.headid) {
                this.errorObj.iheadErr.push("Income head is required");
            }
        },
        async TempSaveModal(s) {
            this.checkPopup();

            if (!this.checkPopEmptyErr()) {
                s.preventDefault();
            }else{
                 //add invoice to assesmenttax_invoice_temp table when generate
                let popdata ={
                    invoice_num: this.receiptdetail[0].tax_invoice_num,
                    tax_num: this.receiptdetail[0].tax_cus_nic,
                    paid_amount: this.receiptdetail[0].tax_price,
                    vote:this.invObj.headid,
                    cus_name: this.invObj.cusname,
                    cus_address: this.invObj.caddr,
                    arrears:this.invObj.arrears,
                    stampfee:this.invObj.stampp,
                    vat:this.invObj.vatt,
                    quater_one:this.invObj.fq,
                    quater_two:this.invObj.sq,
                    quater_three:this.invObj.tq,
                    quater_four:this.invObj.foq,
                    balance:this.getBalance(),
                    discount:this.invObj.disc,
                }

                await axios.post("/astaxdet/", popdata)
                this.$refs.alert.showAlert('success', 'Details Added Successfully !')
               
                this.searchinvoice=this.receiptdetail[0].tax_invoice_num;
                this.invoicedetails = (await axios.get('/assesinvoiced/'+this.searchinvoice)).data
                this.closePopup();
            //view invoice below
            this.showinvoicePopup = true;

            }

        },
        closePopup() {
            this.showPopup = false;
            document.removeEventListener('mouseup', this.closeModalOnClickOutside);
        },
        closeInvoicePopup() {
            this.showinvoicePopup = false;
            document.removeEventListener('mouseup', this.closeModalOnClickOutside);
        },
        closeModalOnClickOutside(event) {
            const modal = this.$refs.modalRef;
            if (!modal.contains(event.target)) {
                this.closePopup();
            }
        },
        
    async printContent() {
       
        this.searchinvoice=this.receiptdetail[0].tax_invoice_num;
        this.invoicedetails = (await axios.get('/assesinvoiced/'+this.searchinvoice)).data
        let data ={
                    invoice_num: this.receiptdetail[0].tax_invoice_num,
                    tax_num: this.receiptdetail[0].tax_cus_nic,
                    paid_amount: this.receiptdetail[0].tax_price,
                    vote:this.invObj.headid,
                    cus_name: this.invObj.cusname,
                    cus_address: this.invObj.caddr,
                    arrears:this.invObj.arrears,
                    stampfee:this.invObj.stampp,
                    vat:this.invObj.vatt,
                    quater_one:this.invObj.fq,
                    quater_two:this.invObj.sq,
                    quater_three:this.invObj.tq,
                    quater_four:this.invObj.foq,
                    balance:this.getBalance(),
                    discount:this.invObj.disc,
                }
                await axios.post("/astaxdetsave/", data)
                this.$refs.alert.showAlert('success', 'Details Added Successfully !')
                // delete from tempory table
                await axios.delete("/deletetempinv/" +this.receiptdetail[0].tax_invoice_num);
        let printupdate={
                    is_printed:"Printed"
                }
                await axios.put("/isprintup/"+this.receiptdetail[0].tax_invoice_num , printupdate)
        const prtHtml = document.getElementById('Printcontent').innerHTML;
        // Get all stylesheets HTML
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
        }
        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        WinPrint.document.write(`



        ${stylesHtml}


        ${prtHtml}

        `);
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
        this.closeInvoicePopup();
        this.allPayments= (await axios.get('/assestaxbysabha/'+this.user.emp_prs_code+"/"+this.objct.idate)).data;
        }

},
components: {
        VueBasicAlert
    }
}
 
</script>
<style scoped>

.ttable1 {
     
     width: 100%;
     border-width:2px;
     border-color : #400f3c;
     /* border-style: solid; */
     font-size: 1.2rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
 }
 th  {
     text-align: center;
}

.ttable, th, td {
border: 1px solid;
}

 
 .table-responsive {
     margin-top: 8vh;
     height: 500px;
 }
.admin-container {
    background-color: #fff;
    /* height: 100vh; */
    padding: 2rem 9%;
    font-size: 16px;
    min-height: 73vh;
}
/* #4e0707; */
.admin-container span {
    color: #4e0707;
    font-size: larger;
}
.gnbtn{
    /* margin: 1rem 0; */
   width:60%; 
   text-align: center;
   background-color : #af74a7;
   /* height: 30px; */
  font-size: 1rem; 
   /* padding: 0.5rem 1rem; */
   border-radius: 20px; 
   color: white; 
}
.popup-button {
      padding: 10px 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  }

  .modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
  }

  .modal-content {
      background-color: white;
      padding: 5px;
      border-radius: 1px;
      /* max-width: 500px; */
      width: 60%;
      position: relative;
  }

  .close x {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      background-color: transparent;
      border: 1rem;
      cursor: pointer;
      font-size: 18px;
      color: #555;
  }

  .close-x:hover {
      color: #ff0000;
      /* Change to your desired hover color */
  }


  .modal-header {
      padding-bottom: 2px;
      border-bottom: 1px solid #ccc;
  }

  .modal-body {
      /* padding: 10px 20px 20px 20px; */
      padding-left: 5%; 
      font-size: 14px;

  }
  .label-width1 {
        width: 250px;
       display: inline-block;  /* This ensures the labels are on separate lines and don't overlap */
    }
   .label-width {
        width: 160px;
       display: inline-block;  /* This ensures the labels are on separate lines and don't overlap */
    }
    .modal-form-control{
        width: 150px;
        outline: 1px solid #d6d3d3; 
        margin-bottom: 10px; 
        margin-right: 10px;
    }
    .modal-form-control-long{
        width: 500px;
        outline: 1px solid #d6d3d3; 
        margin-bottom: 10px; 
        margin-right: 10px;
    }
  .model-group {
      padding: 10px ;
      /* padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 10%; */
      /* margin: 10px; */
      /* justify-content: left; */
      margin-right: 10px;
  }
  .modal-body.model-group .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 10%;
    padding: 0;
    width: 90%;

} 
.modal-body.row {
    display: flex;
    margin: 10%;
  }
  .modal-body.row1 {
    display: flex;
    margin: 10%;
    padding-left: 40%;
  }
  .modal-footer {
      padding-top: 5px;
      border-top: 1px solid #ccc;
      display: flex;
      justify-content: flex-end;
  }

  .close-button,
  .save-button {
      padding: 5px 10px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 4px;
      transition: background-color 0.3s ease;
  }

  .close-button {
      background-color: #f44336;
      color: white;
  }

  .save-button {
      background-color: #4CAF50;
      color: white;
      margin-left: 5px;
  }

  .close-button:hover {
      background-color: #d32f2f;
  }

  .save-button:hover {
      background-color: #45a049;
  }
  .rowpop{
    padding-left: 30%;
  }
  /* invoice container */
  .ttable {
     
     width: 100%;
     border-width:1px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 0.8rem;
    text-align: right;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      border-collapse: collapse;
      
 }
 th  {
     text-align: center;
}
.ttable, th, td {
border: 1px solid;
/* padding-right: 0.7rem; */
}
.invoice-container {
    background-color: #fff;
    /* height: 100vh; */
    padding: 2rem 25%;
    font-size: 16px;
    align-content: center;
    min-height: 72.3vh;
}
.invoice-container .invoice-form-container {
    background: #fff;

}

.invoice-container .invoice-form-container .heading{
   padding: 0%;

}

.invoice-container .invoice-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
    padding-left: 5rem;
}
.h4 {
    /* padding-bottom: 1rem; */
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}
.invoice-container .invoice-form-container form h3 {
    /* padding-bottom: 1rem; */
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}
.invoice-container .invoice-form-container form .table {
    /* margin: 1rem 0; */
    width: 95%;
    /* border-style: solid; */
    font-size: 1.2rem;
    /* background-color : #e9e4e9; */
    padding-left: 5rem;
     /* height: 3rem; */
     border-color : #130f40;
     /* border-width:2px; */
     border-top: 0.5px solid;
     border-left: 0.5px solid;
     border-right: 0.5px solid;
     border-bottom: 0.5px solid;
}
.invoice-container .invoice-form-container form .table .td {
    /* margin: 1rem 0; */
    width: 25%;
    vertical-align: middle;
    border: 0.5px solid;
    border-bottom: 0.5px solid;
}
.invoice-container .invoice-form-container form .form-group {
    margin:0;
    font-size: 1.3rem;
    padding-top: 0%;
    padding-bottom: 0%;
} 
.invoice-container .invoice-form-container form .form-group1 {
    margin:0;
    font-size: 1.2rem;
} 
</style>