<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="register-container">
        <div class="register-form-container">
            <form id="addIncomeHeadForm" @submit="handleSubmit" novalidate autocomplete="off">
                <div class="heading">
                    <span>Add Online Services</span>
                    <h4><b>මාර්ගගත සේවාවන්</b></h4>
                </div>
                <div class="form-group">
                    <label for="check">Service:</label>
                    <select v-model="servObject.servicename" id="check" class="form-control">
                        <option value="" selected disabled>Choose</option>
                        <option value="bacho,බැකෝ යන්ත්‍ර වෙන් කිරීම">Bachoe Booking - බැකෝ යන්ත්‍ර වෙන් කිරීම</option>
                        <option value="gully, ගලි බව්සර් වෙන් කිරීම">Gully Bowser Booking - ගලි බව්සර් වෙන් කිරීම</option>
                        <option value="stadiums, ක්‍රීඩාංගණ වෙන් කිරීම">Stadiums - ක්‍රීඩාංගණ වෙන් කිරීම</option>
                        <option value="townhall, නගර ශාලා වෙන් කිරීම">Town Halls/Auditorium - නගර ශාලාව/රැස්වීම් ශාලාව වෙන් කිරීම</option>
                        <option value="crematoria, ආදාහනාගාර වෙන් කිරීම">Crematoria -ආදාහනාගාර වෙන් කිරීම</option>
                        <option value="mv, යන්ත්‍රෝපකරණ හා වාහන වෙන් කිරීම">Mechinary & Vehicle -යන්ත්‍රෝපකරණ හා වාහන වෙන් කිරීම</option>
                        <!-- <option value="tractor, ට්‍රැක්ටර් රථ වෙන් කිරීම">Tractor -ට්‍රැක්ටර් රථ වෙන් කිරීම</option>
                        <option value="lorry, ලොරි රථ වෙන් කිරීම">Lorry -ලොරි රථ වෙන් කිරීම</option>
                        <option value="mower, වල් කපන යන්ත්‍ර වෙන් කිරීම">Mower -වල් කපන යන්ත්‍ර වෙන් කිරීම</option> -->


                    </select>

                    <p class="error-mess" v-if="errorObj.servError.length > 0">{{ errorObj.servError[0] }}</p>
                </div>
                <div class="form-group">
                   <label for="iHead">Income Head:
                   </label>
                 <select v-model="servObject.servVote" id="iHead" class="form-control" >
                       <option value="" selected disabled>
                                Choose
                       </option>
                       <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.sb_rate_head "  
                           v-bind:value =ratehead.sb_rate_head >
                             {{ ratehead.sb_rate_head  }} - {{ratehead.sb_rate_head_name}}  
                       </option>
                   </select>

                   <p class="error-mess" v-if="errorObj.voteError.length > 0">{{ errorObj.voteError[0] }}</p> 
               </div>
               <div class="form-group">
                    <label for="iCode">Income Head Name:
                    </label>
                    <input type="text" name="iCodes" id="iCode" class="form-control"
                        v-model="servObject.rname" />
                    <p class="error-mess" v-if="errorObj.nameerr.length > 0">{{ errorObj.nameerr[0] }}</p>
                </div>
                <div class="form-group">
                    <input type="submit" value="Save" class="btn" />
                  
                </div>
           <!-- table -->
           <br>
 <div class="heading">
           <b><h4>Sabha Services</h4></b>
         </div>
        <div class="row tablrow">
        
            <table class="ttable" >
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>Service</th>
                        <th>Service Name</th>
                        <th>Sabha Vote</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" (b,index) in paginatedData " :key="index">
                        <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                        <td style="text-align: left;">{{ b.service }}</td>
                        <td>{{ b.service_name }}</td>
                        <td>{{ b.vote }}</td>
                        <td><router-link to="" @click="deleteSrvice(b.id)"  class="fas fa-trash-alt" style="color: darkgreen;"> </router-link></td>
                     
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="padding-left: 4.0rem;">
            <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
        </div>
            </form>
        </div>
    </div>
   
</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
//import { ref } from 'vue'
export default {

    name: "OnlineService",

    data() {
        return {
            sabhaIncomeHead : undefined,
            servObject: { servicename:"", servVote:"" ,rname:""},
            errorObj: { servError:[], voteError:[],nameerr:[] },
            nic:"",
            sabha: "",
            userLevel:"",
            allservices:[],
            matchService:[],
            itemsPerPage: 10,
            currentPage: 1,
        }
    },

    created() {
        this.getDataFromSessionStorage(),
        this.getServices()
      
    },
    computed:{

        paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    
    // Check if this.data is an array before using slice method
    if (Array.isArray(this.allservices)) {
      return this.allservices.slice(startIndex, endIndex);
    } else {
      // Return empty array or handle loading state
      return [];
    }
  },
  totalPages() {
      return Math.ceil(this.allservices.length / this.itemsPerPage);
    },
    },
   
    methods: {
        async getDataFromSessionStorage(){
            const data = JSON.parse(sessionStorage.getItem('userData'))
            if(data){
                            this.nic = data.nic 
                            this.sabha = data.sabha
                            this.userLevel =data.userLevel
                        }
        this.sabhaIncomeHead = (await axios.get('/allnewvotes/'+this.sabha)).data;
        // this.sabhaheads = (await axios.get('/headsbysabha/'+this.sabha)).data;
        },
       
        async getServices() {
             this.allservices = (await axios.get('/loadservices/'+this.sabha)).data;
          
        },
      
        async deleteSrvice(sid){
            let result = confirm("Confirm Delete?")
        if(result == true){

            await axios.delete("/deletesabserv/"+sid );
            this.$refs.alert.showAlert('success', 'Service Deleted!')
            this.allservices = (await axios.get('/loadservices/'+this.sabha)).data;
        }
        },

        resetCheckErr: function () {
            this.errorObj.servError = [];
            this.errorObj.voteError = [];
            this.errorObj.nameerr = [];
        },
        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
        checkForm: function () {
            this.resetCheckErr();

            // Head Id/code validate
            if (!this.servObject.servicename) {
                this.errorObj.servError.push("Service is required");
            }

            // Rate Head validate
            if (!this.servObject.servVote){
                this.errorObj.voteError.push("Incomehead is required");
            }
            if (!this.servObject.rname){
                this.errorObj.nameerr.push("Incomehead name is required");
            }
        },
        
        async handleSubmit(e) {
            this.checkForm();


            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                e.preventDefault();
                let typeserv =  this.servObject.servicename.split(',')
                let serviceexsist = (await axios.get('/checkservice/'+this.sabha+"/"+this.servObject.servVote+"/"+typeserv[0]+"/"+this.servObject.rname));
                this.matchService = serviceexsist.data
                if (this.matchService.length>0) {
    
                    this.$refs.alert.showAlert('error', 'This Service already exist !')
                }
               else{
                let typeser =  this.servObject.servicename.split(',')
                    let data = {
                        sabha_code : this.sabha ,
                        vote : this.servObject.servVote,
                        service: typeser[1],
                        service_name:this.servObject.rname,
                        service_type:typeser[0],
                    }
                    await axios.post("/savesabhaserv/", data)
                    this.$refs.alert.showAlert('success', 'Service Added Successfully !')
                    document.getElementById("addIncomeHeadForm").reset();
                    this.allservices = (await axios.get('/loadservices/'+this.sabha)).data;
                }
                }
           
        }

    },
    components: {
        VueBasicAlert
    }
}
</script>

<style scoped>

/* new */
.tablrow{
padding-left: 10%;
}
.ttable {
     
     width: 90%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
 }
 th  {
     text-align: center;
}
.ttable, th, td {
border: 1px solid;
text-align: center;
}
.register-container {
    padding: 2rem 9%;
     min-height: 72.3vh;
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.6rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
} 

.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
     color: #032a2c;

}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 100%;
    text-align: center;
    background-color:#032a2c ;
    color: #e9e4e9;
}

.register-container .register-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group {
    margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
</style>