
<template>
   
    <div class="Shop-container">
        <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
        <router-link to="/shoparrrepo" class="btn" style="background-color:darkgreen">One Month</router-link>&nbsp;
            <router-link to="/shoparrtwo" class="btn" style="background-color:orange">Two months</router-link>&nbsp;
            <router-link to="/shoparrthree" class="btn" style="background-color:firebrick"> More than 3</router-link>&nbsp;
            <br> <br>
               
           <div class="Shop-form-container">
               <form id="addShopsForm"  novalidate autocomplete="off">
                <div id="Printcontent">
                <div class="heading">
                    <h4 style="text-align: center; padding-top: 0px; padding-bottom: 0px;">Shop Rent - Two Month Arrears Report<br>
            {{sabhadetail.sb_name_en}}</h4>
            <h5>Date:  {{ new Date().toLocaleDateString() }}</h5>
                </div>
               <div class="row">
                   <table style="width:98%; text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;font-size: small;" >
                   <thead>
                       <tr>   
                           <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Index</th>
                           <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Taxpayee name</th>
                           <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Taxpayee NIC</th>
                           <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Shop Number</th>
                           <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Shop Place</th>
                           <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Monthly Shop rent</th>
                           <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Arrears</th>
                           <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Last Payment </th>
                       </tr>
                   </thead>
                   <tbody>
                       <tr v-for=" (s,index) in paginatedData" :key="index">
    
                        <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ (currentPage - 1) * 10 + index + 1 }}</td>
                           <td style="text-align: left; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">&nbsp;{{ s.shop_taxpayee  }}</td>
                           <td style="text-align: left; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">&nbsp;{{ s.taxpayee_nic  }}</td>
                           <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ s.shop_number   }}</td>
                           <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{  s.place }}</td>
                           <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ s.monthly_shop_rent  }}</td>
                           <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{  s.arrears }}</td>
                           <td style="text-align: left; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">&nbsp;{{  getMonthName(s.pay_month) }} {{ s.pay_year }}</td>
                       </tr>
                   </tbody>
               </table>
            </div>
               <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
                 <span>{{ currentPage }} / {{ totalPages }}</span>
                <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
               </div>
               </form>
               <div class="form-group">
                    <input type="button" value="Print" class="btn" @click="printContent"/>
                    
                </div>
           </div>
    </div>
    </template>
    <script>
    import axios from "axios";
    import { mapMutations } from "vuex";
    import { mapState } from "vuex";
    import VueBasicAlert from 'vue-basic-alert'
    export default {
       
        name: "ShopArrearsTwo",
    
        data() {
            return {
                
                 allShopDetails:[],
                 itemsPerPage: 10,
                currentPage: 1,
                sabhadetail:[]
            }
        },
            created() {
                this.getDataFromSessionStorage()
    
            },
         
            computed : {
                ...mapState(["user"]),
        paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.allShopDetails)) {
          return this.allShopDetails.slice(startIndex, endIndex);
        } else {
          // Return empty array or handle loading state
          return [];
        }
      },
      totalPages() {
          return Math.ceil(this.allShopDetails.length / this.itemsPerPage);
        },
    
        },
        methods: {
            ...mapMutations(["setUser"]),
            async getDataFromSessionStorage(){
               const data = JSON.parse(sessionStorage.getItem('userData'))
               if(data){
                               this.nic = data.nic 
                               this.sabha = data.sabha
                               this.userLevel =data.userLevel
        
                           }
               let sabhaCode = this.sabha
               this.allShopDetails = (await axios.get('/arrearstwo/'+sabhaCode)).data
               this.sabhadetail= (await axios.get('/pra_sabha/'+this.sabha)).data
           },
           nextPage(event) {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
            // Prevent default form submission behavior
            event.preventDefault();
            },
            prevPage(event) {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
            // Prevent default form submission behavior
            event.preventDefault();
            },
            printContent() {
            const prtHtml = document.getElementById('Printcontent').innerHTML;
               const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                WinPrint.document.write(`    
        ${prtHtml}
    `);
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
        },
    getMonthName(monthNumber) {
        const months = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];
        return months[monthNumber]; // monthNumber is 0-based (0 = January)
    }
            
        },
        components: {
            VueBasicAlert
        }
    }
    
    </script>
    <style scoped>
    .btn {
        background-color: #e68a00;   
        width: 15rem;
        height: 2.7rem;
        font-size: small;
        padding-top: 0.3rem;
        /* padding-bottom: 0.5rem; */
    }
    .ttable {
         
         width: 100%;
         border-width:1px;
         border-color : #130f40;
         /* border-style: solid; */
         font-size: 0.9rem;
         /* background-color :white; */
         /* padding-left: 3rem; */
          /* height: 3rem; */
          border-collapse: collapse;
     }
     th  {
         text-align: center;
    }
    .ttable, th, td {
    border: 1px solid;
    text-align: center;
    }
    .register-container {
        padding: 1.5rem 9%;
         /* min-height: 72.3vh; */
    }
    
    .register-container .register-form-container {
        background: #fff;
    
    }
    
    .register-container .register-form-container form {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%);
        /* max-width: 98rem; */
        width: 100%;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
        border: 0.1rem solid rgba(0, 0, 0, 0.2);
        padding: 2rem;
        border-radius: 0.5rem;
        animation: fadeUp 0.4s linear;
    }
    
    .register-container .register-form-container form h3 {
        padding-bottom: 0.5rem;
        font-size: 1.9rem;
        text-transform: uppercase;
        color: #130f40;
        /* margin: 0; */
        text-align: center;
        
    }
    
    .register-container .register-form-container form .form-control {
        margin: 0.7rem 0;
        border-radius: 0.5rem;
        background: #f7f7f7;
        /* padding: 2rem 1.2rem; */
        font-size: 1.5rem;
        color: #130f40;
        text-transform: none;
        width: 100%;
        border: margin-bottom; 
    }
    
    .register-container .register-form-container form .form-control1 {
        margin: 0.7rem 0;
        border-radius: 0.5rem;
        background: #f7f7f7;
        /* padding: 2rem 1.2rem; */
        font-size: 1.5rem;
        color: #130f40;
        text-transform: none;
        width: 40%;
        border: none;
        padding-bottom: 0.5rem;
    }
    .register-container .register-form-container form .form-radio {
        margin: 2rem 0%;
        border-radius: 0.5rem;
        background: #f7f7f7;
       padding-bottom: 1rem;
        font-size: 10rem;
        color: #130f40;
    
        width: 10%;
        border: none;
        margin-left: 10%;
    }
    .register-container .register-form-container form label {
        font-size: 1.5rem;
        margin: 0;
        padding: 0;
    }
    
    .register-container .register-form-container form span {
        font-size: 25px;
        /* padding-left: 5px;
        padding-right: 40px; */
        text-align: center;
        color: #022e2a;
    
    }
    
    .register-container .register-form-container form .btn {
        margin: 1rem 0;
        width: 30%;
        text-align: center;
        align-items: center;
        /* background: #022e2a; */
        background-color : #af74a7;
        
    }
    
    .register-container .register-form-container form p {
        padding-top: 0.75rem;
        font-size: 1rem;
        color: #666;
        margin: 0;
    }
    
    .register-container .register-form-container form p a {
        color: #27ae60;
    }
    
    .register-container .register-form-container form p a:hover {
        color: #130f40;
        text-decoration: underline;
    }
    
    .register-container .register-form-container form .form-group  {
        margin: 0;
    }
    
    .register-container .register-form-container form .form-group  .error-mess {
        font-size: 1rem;
        position: relative;
        color: rgb(243, 47, 47);
        margin: 0;
        padding: 0;
        width: 100%;
    
    } 
    .register-container .register-form-container form .hide {
        display: none;
    
    } 
    /* for second container */
    .Shop-container {
       background-color: #ffffff09;
       min-height: 80vh;
       padding: 2rem 9%;
       font-size: 16px;
    }
    
    .Shop-list>tbody>tr>td {
       padding: 12px 8px;
    }
    
    .project-list>tbody>tr>td .avatar {
       width: 22px;
       border: 1px solid #CCC;
    }
    /* newly added from income heads */
    .Shop-container {
       padding: 2rem 9%;
    }
    /* ongoing */
    .Shop-container .Shop-form-container {
       background: #fff;
       /* width: 100% !important; */
       
    }
    
    .Shop-container .Shop-form-container form {
       /* position: relative;
       left: 40%;
       transform: translate(-50%, 0%); */
       /* max-width: 70rem; */
       width: 100%;
       box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
       border: 0.1rem solid rgba(0, 0, 0, 0.2);
       padding: 2rem;
       border-radius: 0.5rem;
       animation: fadeUp 0.4s linear;
       
    }
    
    .Shop-container .Shop-form-container form h3 {
       padding-bottom: 1rem;
       font-size: 2rem;
       text-transform: uppercase;
       color: #130f40;
       margin: 0;
    }
    
    .Shop-container .Shop-form-container form .form-control {
        margin: 0.7rem 0;
       border-radius: 0.5rem;
       background: #f7f7f7;
       /* padding: 2rem 1.2rem; */
        font-size: 1.3rem; 
       color: #130f40;
       text-transform: none;
       width: 100%;
       border: margin-bottom; 
    } 
    
    .Shop-container .Shop-form-container form label {
        font-size: 1.2rem;
       margin: 0;
       padding: 0;
       float:left;
    }
    
    .Shop-container .Shop-form-container form span {
       font-size: 18px;
       padding-left: 5px;
       padding-right: 40px;
    }
    
    .Shop-container .Shop-form-container form .btn {
       margin: 1rem 0;
       width: 100%;
       text-align: center;
       font-size: small;
       background-color : #af74a7;
        height: 3rem;
      
       
    }
    /* .Shop-container .Shop-form-container form .table {
       margin: 1rem 0;
       width: 100%;
       text-align: center;
       font-size: small;
       background-color : #e9e4e9;
        height: 3rem;
       
    } */
    .Shop-container .Shop-form-container form .Addbtn {
       /* margin: 1rem 0; */
       width: 5rem;
       /* height: 2rem; */
       /* text-align: center; */
       /* background-color : #af74a7; */
       /* vertical-align: bottom; */
       /* align-items: bottom; */
       padding-top: 1.9rem;
       padding-left: 3rem;
       
    }
    
    .Shop-container .Shop-form-container form p {
       padding-top: 1rem;
       font-size: 1.5rem;
       color: rgb(243, 47, 47);
       margin: 0;
    }
    
    .Shop-container .Shop-form-container form p a {
       color: #27ae60;
    }
    
    .Shop-container .Shop-form-container form p a:hover {
       color: #130f40;
       text-decoration: underline;
    }
    
    .Shop-container .Shop-form-container form .form-group {
       margin: 0;
    }
    
    .Shop-container .Shop-form-container form .form-group .error-mess {
       font-size: 1.5rem;
       position: relative;
       color: rgb(243, 47, 47);
       margin: 0;
       padding: 2rem 1.2rem;
    }
    /* .project-list>tbody>tr>td {
       padding: 12px 8px;
    }
    
    .project-list>tbody>tr>td .avatar {
       width: 22px;
       border: 1px solid #CCC;
    } */
    </style>
    