
<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <section>
    
    <div class="register-container">
        <div class="register-form-container">
            <form id="ShopDetailForm" novalidate autocomplete="off" >
                <div class="heading">
            <h3><b>Add New Shops</b></h3>
            </div>
            <div class="row">
            <div class="form-group col-sm-4">
                   <label for="sTax"><b>Tax Payee:</b> 
                   </label>
                   <input type="text" name="sTax" placeholder="Tax Payee Name" id="sTax" class="form-control" 
                    v-model="rentObj.sTax" />
                    <p class="error-mess" v-if="errorObj.sTaxErr.length > 0">{{ errorObj.sTaxErr[0] }}</p>
               </div>
               <div class="form-group col-sm-4">
                   <label for="sTaxNic"><b>Tax Payee NIC:</b>
                   </label>
                   <input type="text" name="sTaxNic" placeholder="Tax Payee NIC" id="sTaxNic" class="form-control" 
                   v-model="rentObj.sTaxNic" />
                    <p class="error-mess" v-if="errorObj.sTaxNic.length > 0">{{ errorObj.sTaxNic[0] }}</p>
               </div>
               <div class="form-group col-sm-4">
                   <label for="sCon"><b>Contact Number:</b>
                   </label>
                   <input type="text" name="sCon" placeholder="Contact Number" id="sCon" class="form-control" 
                   v-model="rentObj.sContact" />
                    <p class="error-mess" v-if="errorObj.sContact.length > 0">{{ errorObj.sContact[0] }}</p>
               </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-4">
                   <label for="sAddr"><b>Tax Payee Address:</b>
                   </label>
                   <input type="text" name="sAddr" placeholder="Tax Payee Address" id="sAddr" class="form-control" 
                   v-model="rentObj.sAddr" />
                    <p class="error-mess" v-if="errorObj.sAddr.length > 0">{{ errorObj.sAddr[0] }}</p>
               </div>
                <div class="form-group col-sm-4">
                    <label id="umarket"><b>Select Market/Street/Place:</b>
                    </label>
                    
                            <select class="form-control" @change="changemarket"  name="pradeshiya" id="pradeshiya" v-model="rentObj.market" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ms in marketstreets" :key="ms.ms_id" :value="ms.ms_id">{{ ms.place}} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.marketErr.length > 0">{{ errorObj.marketErr[0] }}</p>
                               
                </div>
                <div class="form-group col-sm-4">
                    <label id="rentShop"><b>Select Shop Number:</b>
                    </label>
                            <select class="form-control"  name="pradeshiya" id="pradeshiya" v-model="rentObj.shop" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="sno in shopnumbers" :key="sno.shop_no" :value="sno.shop_no">{{ sno.shop_no}} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.shopErr.length > 0">{{ errorObj.shopErr[0] }}</p>
                               
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-4">
                   <label for="sRent"><b>Monthly Shop Rent:</b>
                   </label>
                   <input type="text" name="sRent" placeholder="Monthly Rent" id="sRent" class="form-control" 
                   v-model="rentObj.sRent" />
                    <p class="error-mess" v-if="errorObj.sRentErr.length > 0">{{ errorObj.sRentErr[0] }}</p>
               </div>
               <div class="form-group col-sm-4">
                   <label for="sOther"> <b>Payment Vote:</b>
                   </label>
                   <select class="form-control"  name="payvote" id="payvote" v-model="rentObj.shopvotes" >
                                <option value="" selected disabled>Choose</option>
                                <!-- <option v-for="ratehead in empshopvotes" :key="ratehead.emp_sb_rates"  
                           v-bind:value =[ratehead.emp_sb_rates,ratehead.subjecttype]  >
                             {{ ratehead.emp_sb_rates  }} - {{ratehead.sb_rate_head_name}}
                       </option> -->
                       <option v-for="ratehead in filteredEmpShopVotes" :key="ratehead.emp_sb_rates"
            v-bind:value="ratehead.emp_sb_rates">
      {{ ratehead.emp_sb_rates }} - {{ ratehead.sb_rate_head_name }}
    </option>
                    </select>
                    <p class="error-mess" v-if="errorObj.shopVoteErr.length > 0">{{ errorObj.shopVoteErr[0] }}</p>
              </div>
                  <!--  <div class="form-group">
                    <label id="PayHead"><b>Select Shop Type:</b>
                    </label>
               <br> -->
                    <!-- <input type="radio" v-model="rentObj.renttype" value="29" class="form-radio" @click="showshopRentdiv"><label id="PayHead">කඩ කාමර කුලී</label> -->
                    <!-- <input type="radio" v-model="rentObj.renttype" value="51" class="form-radio" @click="showMeatRentdiv"><label id="PayHead">මස් කඩ කාමර කුලී</label> -->
                    <!-- <p class="error-mess" v-if="errorObj.renttyperErr.length > 0">{{ errorObj.renttyperErr[0] }}</p>          -->
                
                <!-- <div class="form-group hide" id="shopRentdiv" >
                    <label for="rentShop"><b>Shop Rent (Rs.) | මාසික කුලිය  (රු.)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                    </label>
                    <input type="number" name="rentShop" placeholder="your Shop Rent (Rs.)" id="rentShop" class="form-control1"
                        v-model="rentObj.shoprent"  />
                    <p class="error-mess" v-if="errorObj.rentErr.length > 0">{{ errorObj.rentErr[0] }}</p>
                    
                </div> -->
                <!-- <div class="form-group hide" id="MeatRentdiv">  
                            
                    <label for="rentShop"><b>Shop Rent (Rs.) | මාසික කුලිය  (රු.)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                    </label>
                    <input type="number" name="rentShop" placeholder="your Shop Rent (Rs.)" id="rentShop" class="form-control1"
                        v-model="rentObj.shoprent"  />
                 
                  </div> -->
                <div class="form-group col-sm-4">
                    <label for="sArr"><b>Arrears (Rs):</b>
                    </label>
                    <input type="number" name="sArr" placeholder="Arrears" id="sArr" class="form-control"
                        v-model="rentObj.sArr" />
                   
                    <p class="error-mess" v-if="errorObj.arrearsErr.length > 0">{{ errorObj.arrearsErr[0] }}</p>
                    
                </div>
            </div>
            <div class="row">
            <!-- <div class="form-group col-sm-12"> -->
                <div class="form-group col-sm-4">
                   <label for="sOther"> <b>Other:</b>
                   </label>
                   <textarea name="sOther" placeholder="Any other details" id="sOther" class="form-control" 
                   v-model="rentObj.sOther" ></textarea>
                    <!-- <p class="error-mess" v-if="errorObj.sOtherErr.length > 0">{{ errorObj.sOtherErr[0] }}</p> -->
              </div>
              <div class="form-group col-sm-4">
                   <label for="finevote"> <b>Fine Vote:</b>
                   </label>
                   <select class="form-control"  name="finevote" id="finevote" v-model="rentObj.finevote" >
                                <option value="" selected disabled>Choose</option>
                                <!-- <option v-for="ratehead in empshopvotes" :key="ratehead.emp_sb_rates"  
                           v-bind:value =[ratehead.emp_sb_rates,ratehead.subjecttype]  >
                             {{ ratehead.emp_sb_rates  }} - {{ratehead.sb_rate_head_name}}
                       </option> -->
                       <option v-for="ratehead in filteredEmpShopVotes" :key="ratehead.emp_sb_rates"
            v-bind:value="ratehead.emp_sb_rates">
      {{ ratehead.emp_sb_rates }} - {{ ratehead.sb_rate_head_name }}
    </option>
                    </select>
                    <p class="error-mess" v-if="errorObj.finevoteeErr.length > 0">{{ errorObj.finevoteeErr[0] }}</p>
              </div>
              <div class="form-group col-sm-4">
                   <label for="mfine"><b>Fine Amount:</b>
                   </label>
                   <input type="text" name="mfine" placeholder="Fine amount" id="mfine" class="form-control" 
                   v-model="rentObj.mfine" />
                    <p class="error-mess" v-if="errorObj.fineErr.length > 0">{{ errorObj.fineErr[0] }}</p>
               </div>
            </div>
                <div class="form-group">
                    <input type="button" @click="handleSubmit" value="Save" class="btn" />
                    
                </div>
            </form>
        </div>
    </div>
</section>
<div class="Shop-container">
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
            
            <div class="heading">
            <h3><b>Added Shops</b></h3>
            </div>
            <p style="font-size: small;">* Click on arrears amount to update arrears, click on edit button for other detail updates.|හිඟ මුදල් යාවත්කාලීන කිරීම සඳහා එම කඩයට අදාළ හිඟ මුදල මත ක්ලික් කරන්න. අනෙකුත් කඩ විස්තර යාවත්කාලීන කිරීම සඳහා Edit button එක ක්ලික් කරන්න </p>
           <div class="row">
               <table id="dtBasicExample" class="ttable table-striped table-bordered table-sm" >
               <thead>
                   <tr>   
                       <th>Index</th>
                       <th>Taxpayee name</th>
                       <th>Taxpayee NIC</th>
                       <th>Contact No</th>
                       <th>Taxpayee Address</th>
                       <th>Shop Number</th>
                       <th>Shop Place</th>
                       <th>Monthly Shop rent</th>
                       <!-- <th>other</th> -->
                       <th>Arrears</th>
                       <th>Fine</th>
                       <th>Edit</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">

                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                       <td style="text-align: left;">{{ s.shop_taxpayee  }}</td>
                       <td>{{ s.taxpayee_nic  }}</td>
                       <td>{{ s.  taxpayee_contact  }}</td>
                       <td style="text-align: left;">{{ s.taxpayee_address  }}</td>
                       <td>{{ s.shop_number   }}</td>
                       <td>{{  s.place }}</td>
                       <td>{{ s.monthly_shop_rent  }}</td>
                       <!-- <td>{{  s.Other }}</td> -->
                       <td><router-link to="" @click="openArrearsPopup(s.shop_details_id)">{{  s.arrears }}</router-link></td>
                       <td>{{  s.fine }}</td>
                       <td><router-link to="" @click="openPopup(s.shop_details_id)"  class="fas fa-edit" style="color: darkgreen;"> </router-link></td>
                       <!-- <td><input type="button" @click="deleteShopdetail(s.shop_details_id)" value="X" class=" btn"></td> -->
                     
                   </tr>
               </tbody>
           </table>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
           </div>
           </form>
       </div>
</div>
<!-- popup edit shop -->
<div class="modal" v-if="showPopup">
      <div class="modal-content" ref="modalRef">
        <button class="close-x" @click="closePopup"> X</button>
        <div class="modal-header">
          <h1>Edit Shop Details</h1>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="model-group">
                   <label for="sTaxNic"><b>Tax Payee NIC:</b>
                   </label>
                   <input type="text" name="sTaxNic" placeholder="Tax Payee NIC" id="sTaxNic" class="form-control" 
                   v-model="popObj.sTaxNic" readonly/>
                    <p class="error-mess" v-if="poerObj.sTaxNicErr.length > 0">{{ poerObj.sTaxNicErr[0] }}</p>
               </div>
            <div class="model-group">
                   <label for="sTax"><b>Tax Payee:</b> 
                   </label>
                   <input type="text" name="sTax" placeholder="Tax Payee Name" id="sTax" class="form-control" 
                    v-model="popObj.sTax" />
                    <p class="error-mess" v-if="poerObj.sTaxErr.length > 0">{{ poerObj.sTaxErr[0] }}</p>
               </div>
              
               <div class="model-group">
                   <label for="sCon"><b>Contact Number:</b>
                   </label>
                   <input type="text" name="sCon" placeholder="Contact Number" id="sCon" class="form-control" 
                   v-model="popObj.sContact" />
                    <p class="error-mess" v-if="poerObj.sContactErr.length> 0">{{ poerObj.sContactErr[0] }}</p>
               </div>
               <div class="model-group">
                   <label for="sAddr"><b>Tax Payee Address:</b>
                   </label>
                   <input type="text" name="sAddr" placeholder="Tax Payee Address" id="sAddr" class="form-control" 
                   v-model="popObj.sAddr" />
                    <p class="error-mess" v-if="poerObj.sAddrErr.length > 0">{{ poerObj.sAddrErr[0] }}</p>
               </div>
            </div>
            <div class="row">
                
                
                <div class="model-group">
                   <label for="sRent"><b>Monthly Shop Rent:</b>
                   </label>
                   <input type="text" name="sRent" placeholder="Monthly Rent" id="sRent" class="form-control" 
                   v-model="popObj.sRent" />
                    <p class="error-mess" v-if="poerObj.sRentErr.length > 0">{{ poerObj.sRentErr[0] }}</p>
               </div>
               <!-- <div class="model-group">
                   <label for="sOther"> <b>Payment Vote:</b>
                   </label>
                   <select class="form-control"  name="payvote" id="payvote" v-model="popObj.shopvotes" >
                                <option value="" selected disabled>Choose</option>
                    
                                <option v-for="ratehead in filteredEmpShopVotes" :key="ratehead.emp_sb_rates"
                                    v-bind:value="ratehead.emp_sb_rates">
                                     {{ ratehead.emp_sb_rates }} - {{ ratehead.sb_rate_head_name }}
                                </option>
                    </select>
                    <p class="error-mess" v-if="poerObj.sOtherErr.length > 0">{{ poerObj.sOtherErr[0] }}</p>
              </div>         -->
              <!-- <div class="model-group">
                    <label for="sArr"><b>Arrears (Rs):</b>
                    </label>
                    <input type="text" name="sArr" placeholder="Arrears" id="sArr" class="form-control"
                        v-model="popObj.sArr" />
                   
                    <p class="error-mess" v-if="poerObj.arrearsErr.length > 0">{{ poerObj.arrearsErr[0] }}</p>
                    
                </div> -->
                <div class="model-group">
                    <label for="efine"><b>Fine (Rs):</b>
                    </label>
                    <input type="text" name="efine" placeholder="fine" id="efine" class="form-control"
                        v-model="popObj.efine" />
                   
                    <p class="error-mess" v-if="poerObj.efineErr.length > 0">{{ poerObj.efineErr[0] }}</p>
                    
                </div>
                <div class="model-group">
                   <label for="sOther"> <b>Other:</b>
                   </label>
                   <textarea name="sOther" placeholder="Any other details" id="sOther" class="form-control" 
                   v-model="popObj.sOther" ></textarea>
              </div>
            </div>
            <div class="row">

                <!-- <div class="model-group col-sm-4">
                   <label for="sOther"> <b>Other:</b>
                   </label>
                   <textarea name="sOther" placeholder="Any other details" id="sOther" class="form-control" 
                   v-model="popObj.sOther" ></textarea>
              </div> -->
            </div>
        </div>

        <div class="modal-footer">

          <button class="close-button" @click="closePopup">Close</button>
          <button class="save-button" @click="saveModal">Update</button>
        </div>

      </div>
    </div>
    <!-- popup1 end -->
     <!-- arrears Popup start-->
     <div class="modal" v-if="showArrearsPopup">
      <div class="modal-content2" ref="arrearsRef">
        <button class="close-x2" @click="closeArrearsPopup"> X</button>
        <div class="modal-header">
          <h1>Update Arrears</h1>
        </div>
        <div class="modal-body2">
            <div class="row">
              <div class="model-group2">
                    <label for="sArr"><b>Arrears (Rs):</b>
                    </label>
                    <input type="text" name="sArr" placeholder="Arrears" id="sArr" class="form-control"
                        v-model="popObj.sArr" />
                   
                    <p class="error-mess" v-if="poerObj.arrearsErr.length > 0">{{ poerObj.arrearsErr[0] }}</p>
                    
                </div>
                <div class="model-group2">
                    <label for="sArr"><b>Last Paid Month:</b>
                    </label>
                    <input type="month" name="imon"  id="imon" class="form-control"
                        v-model="popObj.emonth" />
                   
                    <p class="error-mess" v-if="poerObj.emonthErr.length > 0">{{ poerObj.emonthErr[0] }}</p>
                    
                </div>
            </div>
           
            </div>
            <div class="modal-footer2">
          <button class="close-button" @click="closeArrearsPopup">Close</button>
          <button class="save-button" @click="arreasSaveModal">Update</button>
        </div>
        </div>
        

      </div>
      <!-- arrears pupup end -->
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import VueBasicAlert from 'vue-basic-alert'
// import { ref } from 'vue';
export default {
   
    name: "NewShopDetails",
    el: '#vue-instance',
  
    data() {
        return {
            
            rentObj: { sTax:"",sTaxNic:"",sContact:"0",sAddr:"",market:"",shop:"",sRent:"",shopvotes:"",sArr:"0",sOther:"",finevote:"",mfine:"0"},
            errorObj: { sTaxErr: [],sTaxNic: [], sContact:[], sAddr: [],marketErr: [], shopErr: [], sRentErr: [], sOtherErr: [],arrearsErr:[],finevoteeErr:[],fineErr:[],shopVoteErr:[]},
            marketstreets:[],
            shopnumbers:[],
            empshopvotes:[],
            theUser:[],
            // prs_code:null,
            distr: {
                address: {
                    ProviceVal: null,
                    
                   
            }
             },
             Prs: {
                praddress: {
                    DistVal: null,
                   
            }
             },
             allShopDetails:[],
             showPopup: false,
             popObj: { sTax:"",sTaxNic:"",sContact:"",sAddr:"",sRent:"",sOther:"",sArr:"0",efine:"0",emonth:""},
             poerObj: { sTaxErr: [],sTaxNicErr: [], sContactErr:[], sAddrErr: [], sRentErr: [],arrearsErr:[],efineErr:[],emonthErr:[]},
             selectedshop:'',
             itemsPerPage: 10,
            currentPage: 1,
            showArrearsPopup:false,
            chosenshop:'',
            selectyr:"",
            selectmon:"",
        }
    },

        created() {
            this.getDataFromSessionStorage()
            this.changesabha()
        },
  
        computed : {
            ...mapState(["user"]),

            filteredEmpShopVotes() {
      // Filter the empshopvotes array based on your condition
      return this.empshopvotes.filter(ratehead => ratehead.subjecttype === 'shoprent');
    },
    paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    
    // Check if this.data is an array before using slice method
    if (Array.isArray(this.allShopDetails)) {
      return this.allShopDetails.slice(startIndex, endIndex);
    } else {
      // Return empty array or handle loading state
      return [];
    }
  },
  totalPages() {
      return Math.ceil(this.allShopDetails.length / this.itemsPerPage);
    },
},     
    methods: {
        ...mapMutations(["setUser"]),
        async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
       

                       }
           let sabhaCode = this.sabha
           
           this.allShopDetails = (await axios.get('/shopddetails/' + sabhaCode)).data
           let empvotes = await axios.get('/subheads/'+ this.nic +"/"+sabhaCode)
           this.empshopvotes = empvotes.data
       },
       nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  // Prevent default form submission behavior
  event.preventDefault();
},
prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  // Prevent default form submission behavior
  event.preventDefault();
},
    
       async changesabha() {    
            let marketData = (await axios.get('/marketstreet/' + this.sabha));
            this.marketstreets=marketData.data; 
       },
       async changemarket() {    
            let shopno = (await axios.get('/marketstreetbyid/'+ this.sabha +'/'+ this.rentObj.market));
            this.shopnumbers=shopno.data; 
       },
       async deleteShopdetail(shop_details_id){
        await axios.delete("/deletshopdetail/" +  shop_details_id );
        this.$refs.alert.showAlert('success', 'Shop details Deleted Successfully !')
        this.allShopDetails = (await axios.get('/shopddetails/' + this.sabha)).data
       },
       resetCheckPopErr: function () {
            this.poerObj.sTaxErr = [];
            this.poerObj.sTaxNicErr = [];
            this.poerObj.sContactErr = [];
            this.poerObj.sAddrErr = [];
            this.poerObj.sRentErr = [];
            this.poerObj.efineErr =[];
          
        },
        checkPopEmptyErr: function () {
            for (var typeErr in this.poerObj) {
                if (this.poerObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
       checkPopup: function () {
            this.resetCheckPopErr();

            if (!this.popObj.sTax) {
                this.poerObj.sTaxErr.push("Tax Payee is required");
            }

            if (!this.popObj.sTaxNic){
                this.poerObj.sTaxNicErr.push("Entering NIC number is required");
            }
            // else {
            //         if (this.popObj.sTaxNic.length !==12)
            //         {
            //             if((this.popObj.sTaxNic.charAt(9).toUpperCase() !== 'V') || (this.popObj.sTaxNic.charAt(9).toLowerCase() !== 'v'))
            //               {
            //                   this.poerObj.sTaxNic.push('Please Enter Correct Nic Number');
            //               }
                          
            //         }
            // }
            //contact validate
            if (!this.popObj.sContact) {
                this.poerObj.sContactErr.push("Contact number is required");
           }
           else{
                if(!this.popObj.sContact) {
                    this.poerObj.sContactErr.push('Phone number is required');
                }
           }
          
            if (!this.popObj.sAddr) {
                this.poerObj.sAddrErr.push("Address is required");
            }
            
           // Month Validate
            if (!this.popObj.sRent) {
                this.poerObj.sRentErr.push("Rent amount is required");
            }
            // if (!this.popObj.shopvotes) {
            //     this.poerObj.sOtherErr.push("Rent amount is required");
            // }
           
            if (!this.popObj.efine) {
                this.poerObj.efineErr.push("Fine is required");
            }
             
        },
        getInsertMonthName:function (mon) {
            // const now = new Date();
            const currentmonth = mon;
            const preveiousmonth = (currentmonth -1 + 12)%12;
            if(currentmonth==0){
                return preveiousmonth+1
            }else{
                return preveiousmonth
            }
           
            },

        async saveModal(s) {
            this.checkPopup();

            if (!this.checkPopEmptyErr()) {
                s.preventDefault();
            }else{
                let popdata ={
                    shop_taxpayee : this.popObj.sTax,
                    taxpayee_nic : this.popObj.sTaxNic,
                    taxpayee_contact : this.popObj.sContact,
                    taxpayee_address : this.popObj.sAddr,
                    monthly_shop_rent : this.popObj.sRent,
                    Other : this.popObj.sOther,
                    // arrears:parseFloat(this.popObj.sArr).toFixed(2),
                    // pay_month: this.getInsertMonthName(new Date().getMonth()),
                    fine:this.popObj.efine,
                }
                await axios.put("/shopdata/"+this.selectedshop,popdata)

                let upcontactcus={
                    cus_name:this.popObj.sTax,
                    cus_contact : this.popObj.sContact,
                    cus_address: this.popObj.sAddr,
                }
                await axios.put("/upcustcon/"+this.popObj.sTaxNic,upcontactcus)

                this.$refs.alert.showAlert('success', 'Shop updated Successfully !')
                this.allShopDetails = (await axios.get('/shopddetails/'+this.sabha)).data
                 
                this.closePopup();
            }
        },
        async openPopup(id) {
            this.showPopup = true;
            document.addEventListener('mouseup', this.closeModalOnClickOutside);
            this.theShop= (await axios.get('/shopdataid/'+id)).data;
            this.popObj.sTax = this.theShop[0].shop_taxpayee;
            this.popObj.sTaxNic = this.theShop[0].taxpayee_nic;
            this.popObj.sContact = this.theShop[0].taxpayee_contact;
            this.popObj.sAddr = this.theShop[0].taxpayee_address;
            // this.popObj.market = this.theShop[0].ms_id;
            // this.popObj.shop = this.theShop[0].shop_number;
            this.popObj.sRent = this.theShop[0].monthly_shop_rent;
            // this.popObj.shopvotes = this.theShop[0].rent_head;
            // this.popObj.sArr = this.theShop[0].arrears;
            this.popObj.efine = this.theShop[0].fine;
            this.popObj.sOther = this.theShop[0].Other;
            this.selectedshop =id
        },
        closePopup() {
            this.showPopup = false;
            document.removeEventListener('mouseup', this.closeModalOnClickOutside);
        },
        closeModalOnClickOutside(event) {
            const modal = this.$refs.modalRef;
            if (!modal.contains(event.target)) {
                this.closePopup();
            }
        },
        // arreras popup
        checkArrPopEmptyErr: function () {
            for (var typeErr in this.poerObj) {
                if (this.poerObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
        resetCheckArrPopErr: function () { 
            this.poerObj.arrearsErr = [];
            this.poerObj.emonthErr=[];
          
        },
        checkArePopup: function () {
            this.resetCheckArrPopErr();
            if (!this.popObj.sArr) {
                this.poerObj.arrearsErr.push("Arrears amount is required");
            }
            if (!this.popObj.emonth) {
                this.poerObj.emonthErr.push("Last paid month & year is required");
            }
        },
        async openArrearsPopup(shopid){
            this.showArrearsPopup = true;
            this.theShop= (await axios.get('/shopdataid/'+shopid)).data;
            this.popObj.sArr = this.theShop[0].arrears;
            this.chosenshop =shopid

        },
        closeArrearsPopup() {
            this.showArrearsPopup = false;
            document.removeEventListener('mouseup', this.closeArrearsModalOnClickOutside);
        },
        closeArrearsModalOnClickOutside(event) {
            const modal = this.$refs.arrearsRef;
            if (!modal.contains(event.target)) {
                this.closeArrearsPopup();
                 this.popObj.emonth=''
            }
        },
        async arreasSaveModal(s) {
            this.checkArePopup();

            if (!this.checkArrPopEmptyErr()) {
                s.preventDefault();
            }else{
                   //get paymonth and year
           const selectedmonyr =(this.popObj.emonth).split("-")
            this.selectyr= selectedmonyr[0]
            this.selectmon= selectedmonyr[1]
                let arrdata ={
                    arrears:parseFloat(this.popObj.sArr).toFixed(2),
                    // pay_month: this.getInsertMonthName(new Date().getMonth()),
                    pay_month:parseInt(this.selectmon) -1,
                    pay_year:this.selectyr
                }
                await axios.put("/uparr/"+this.chosenshop,arrdata)
                this.$refs.alert.showAlert('success', 'Arrears updated Successfully !')
                this.allShopDetails = (await axios.get('/shopddetails/'+this.sabha)).data
                this.closeArrearsPopup();
                this.popObj.emonth=''
            }
        },
    //    showshopRentdiv: function (){
    //         document.getElementById('shopRentdiv').style.display ='block';
    //         document.getElementById('MeatRentdiv').style.display = 'none';
    //     },
    //     showMeatRentdiv:function (){
    //         document.getElementById('MeatRentdiv').style.display = 'block';
    //         document.getElementById('shopRentdiv').style.display ='none';
    //     },
    addZero(num) {
        num = num.toString();
    return '19' + num.slice(0, 5) + '0' + num.slice(5, -1);
},
addZeroPadding: function() {
// this.len= this.loginObj.nic.charAt(9).toUpperCase()
// Check if the user input is a valid number
// if (!isNaN(parseInt(this.rentObj.sTaxNic)) && this.rentObj.sTaxNic !== null && this.rentObj.sTaxNic.charAt(9).toUpperCase() === 'V') {
if (!isNaN(parseInt(this.rentObj.sTaxNic)) && this.rentObj.sTaxNic !== null && (this.rentObj.sTaxNic.charAt(9).toUpperCase() === 'V' || this.rentObj.sTaxNic.charAt(9).toUpperCase() === 'X')) {
    
    this.paddedNum = this.addZero(this.rentObj.sTaxNic);

}else{
    this.paddedNum=parseInt(this.rentObj.sTaxNic);
}
return this.paddedNum
},


            resetCheckErr: function () {
            this.errorObj.sTaxErr = [];
            this.errorObj.sTaxNic = [];
            // this.errorObj.sContact=[]
            this.errorObj.sAddr = [];
            this.errorObj.marketErr = [];
            this.errorObj.shopErr = [];
            this.errorObj.sRentErr = [];
            this.errorObj.sOtherErr = [];
            // this.errorObj.renttyperErr = [];
            this.errorObj.arrearsErr = [];
            this.errorObj.finevoteeErr=[];
            this.errorObj.fineErr=[];
            this.errorObj.shopVoteErr=[]
        },
        resetObjects: function(){
            this.rentObj.dis="";
            this.rentObj.pro="";
            this.rentObj.pra_sabha="";
            this.rentObj.shop="";
            this.rentObj.shop="";
            this.rentObj.rentdes="";
            this.rentObj.month=null
            this.rentObj.year=null;
            this.rentObj.shoprent="";
            this.rentObj.mfine="0"
        },
        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
         checkForm: function () {
            this.resetCheckErr();

            // District validate
            if (!this.rentObj.sTax) {
                this.errorObj.sTaxErr.push("Tax Payee is required");
            }
       
            // NIC validate
            if (!this.rentObj.sTaxNic) {
                this.errorObj.sTaxNic.push('Entering NIC number is required');
                }else {
                    // if (this.rentObj.sTaxNic.length !==12)
                    // {
                    //     if((this.rentObj.sTaxNic.charAt(9).toUpperCase() !== 'V') || (this.rentObj.sTaxNic.charAt(9).toLowerCase() !== 'v'))
                    //       {
                    //           this.errorObj.sTaxNic.push('Please Enter Correct Nic Number');
                    //       }
                          
                    // }
                    if ( this.rentObj.sTaxNic.length !== 12 &&
                            !(
                                this.rentObj.sTaxNic.charAt(9).toUpperCase() === 'V' ||
                                this.rentObj.sTaxNic.charAt(9).toLowerCase() === 'v' ||
                                this.rentObj.sTaxNic.charAt(9).toUpperCase() === 'X' ||
                                this.rentObj.sTaxNic.charAt(9).toLowerCase() === 'x'
                            )
                        ) {
                            this.errorObj.sTaxNic.push('Please Enter Correct Nic Number');
                        }
            }
            //contact validate
            // if (!this.rentObj.sContact) {
            //     this.errorObj.sContact.push("Contact number is required");
            // }else{
            //     if(!/[0-9]{10}/.test(this.rentObj.sContact)) {
            //     this.errorObj.sContact.push('Phone numbers should contain 10 Digits, no spaces allowed');
            //     }
            // }
          
            // Pradeshiya validate
            if (!this.rentObj.sAddr) {
                this.errorObj.sAddr.push("Address is required");
            }
            // marketPlace validate
            if (!this.rentObj.market) {
                this.errorObj.marketErr.push("Market/Street/Place is required");
            }
            // shopnumbers validate
            if (!this.rentObj.shop) {
                this.errorObj.shopErr.push("Shop Number is required");
            }
           // Month Validate
            if (!this.rentObj.sRent) {
                this.errorObj.sRentErr.push("Rent amount is required");
            }
            if (!this.rentObj.shopvotes) {
                this.errorObj.shopVoteErr.push("Payment vote is required");
            }
            if (!this.rentObj.finevote) {
                this.errorObj.finevoteeErr.push("Fine vote is required");
            }
            if (!this.rentObj.mfine) {
                this.errorObj.fineErr.push("fine is required");
            }

            // year Validate
            // if (!this.rentObj.renttype) {
            //     this.errorObj.renttyperErr.push("Shop type is required");
            // }
           
       
        },
     
       async handleSubmit(e) {
           this.checkForm();
           
                if (!this.checkEmptyErr()) {
                    e.preventDefault();
                } else {
                 e.preventDefault();
               
                    if(!this.user){
                        this.$refs.alert.showAlert('error','Please Loging to Continue !')
                    }
                    else{
                        let Nic12 = this.addZeroPadding();
                     const data = {
                        // cus_nic:this.user.cus_nic,
                        shop_sabha :this.sabha,
                        shop_taxpayee :this.rentObj.sTax,
                        taxpayee_nic :Nic12,
                        taxpayee_contact:this.rentObj.sContact,
                        taxpayee_address : this.rentObj.sAddr,
                        shop_number :this.rentObj.shop,
                        monthly_shop_rent :this.rentObj.sRent,
                        Other :this.rentObj.sOther,
                        shop_place: this.rentObj.market,
                        rent_head :this.rentObj.shopvotes,
                        arrears :this.rentObj.sArr,
                        pay_month: this.getInsertMonthName(new Date().getMonth()),//'0',
                        pay_year:0,
                        fine_vote:this.rentObj.finevote,
                        fine:this.rentObj.mfine
                    }
                    // sessionStorage.setItem('rentDetails',JSON.stringify(data));
                    await axios.post("/shopdata/", data)
                    
                    document.getElementById("ShopDetailForm").reset();
                    this.allShopDetails = (await axios.get('/shopddetails/' + this.sabha)).data
                    // add to sabha customer table
                    this.theUser= (await axios.get('/customers/'+Nic12+"/"+this.sabha)).data;
                if(this.theUser.length==0){

                    const cusdata ={
                        sabha_code :this.sabha,
                        cus_nic :Nic12,
                        cus_name:this.rentObj.sTax,
                        cus_contact:this.rentObj.sContact,
                        cus_address:this.rentObj.sAddr,
                    }
                    await axios.post("/customers/", cusdata)
                }
                    this.$refs.alert.showAlert('success', 'Shop Added Successfully !')
                //     this.resetObjects(); 
                // sessionStorage.removeItem("usernic");
                this.rentObj.sTax =""
                this.rentObj.sTaxNic =""
                this.rentObj.sAddr =""
                this.rentObj.shop=""
                this.rentObj.sRent=""
                this.rentObj.sOther=""
                this.rentObj.market=""
                this.rentObj.sArr=0
                this.rentObj.sContact=""
                }
            }
        }
        
      
    },
    components: {
        VueBasicAlert
    }
}

</script>





<style scoped>
.ttable {
     
     width: 100%;
     border-width:1px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 0.9rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      border-collapse: collapse;
 }
 th  {
     text-align: center;
}
.ttable, th, td {
border: 1px solid;
text-align: center;
}
.register-container {
    padding: 1.5rem 9%;
     /* min-height: 72.3vh; */
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 98rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}

.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 40%;
    border: none;
    padding-bottom: 0.5rem;
}
.register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;

    width: 10%;
    border: none;
    margin-left: 10%;
}
.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #022e2a;

}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 30%;
    text-align: center;
    align-items: center;
    /* background: #022e2a; */
    background-color : #032a2c;
    /* height: 3rem; */
    color: #e9e4e9;
    
}

.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 90%;

} 
.register-container .register-form-container form .hide {
    display: none;

} 
/* for second container */
.Shop-container {
   background-color: #ffffff09;
   /* height: 50vh; */
   padding: 2rem 9%;
   font-size: 16px;
}

.Shop-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}
/* newly added from income heads */
.Shop-container {
   padding: 2rem 9%;
}
/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #af74a7;
    height: 3rem;
  
   
}
/* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
} */
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: rgb(22, 21, 21);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
/* .project-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
} */
/* [popup] */
.popup-button {
      padding: 10px 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  }

  .modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
  }

  .modal-content {
      background-color: white;
      padding: 5px;
      border-radius: 1px;
      /* max-width: 500px; */
      width: 60%;
      position: relative;
  }

  .close x {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 18px;
      color: #555;
  }

  .close-x:hover {
      color: #ff0000;
      /* Change to your desired hover color */
  }


  .modal-header {
      padding-bottom: 2px;
      border-bottom: 1px solid #ccc;
  }

  .modal-body {
      /* padding: 10px 20px 20px 20px; */
      padding-left: 5%; 

  }
  .model-group {
      padding: 10px ;
      /* padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 10%; */
      /* margin: 10px; */
      /* justify-content: left; */
      margin-right: 10px;
  }
  .modal-body.model-group .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 10%;
    padding: 0;
    width: 90%;

} 
.modal-body.row {
    display: flex;
    margin: 10%;
  }
  .modal-footer {
      padding-top: 5px;
      border-top: 1px solid #ccc;
      display: flex;
      justify-content: flex-end;
  }

  .close-button,
  .save-button {
      padding: 5px 10px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 4px;
      transition: background-color 0.3s ease;
  }

  .close-button {
      background-color: #f44336;
      color: white;
  }

  .save-button {
      background-color: #4CAF50;
      color: white;
      margin-left: 5px;
  }

  .close-button:hover {
      background-color: #d32f2f;
  }

  .save-button:hover {
      background-color: #45a049;
  }
  /* 2 */
  .modal-body2 {
      /* padding: 10px 20px 20px 20px; */
      padding-left:1.0rem; 

  }
  .modal-content2 {
      background-color: white;
      padding: 5px;
      border-radius: 1px;
      /* max-width: 500px; */
      width: 30%;
      position: relative;
  }
  .model-group2 {
      /* padding: 10px ; */
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 10%; 
      /* margin: 10px; */
      justify-content:center;
      margin-right: 10px;
  }
  .modal-footer2 {
      padding-top: 5px;
      border-top: 1px solid #ccc;
      display: flex;
      justify-content: flex-end;
  }
  .close-x2 {
      /* position: absolute; */
      top: 5px;
      right: 5px;
      left: 5px;
      padding: 5px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 15px;
      color: #555;
  }
  .modal-body2 .model-group2 .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 10%;
    padding: 0;
    width: 90%;

} 
</style>
