<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="Shop-container">
        <div class="row1">
           <router-link to="/asesdash" class="close-btn" > Close </router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h1>Add Assessment tax PIV</h1>
       </div>
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
                <div class="heading">
                    <b><h3>Search Property</h3></b>
                </div>
                <h5><b>Select by Category</b></h5>
               <div class="row">
                <div class="col-sm-3">
                    
                   <label for="Splace">Ward
                   </label>
                       <div><select v-model="CatObj.ward" id="ward" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in savedWards" :key="m.ward_id" id="Hid"  
                            v-bind:value =m.ward_id>
                               <div>{{m.ward }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.wardErr.length > 0">{{ errObj.wardErr[0] }}</p>
               </div>
               <div class="col-sm-3">
                   <label for="street">Street
                   </label>
                       <div><select v-model="CatObj.street" id="street" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in savedStreets" :key="m.street_id" id="Hid"  
                            v-bind:value =m.street_id>
                               <div>{{m.street_name }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.stretErr.length > 0">{{ errObj.stretErr[0] }}</p>
               </div>
               <div class="col-sm-3">
                   <label for="cat">Main Category
                   </label>
                       <div><select v-model="CatObj.mainc" id="cat" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in maincate" :key="m.cat_id" id="Hid"  
                            v-bind:value =m.cat_id>
                               <div>{{m.category_name }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.maincErr.length > 0">{{ errObj.maincErr[0] }}</p>
               </div>
               <div class="Addbtn col-sm-3">
                   <label for="s">
                   </label>
                   <input type="button" value="Search" id="Addpay" class=" btn" @click="SearchByCat()"  /> 
               </div>
               </div>
                <div class="row">                    
                    <div class="col-sm-3">
                        <h5><b>Select by property ID</b></h5>
                        <label for="usedn"> Property ID:
                        </label>
                        <div><select v-model="CatObj.propsearch" id="serchprop" class="form-control" >
                            <option value="" selected disabled>
                                        Choose
                                    </option>
                                    <option v-for="p in sabaProp" :key="p.property_id" id="Hid"  
                                    v-bind:value =p.property_id>
                                    <div>{{p.property_id }}</div> 
                                    </option>
                                </select>
                            <!-- <p class="error-mess" v-if="errObj.propErr.length > 0">{{ errObj.propErr[0] }}</p> -->
                        </div>
                    </div>
                    <div class="Addbtn col-sm-3">
                   <br>
                   <input type="button" value="Search" id="secbtn" class=" btn" @click="SearchById()"  /> 
               </div>
                </div>
            
           <br>
           <div v-if="showtbl" >
           <div   style="width: 100%; text-align: center;">
                <h3>Select a Property to Add PIV</h3>
            </div>
           <div class="row">
            <table style="width: 100%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
               <thead>
                   <tr>   
                        <th>Index</th>
                       <th>Property ID</th>
                       <th>Assessment Num</th>
                       <th>Address</th>
                       <th>Rate</th>
                       <th>Select</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">
                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                       <td>{{ s.property_id  }}</td>
                       <td>{{ s.asses_num  }}</td>
                       <td>{{ s.prop_address }}</td>
                       <td>{{ s.prop_rate }}</td>
                       <td> <input
                                type="radio"
                                name="propertySelection"
                                :value="s.property_id"
                                v-model="selectedProperty"
                                 @change="populateCustomerDetails(s.property_id)"
                            /></td>
                   </tr>
               </tbody>
           </table>
           </div>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
        </div>
        <!-- <div v-if="selectedProperty" style="margin-top: 10px; text-align: center;">
            <p>Selected Property ID: {{ selectedProperty }}</p>
         </div> -->
           </form>
           <!-- piv -->
            <div class="register-container">
           <div class="register-form-container">
           <form id="userForm"  novalidate autocomplete="off">
               <div class="heading">
                   <h3>Invoice Details </h3>
               </div>
               <h4>Assesment Property Details</h4>
                   <div class="row">
                    <div class="col-sm-8">
                        <label for="cName">Name:</label>
                        <textarea name="cName" id="cName" class="form-control" v-model="cusObj.name" rows="2" readonly></textarea>
                    </div>
                       <div class="col-sm-4">
                           <label for="cAddress">Address:</label>
                            <textarea name="cAddress"  id="cAddress" class="form-control" v-model="cusObj.address" readonly ></textarea>
                       </div>
                   </div>
<div class="form-group" >
<table class="ttable">
  <thead>
    <tr>
      <th>Quarter</th>
      <th>Payable Amount</th>
      <th>Paid Amount</th>
      <th>Fine</th>
    </tr>
  </thead>
  <tbody>
    <template v-for="p in propDetails" :key="p.property_id">
  <!-- First Quarter -->
  <tr>
    <td style="text-align: left;"><b>First Quarter Amount</b></td>
    <td>{{ p.q_one_val }}</td>
    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][1] ? paymentData[p.property_id][1][0] : 'Loading...' }}</td>
    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][1] ? paymentData[p.property_id][1][1] : 'Loading...' }}</td>
  </tr>

  <!-- Second Quarter -->
  <tr>
    <td style="text-align: left;"><b>Second Quarter Amount</b></td>
    <td>{{ p.q_two_val }}</td>
    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][2] ? paymentData[p.property_id][2][0] : 'Loading...' }}</td>
    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][2] ? paymentData[p.property_id][2][1] : 'Loading...' }}</td>
  </tr>

  <!-- Third Quarter -->
  <tr>
    <td style="text-align: left;"><b>Third Quarter Amount</b></td>
    <td>{{ p.q_three_val }}</td>
    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][3] ? paymentData[p.property_id][3][0] : 'Loading...' }}</td>
    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][3] ? paymentData[p.property_id][3][1] : 'Loading...' }}</td>
  </tr>

  <!-- Fourth Quarter -->
  <tr>
    <td style="text-align: left;"><b>Fourth Quarter Amount</b></td>
    <td>{{ p.q_four_val }}</td>
    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][4] ? paymentData[p.property_id][4][0] : 'Loading...' }}</td>
    <td>{{ paymentData[p.property_id] && paymentData[p.property_id][4] ? paymentData[p.property_id][4][1] : 'Loading...' }}</td>
  </tr>
</template>


  </tbody>
</table>

<br>
</div>                       
                   <h4>Payment Details</h4>
                   <div class="form-group d-flex align-items-center mb-2">
                  <label for="fqp" class="me-2" style="width: 180px;">Arrears Amount:</label>
                  <input type="text" id="parr" class="form-control" v-model="cusObj.arre" style="width: auto;" />
                </div> 
                   <div class="form-group d-flex align-items-center mb-2">
                    <label for="checkboxannual" style="width: 200px;"><b>Pay Full Annual Amount</b></label>&nbsp;
                    <input type="checkbox" id="checkboxannual" v-model="Annualchecked"  @change="handleCheckboxChange"  />
                    <!-- <p class="error-mess" v-if="cusErrObj.monthendErr.length > 0">{{ cusErrObj.monthendErr[0] }}</p> -->
                    
                  </div>
                  <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
                  <div class="form-group" id="yrvalforann" v-show="Annualchecked">
                    <label for="fqp" class="me-2" style="width: 180px;">Yearly Value:</label>
                    <input type="text" id="annval" class="form-control" v-model="cusObj.yearlyval" style="width: auto;" />
                  </div> 
                  <div class="form-group " id="discforann" v-show="Annualchecked">
                    <label for="fqp" class="me-2" style="width: 180px;">Discount Value:</label>
                    <input type="text" id="anndis" class="form-control" v-model="cusObj.discval" style="width: auto;" />
                  </div> 

               <div>
  <br>
                <div class="form-group d-flex align-items-center mb-2">
                  <label for="checkbox" style="width: 100px;"><b>Tax Relief given</b></label>&nbsp;
                  <input type="checkbox" id="checkbox" v-model="Relchecked" style="width: 70px;" />
                </div>
                <div class="form-group relief-row " id="relief" v-show="Relchecked">
                  <label for="frel">Relief Percentage given(%) :&nbsp; </label>
                  <input type="text" id="ream" v-model="cusObj.relper" />
                  <input type="button" value="Calculate" id="calbtn" @click="calculateRelivedPayments" style="width: auto;" />
                </div>
                <br>
                <div>
  <!-- Headings -->
  <div class="form-group d-flex align-items-center mb-2 fw-bold">
    <label class="me-2" style="width: 180px;"></label>
    <label class="me-2" style="width: 180px;"><b>Quarter Payments</b></label>
    <label class="me-2" style="width: 180px;"><b>Discount</b></label>
    <label class="me-2" style="width: 180px;"><b>Fine</b></label>
    <label class="me-2" style="width: 200px;"><b>Amount to Pay</b></label>
    <label class="me-2" style="width: auto;"><b>Pay</b></label>
  </div>

  <!-- First Quarter Payment Row -->
  <div class="form-group d-flex align-items-center mb-2">
    <label for="fqp" class="me-2" style="width: 180px;">First Quarter Payment:</label>
    <input type="text" id="fqpay" class="form-control me-2" v-model="cusObj.fq" style="width: auto;" />&nbsp;
    <input type="text" id="fqdisc" class="form-control me-2" :value="calculatedDiscount"  placeholder="Discount" style="width: auto;" />&nbsp;
    <input type="text" id="fqfine" class="form-control me-2" :value="calculatedFine" placeholder="Fine" style="width: auto;" />&nbsp;
    <input type="text" id="fqamt" class="form-control" :value="calculatedAmount" placeholder="Amount to Pay" style="width: auto;" />
    <input type="checkbox" id="checkboxqone" class="form-control" v-model="qonecheck"  @change="qoneChecked" style="width: 5%;"  />
  </div>
  <p v-if="qOneErrorMessage" class="text-danger">{{ qOneErrorMessage }}</p>

  <!-- Second Quarter Payment Row -->
  <div class="form-group d-flex align-items-center mb-2">
    <label for="sqp" class="me-2" style="width: 180px;">Second Quarter Payment:</label>
    <input type="text" id="sqpay" class="form-control me-2" v-model="cusObj.sq" style="width: auto;" />&nbsp;
    <input type="text" id="sqdisc" class="form-control me-2" :value="secondQuarterDiscount" placeholder="Discount" style="width: auto;" />&nbsp;
    <input type="text" id="sqfine" class="form-control me-2" :value="secondQuarterFine" placeholder="Fine" style="width: auto;" />&nbsp;
    <input type="text" id="sqamt" class="form-control" :value="secondQuarterAmount" placeholder="Amount to Pay" style="width: auto;" />
    <input type="checkbox" id="checkboxqtwo" class="form-control" v-model="qtwocheck"  @change="qtwoChecked" style="width: 5%;"  />
  </div>
  <p v-if="qtwoErrorMessage" class="text-danger">{{ qtwoErrorMessage }}</p>

  <!-- Third Quarter Payment Row -->
  <div class="form-group d-flex align-items-center mb-2">
    <label for="tqp" class="me-2" style="width: 180px;">Third Quarter Payment:</label>
    <input type="text" id="tqpay" class="form-control me-2"  v-model="cusObj.tq" style="width: auto;" />&nbsp;
    <input type="text" id="tqdisc" class="form-control me-2" :value="thirdQuarterDiscount"  placeholder="Discount" style="width: auto;" />&nbsp;
    <input type="text" id="tqfine" class="form-control me-2"  :value="thirdQuarterFine"  placeholder="Fine" style="width: auto;" />&nbsp;
    <input type="text" id="tqamt" class="form-control" :value="thirdQuarterAmount" placeholder="Amount to Pay" style="width: auto;" />
    <input type="checkbox" id="checkboxqthree" class="form-control" v-model="qthrecheck"  @change="qthreeChecked" style="width: 5%;"  />
    
  </div>
  <p v-if="qthreeErrorMessage" class="text-danger">{{ qthreeErrorMessage }}</p>

  <!-- Fourth Quarter Payment Row -->
  <div class="form-group d-flex align-items-center mb-2">
    <label for="foqp" class="me-2" style="width: 180px;">Fourth Quarter Payment:</label>
    <input type="text" id="foqpay" class="form-control me-2" v-model="cusObj.foq" style="width: auto;" />&nbsp;
    <input type="text" id="foqdisc" class="form-control me-2"  :value=" fourthQuarterDiscount" placeholder="Discount" style="width: auto;" />&nbsp;
    <input type="text" id="foqfine" class="form-control me-2" :value=" fourthQuarterFine"  placeholder="Fine" style="width: auto;" />&nbsp;
    <input type="text" id="foqamt" class="form-control"  :value=" fourthQuarterAmount" placeholder="Amount to Pay" style="width: auto;" />
    <input type="checkbox" id="checkboxqfour" class="form-control" v-model="qfourcheck"  @change="qfourChecked" style="width: 5%;"  />
  </div>
  <p v-if="qthreeErrorMessage" class="text-danger">{{ qfourerrorMessage }}</p>
</div>
  <div class="form-group">
                   <label for="iHead">Income Head:
                   </label>
                 <select v-model="cusObj.headid" id="iHead" class="form-control">
                       <option value="" selected disabled>
                                Choose
                       </option>
                       <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.emp_sb_rates"  
                           v-bind:value =[ratehead.emp_sb_rates,ratehead.subjecttype]  >
                             {{ ratehead.emp_sb_rates  }} - {{ratehead.sb_rate_head_name}}
                       </option>
                   </select>

                   <p class="error-mess" v-if="cusErrObj.descrErr.length > 0">{{ cusErrObj.descrErr[0] }}</p> 
</div>


                <div class="form-group">
                    <label for="desc">Description:</label>
                    <input type="text" id="desc" class="form-control" v-model="cusObj.descr" />
                </div>
               </div>
           </form>
       </div>
       </div>
</div>
</div>
<!-- end -->
</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {

    name: "AssessPIV",

    data() {
       return {
           nic:"",
           sabha: "",
           userLevel:"",
           CatObj : { ward:"", street:"",  mainc:"" ,propsearch:""},
           errObj:{ wardErr:[], stretErr:[],maincErr:[]},
           savedWards:[],
           savedStreets:[],
           itemsPerPage: 10,
            currentPage: 1,
            maincate:[],
            sabaProp:[],
            propertydetails:[],
            showtbl:false,
            selectedProperty: null,
            cusObj:{name:"",address:"",headid:"",descr:"",arre:"",relper:"0",fq:"",sq:"",tq:"",foq:"",fqDiscount:"",sqDiscount:"",yearlyval:"",discval:""},
            cusErrObj:{nameErr:[],addErr:[],headidErr:[],descrErr:[],monthendErr:[]},
            ownerdetails:[],
            sabhaIncomeHead:[],
            propDetails:[],
            paymentData: {}, // Store paid amount and fine for each property and quarter
            response:[],
            responseData:[],
            Relchecked: false,
            propertyde:[],
            Annualchecked:false,
            errorMessage: "",
            qonecheck:false,
            qOneErrorMessage:"",
            qtwoErrorMessage:"",
            qtwocheck:false,
            qthreeErrorMessage:"",
            qthrecheck:false,
            qfourerrorMessage:"",
            qfourcheck:false
       }; 
   },
   created() {
       this.getDataFromSessionStorage()

   },

   computed:{
       ...mapState(["user"]),
      
    paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.propertydetails)) {
        return this.propertydetails.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.propertydetails.length / this.itemsPerPage);
    },
    //first quarter
    calculatedDiscount() {
    if (!this.qonecheck || !this.cusObj.fq) return "";
    const currentDate = new Date();
    return this.calQuarterDiscount(this.cusObj.fq, currentDate)[0];
  },
  calculatedAmount() {
    if (!this.qonecheck || !this.cusObj.fq) return "";
    const currentDate = new Date();
    return this.calQuarterDiscount(this.cusObj.fq, currentDate)[1];
  },
  calculatedFine() {
    if (!this.qonecheck || !this.cusObj.fq) return "";
    const currentDate = new Date();
    return this.calQuarterDiscount(this.cusObj.fq, currentDate)[2];
  },

    //second quarter
    secondQuarterDiscount(){
      if (!this.qtwocheck || !this.cusObj.sq) return "";
      const currentDate = new Date();
      return this.calSecondQuarterValues(this.cusObj.sq, currentDate)[0];
    },
    secondQuarterAmount() {
      if (!this.qtwocheck || !this.cusObj.sq) return "";
      const currentDate = new Date();
      return this.calSecondQuarterValues(this.cusObj.sq, currentDate)[1];
    },
    secondQuarterFine() {
      if (!this.qtwocheck || !this.cusObj.sq) return "";
      const currentDate = new Date();
      return this.calSecondQuarterValues(this.cusObj.sq, currentDate)[2];
    },
        //Third quarter
    thirdQuarterDiscount(){
      if (!this.qthrecheck || !this.cusObj.tq) return "";
      const currentDate = new Date();
      return this.calThirdQuarterValues(this.cusObj.tq, currentDate)[0];
    },
    thirdQuarterAmount() {
      if (!this.qthrecheck || !this.cusObj.tq) return "";
      const currentDate = new Date();
      return this.calThirdQuarterValues(this.cusObj.tq, currentDate)[1];
    },
    thirdQuarterFine() {
      if (!this.qthrecheck || !this.cusObj.tq) return "";
      const currentDate = new Date();
      return this.calThirdQuarterValues(this.cusObj.tq, currentDate)[2];
    },
    //Fourth quarter
    fourthQuarterDiscount(){
      if (!this.qfourcheck || !this.cusObj.foq) return "";
      const currentDate = new Date();
      return this.calFourthQuarterValues(this.cusObj.foq, currentDate)[0];
    },
    fourthQuarterAmount() {
      if (!this.qfourcheck || !this.cusObj.foq) return "";
      const currentDate = new Date();
      return this.calFourthQuarterValues(this.cusObj.foq, currentDate)[1];
    },
    fourthQuarterFine() {
      if (!this.qfourcheck || !this.cusObj.foq) return "";
      const currentDate = new Date();
      return this.calFourthQuarterValues(this.cusObj.foq, currentDate)[2];
    },
   },
   async mounted() {
    try {
        this.maincate = (await axios.get('/maincat/')).data
           //wards
           this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
           //streets
           this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;

    } catch (error) {
        console.error("Error fetching data", error);
    }

    await this.loadPaymentData();

},
watch: {
  propDetails: {
    handler(newVal) {
      if (newVal && newVal.length > 0) {
        newVal.forEach(p => {
          for (let qua = 1; qua <= 4; qua++) {
            this.loadPaymentData(p.property_id, qua);
          }
        });
      }
    },
    immediate: true,
    deep: true
  }
},
   methods: {
       ...mapMutations(["setUser"]),

       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }
                try{
                this.sabaProp = (await axios.get('/props/' +this.sabha)).data                       
            } catch (error) {
                console.error("Error fetching data", error);
            }
           this.maincate = (await axios.get('/maincat/')).data
           //wards
           this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
           //streets
           this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;
           //incomeheads
           let Sabhadata = await axios.get('/subheads/'+ this.nic +"/"+ this.sabha)
           this.sabhaIncomeHead = Sabhadata.data
       },
       getWardName(ward_id) {
        const ward = this.savedWards.find(m => m.ward_id === ward_id);
        return ward ? ward.ward : 'Unknown';  // Return 'Unknown' if ward_id is not found
    },
    getStreetName(street_id) {
        const street = this.savedStreets.find(m => m.street_id === street_id);
        return street ? street.street_name : 'Unknown';
    },
    getCategoryName(cat_id) {
        const category = this.maincate.find(m => m.cat_id === cat_id);
        return category ? category.category_name : 'Unknown';
    },

nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  event.preventDefault();
},
prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  event.preventDefault();
},
//fectch both
    async fetchData(url) {
        try {
            const response = await axios.get(url);
            this.propertydetails = response.data;
            if (this.propertydetails.length > 0) {
            this.showtbl = true;
            } else {
            this.message = "No such record";
            this.showtbl = false;
            }
        } catch (error) {
            console.error("Error fetching details:", error);
            this.message = "Failed to load details. Please try again later.";
        }
        },

       resetCheckErr: function () {
        this.errObj.wardErr =[];
        this.errObj.stretErr=[];
        this.errObj.maincErr = [];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           if (!this.CatObj.ward) {
               this.errObj.wardErr.push("required");
           }
           if (!this.CatObj.street) {
               this.errObj.stretErr.push("required");
           }
           // main categories
           if (!this.CatObj.mainc) {
               this.errObj.maincErr.push("required");
           }
       },
       async SearchByCat(e) {
            this.checkForm();
            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                this.showtbl = false;
            this.message = ""; // Clear any previous messages
            this.propertydetails = []; // Clear previous data
            this.CatObj.propsearch=""
            this.selectedProperty= null
                const url = `/proplist/${this.sabha}/${this.CatObj.ward}/${this.CatObj.street}/${this.CatObj.mainc}`;
                await this.fetchData(url);
            }
        },
    async SearchById() {
          this.showtbl = false;
        this.message = ""; // Clear any previous messages
        this.propertydetails = []; // Clear previous data
        this.CatObj.ward="",
        this.CatObj.street="",
        this.CatObj.mainc="",
        this.selectedProperty= null
    try {
        const url = `/sabapropid/${this.sabha}/${this.CatObj.propsearch}`;
        
        // Fetch data from API
        const response = await axios.get(url);

        if (response.data.length > 0) {
        this.propertydetails = response.data;
        this.showtbl = true;
        } else {
        this.message = "No such record";
        this.showtbl = false;
        }
    } catch (error) {
        console.error("Error fetching details:", error);
        this.message = "Failed to load details. Please try again later.";
        this.showtbl = false;
    }
    },
    //selected property's details
    // async populateCustomerDetails(property) {
    //   // Populate the form fields with the selected property's details
      
    //   this.ownerdetails = (await axios.get('/proownerdetatails/'+property+"/"+this.sabha)).data;
    //   this.cusObj.name = this.ownerdetails.owner_name || "";
    //   this.cusObj.phone = this.ownerdetails.owner_contact || "";
    //   this.cusObj.address = this.ownerdetails.owner_address || "";
    // },
    async populateCustomerDetails(property) {
  try {
    const response = await axios.get(`/prownerdetails/${property}/${this.sabha}`);

    if (response.data && response.data.length > 0) {
      this.ownerdetails = response.data;

      // Join multiple names into a single string (e.g., comma-separated)
      this.cusObj.name = this.ownerdetails.map(owner => owner.cus_name).join(', ') || "";

      // Assign the first address found
      this.cusObj.address = this.ownerdetails[0].prop_address || "";
    } else {
      console.warn("No owner details found.");
      this.$refs.alert.showAlert('error', 'No owner details found');
      this.cusObj = { name: "", address: "" }; // Clear form
      this.message = "No owner details available.";
    }
    //get property payment details to show in the table
    this.propDetails =  (await axios.get('/sabapropid/'+ this.sabha +"/"+ this.CatObj.propsearch)).data
    this.cusObj.arre=this.propDetails[0].arrears_val
    this.cusObj.fq=this.propDetails[0].q_one_val
    this.cusObj.sq=this.propDetails[0].q_two_val
    this.cusObj.tq=this.propDetails[0].q_three_val
    this.cusObj.foq=this.propDetails[0].q_four_val

  } catch (error) {
    console.error("Error fetching owner details:", error);
    this.message = "Failed to load owner details. Please try again later.";
  }
  // this.loadPaymentData()
},
async loadPaymentData(pid, qua) {
  const currentYear = new Date().getFullYear();
  // console.log(`Calling loadPaymentData for PID: ${pid}, Quarter: ${qua}`);

  try {
    const response = await axios.get(`/pqpayview/${pid}/${this.sabha}/${qua}/${currentYear}`);
    const result = response.data[0]; // Assuming the response is an array with data in the first item

    // Check if data exists for the requested quarter
    if (!result) {
      // console.log(`No data for PID: ${pid}, Quarter: ${qua}`);
      // Set "Not Paid" values in paymentData when there's no data for this quarter
      if (!this.paymentData[pid]) this.paymentData[pid] = {};
      this.paymentData[pid][qua] = ["Not Paid", "Not Paid"];
      return ["Not Paid", "Not Paid"];
    }

    // Initialize the object structure if it doesn’t exist
    if (!this.paymentData[pid]) this.paymentData[pid] = {};

    // Store data in paymentData
    this.paymentData[pid][qua] = [
      parseFloat(result.paid_amount).toFixed(2),
      parseFloat(result.fine_amount).toFixed(2)
    ];
  
    return this.paymentData[pid][qua];
  } catch (error) {
    // console.error(`Error loading data for PID: ${pid}, Quarter: ${qua}`, error);
    if (!this.paymentData[pid]) this.paymentData[pid] = {};
    this.paymentData[pid][qua] = ["Error", "Error"]; // Return "Error" in case of API error
    return ["Error", "Error"];
  }
},
async calculateRelivedPayments() {
      try {
        this.propertyde = (
          await axios.get('/sabapropid/' + this.sabha + '/' + this.CatObj.propsearch)
        ).data;

        let assessval = this.propertyde[0].yearly_value; // yearly value
        let relifrate = this.cusObj.relper; // Relief rate percentage
        if (relifrate) {
          let yeralyval = (assessval * (parseFloat(relifrate) / 100)).toFixed(2); // New yearly value
          let newyearlyval= (assessval-yeralyval).toFixed(2)
          let oneQuatervalue = parseFloat(newyearlyval / 4); // Value of one quarter
          let threeQuartersum = parseFloat(oneQuatervalue * 3); // Value of 3 quarters

          // Update values in quarter payment textboxes
          this.cusObj.fq = oneQuatervalue;
          this.cusObj.sq = oneQuatervalue;
          this.cusObj.tq = oneQuatervalue;
          this.cusObj.foq = (newyearlyval - threeQuartersum);
        }
      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    },
    // handleCheckboxChange() {
    //   // Reset error message
    //   this.errorMessage = "";
    //   this.cusObj.yearlyval= this.propDetails[0].yearly_value ;
    //   // Get the current date
    //   const today = new Date();
    //   // const january31 = new Date(today.getFullYear(), 1, 01); // January 31 of the current year
    //   const january31 = new Date(today.getFullYear(), 10, 21); // for checking purpose

    //   if (this.Annualchecked) {
    //     if (today > january31) {
    //       this.errorMessage = "The time to pay the full annual amount has passed.";
    //       this.Annualchecked = false; // Reset checkbox if error
    //     } else {
    //       // this.performCalculation();
    //       let discount_amount=0;
    //       let yearly_val=this.propDetails[0].yearly_value ;
    //       let discount_value=0;

    //       discount_amount=(yearly_val*(parseFloat (10)/100)).toFixed(2)
    //       discount_value = (yearly_val-discount_amount).toFixed(2)
    //       this.cusObj.discval =discount_value

    //     }
    //   }
    // },
    handleCheckboxChange() {
  // Reset error message
  this.errorMessage = "";

  if (!this.Annualchecked) {
    // Clear values when checkbox is unchecked
    this.cusObj.yearlyval = "";
    this.cusObj.discval = "";
    return; // Exit early as no further actions are needed
  }

  // Ensure propDetails is loaded and not empty
  if (!this.propDetails || !this.propDetails[0]) {
    this.errorMessage = "Property details are not available.";
    this.Annualchecked = false;
    return;
  }

  // Set yearly value
  const yearly_val = this.propDetails[0].yearly_value;
  this.cusObj.yearlyval = yearly_val;

  // Get the current date
  const today = new Date();
  const strippedCurrentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const january31 = new Date(today.getFullYear(), 0, 31); // January 31 of the current year
      // const january31 = new Date(today.getFullYear(), 10, 21); // for checking purpose

  if (this.Annualchecked) {
    if (strippedCurrentDate > january31) {
      this.errorMessage = "The time to pay the full annual amount has passed.";
      this.Annualchecked = false; // Reset checkbox if error
    } else {
      // Perform discount calculation
      this.calculateDiscount(yearly_val);
    }
  }
},

calculateDiscount(yearly_val) {
  const DISCOUNT_PERCENTAGE = 10; // Use a constant for maintainability

  const discount_amount = (yearly_val * (DISCOUNT_PERCENTAGE / 100)).toFixed(2);
  const discount_value = (yearly_val - discount_amount).toFixed(2);

  // Update the customer object with the discounted value
  this.cusObj.discval = discount_value;

  // console.log("Discount applied:", discount_value);
},
qoneChecked() {
  this.qOneErrorMessage = "";

  // Ensure property details are available
  if (!this.propDetails || !this.propDetails[0]) {
    this.qOneErrorMessage = "Property details are not available.";
    this.qonecheck = false;
    return;
  }

  // Validate date for the first quarter
  // const today = new Date();
  // // const january31 = new Date(today.getFullYear(), 1, 1); // January 31
  // const dec31 = new Date(today.getFullYear(), 11, 31); // December 31

  // if (today > january31 && today <= dec31) {
    
    this.cusObj.fq = this.propDetails[0].q_one_val || 0;
},

qtwoChecked() {
  this.qtwoErrorMessage = "";

  if (!this.propDetails || !this.propDetails[0]) {
    this.qtwoErrorMessage = "Property details are not available.";
    this.qtwocheck = false;
    return;
  }

  // Assign initial quarter payment directly
  this.cusObj.sq = this.propDetails[0].q_two_val;
},
qthreeChecked(){
  this.qthreeErrorMessage=""

  if (!this.propDetails || !this.propDetails[0]) {
    this.qthreeErrorMessage = "Property details are not available.";
    this.qthrecheck = false;
    return;
  }
  this.cusObj.tq = this.propDetails[0].q_three_val
},
qfourChecked(){

  this.qfourerrorMessage=""

  if (!this.propDetails || !this.propDetails[0]) {
    this.qfourerrorMessage = "Property details are not available.";
    this.qfourcheck = false;
    return;
  }
  this.cusObj.foq = this.propDetails[0].q_four_val

},
// calQuarterDiscount(quarterpayment) {
//       const quarter_discount_percentage = 5;
//       const quarter_discount = (quarterpayment * (quarter_discount_percentage / 100)).toFixed(2);
//       const discounted_value = (quarterpayment - quarter_discount).toFixed(2);
//       const qfine="0"
//       return [quarter_discount, discounted_value, qfine];
//     },

// calQuarterDiscount(quarterPayment, currentDate) {
  
//   const march31 = new Date(currentDate.getFullYear(), 2, 31); // March 31
//   // const march31 = new Date(currentDate.getFullYear(), 11, 21); // chnage this to, 2,31
//   // const june30 = new Date(currentDate.getFullYear(), 5, 30); // June 30
//   const june30 = new Date(currentDate.getFullYear(), 11, 21); // testing for June 30 change this
//   const sept30 = new Date(currentDate.getFullYear(), 8, 30); 
//   const dec31 = new Date(currentDate.getFullYear(), 11, 31);

//   if (currentDate <= march31) {
//     const discountRate = 5; // Discount for Jan-Mar
//     const discount = (quarterPayment * (discountRate / 100)).toFixed(2);
//     const discountedValue = (quarterPayment - discount);
//     const fine = "0";
//     return [discount, discountedValue, fine];
//   } else if (currentDate > march31 && currentDate <= june30) {
//     // const discountRate = 5; // Discount for Apr-Jun
//     // const discount = (quarterPayment * (discountRate / 100)).toFixed(2);
//     const warrantRate = this.propDetails[0].warrant_cost_rate || 0;
 
//     const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
//     const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
//     const discount = "0";
//     return [discount, paymentWithFine, fine];
//   } else if(currentDate > june30 && currentDate <= sept30){
//     const warrantRate = this.propDetails[0].warrant_cost_rate || 0;
 
//     const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
//     const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
//     const discount = "0";
//     return [discount, paymentWithFine, fine];
//   }else if(currentDate > sept30 && currentDate <= dec31){
//     const warrantRate = this.propDetails[0].warrant_cost_rate || 0;
 
//     const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
//     const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
//     const discount = "0";
//     return [discount, paymentWithFine, fine];
//   }
//   else {
//     // const discount = "0";
//     // const discountedValue = quarterPayment;
//     // const fine = "0";
//     // return [discount, discountedValue, fine];
//     console.log("error")
//   }
// },
calQuarterDiscount(quarterPayment, currentDate) {
  const march31 = new Date(currentDate.getFullYear(), 2, 31); // March 31
  const june30 = new Date(currentDate.getFullYear(), 5, 30); // June 30
  const sept30 = new Date(currentDate.getFullYear(), 8, 30); // Sept 30
  const dec31 = new Date(currentDate.getFullYear(), 11, 31); // Dec 31

  const warrantRate = this.propDetails?.[0]?.warrant_cost_rate || 0;
  const strippedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

  if (strippedCurrentDate <= march31) {
    const discountRate = 5; // Discount for Jan-Mar
    const discount = (quarterPayment * (discountRate / 100)).toFixed(2);
    const discountedValue = (quarterPayment - discount).toFixed(2);
    const fine = "0";
    return [discount, discountedValue, fine];
  } else if (strippedCurrentDate > march31 && strippedCurrentDate <= june30) {
    const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
    const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
    const discount = "0";
    return [discount, paymentWithFine, fine];
  } else if (strippedCurrentDate > june30 && strippedCurrentDate <= sept30) {
    const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
    const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
    const discount = "0";
    return [discount, paymentWithFine, fine];
  } else if (strippedCurrentDate > sept30 && strippedCurrentDate <= dec31) {
    const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
    const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
    const discount = "0";
    return [discount, paymentWithFine, fine];
  } else {
    // Handle invalid dates if necessary
    return ["0", quarterPayment, "0"];
  }
},
calSecondQuarterValues(quarterPayment, currentDate) {
  const june30 = new Date(currentDate.getFullYear(), 5, 30); // June 30
  const july1 = new Date(currentDate.getFullYear(), 6, 1);   // July 1
  const dec31 = new Date(currentDate.getFullYear(), 11, 31); // Dec 31

  const warrantRate = this.propDetails?.[0]?.warrant_cost_rate || 0;

  // Strip time from currentDate for accurate comparison
  const strippedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

  if (strippedCurrentDate <= june30) { // Discount applies up to and including June 30
    const discountRate = 5; // Discount for Jan-Mar
    const discount = (quarterPayment * (discountRate / 100)).toFixed(2);
    const discountedValue = (quarterPayment - discount).toFixed(2);
    const fine = "0"; // No fine
    return [discount, discountedValue, fine];
  } else if (strippedCurrentDate >= july1 && strippedCurrentDate <= dec31) { // Fine applies from July 1 onward
    const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
    const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
    const discount = "0"; // No discount
    return [discount, paymentWithFine, fine];
  } else {
    // Handle invalid dates if necessary
    return ["0", quarterPayment.toFixed(2), "0"];
  }
},

calThirdQuarterValues(quarterPayment, currentDate){

  const sept30 = new Date(currentDate.getFullYear(), 8, 30); // Sept 30
  const dec31 = new Date(currentDate.getFullYear(), 11, 31); // Dec 31
  const oct1 = new Date(currentDate.getFullYear(), 9, 1); // oct1

  const warrantRate = this.propDetails?.[0]?.warrant_cost_rate || 0;
  const strippedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

  if (strippedCurrentDate <= sept30) {
    const discountRate = 5; // Discount for Jan-Mar
    const discount = (quarterPayment * (discountRate / 100)).toFixed(2);
    const discountedValue = (quarterPayment - discount).toFixed(2);
    const fine = "0";
    return [discount, discountedValue, fine];
  } else if (strippedCurrentDate >= oct1 && strippedCurrentDate <= dec31) {
    const fine = (quarterPayment * (warrantRate / 100)).toFixed(2);
    const paymentWithFine = (parseFloat(quarterPayment) + parseFloat(fine)).toFixed(2);
    const discount = "0";
    return [discount, paymentWithFine, fine];
  }else {
    // Handle invalid dates if necessary
    return ["0", quarterPayment.toFixed(2), "0"];
  }
},
calFourthQuarterValues(quarterPayment, currentDate){
  const dec31 = new Date(currentDate.getFullYear(), 11, 31); // Dec 31
  const strippedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());


  if (strippedCurrentDate <= dec31) {
    const discountRate = 5; // Discount for Jan-Mar
    const discount = (quarterPayment * (discountRate / 100)).toFixed(2);
    const discountedValue = (quarterPayment - discount).toFixed(2);
    const fine = "0";
    return [discount, discountedValue, fine];
  } else {
    // Handle invalid dates if necessary
    return ["0", quarterPayment.toFixed(2), "0"];
  }
},

resetCheckErr2: function () {
        this.cusErrObj.descrErr =[];
       },
       checkEmptyErr2: function () {
           for (var typeErr in this.cusErrObj) {
               if (this.cusErrObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm2: function () {
           this.resetCheckErr2();
           if (!this.CatObj.ward) {
               this.cusErrObj.descrErr.push("required");
           }
       },

   },
   components: {
        VueBasicAlert
    }
};

</script>

<style scoped>
.relief-row {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds spacing between items */
}

.relief-row input[type="text"] {
  width: auto;
  /* box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05); */
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 0.6rem;
   border-radius: 0.5rem;
   background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.3rem;
    color: #130f40;
}

.relief-row input[type="button"] {
  width: auto;
  background-color: #022e2a;
  color:#fff;
  padding: 8px;
  border-radius: 8px;
  
}
.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
     text-align: center;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      padding: 2%;
  }
  th  {
     text-align: center;
  }
  .ttable, th, td {
  border: 1px solid;
  }
.row1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .close-btn {
  /* align-items: right; */
  background-color:rgb(121, 23, 12);
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #f8f4f4;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 8px;
  border-radius: 8px;
  width:6%;
  height: 4%;
  text-align: center;
}

.close-btn:hover {
  color: rgb(220, 18, 18);
  transform: scale(1.2);
  background-color:  #022e2a; 
}

.close-btn:focus {
  outline:none; /* Remove default focus outline */
}
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}
/* shroff form container */
.Shop-container {
   background-color: #ffffff09;
   /* height: 90vh; */
   padding: 1rem 2%;
   font-size: 16px;
}

/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   /* margin: 1rem 0; */
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
    height: 3rem;
    color: #fffbfb;
  
   
}
/* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
} */
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.3rem;
   color: rgb(243, 47, 47);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
   font-size: 1.2rem;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
/* second form */
.register-container {
    padding-top: 1.0rem;
    font-size: 16px;
     /* min-height: 72.3vh; */
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 98rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.3rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}

.register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;

    width: 10%;
    border: none;
    margin-left: 10%;
}
.register-container .register-form-container form label {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #022e2a;
}

</style>