<template>
    <div id="app">
        <div v-if="!user">
            <router-view></router-view>
        </div>
        <div v-else>
            <!-- <NavBar />
            <SideBar/> -->
            <MenuBar />
            <div class="auth-wrapper">
                <div class="auth-inner">
                    <router-view></router-view>
                </div>
            </div>
            <FooterComponent />
        </div>
    </div>
</template>

<script>
// import NavBar from './components/NavBar.vue';
// import SideBar from './components/SideBar.vue';
import MenuBar from './components/MenuBar.vue';
import FooterComponent from './components/FooterComponent.vue';
// import { mapActions } from 'vuex';
import { mapState } from 'vuex';
export default {
    name: 'App',
    components: {
        // NavBar,
        // SideBar,
        MenuBar,
        FooterComponent
    },


    computed: {
      
        ...mapState(["user"])
    },

    
}
</script>

<style>
@import "./assets/css/global_style.css";
.auth-wrapper {
  padding-left: 200px; /* Adjust based on your sidebar width */
}
</style>


