<template>
    <div class="admin-container" style="text-align: center;">

        <div class="home-main">
        
        <div class="content">
            <span >welcome! | ආයුබෝවන්! {{user.emp_name}} </span>
           
           <br>
           <h3>Financial Progress by Province - {{ new Date().getFullYear() }}</h3>
        </div>

    </div>
    <div class="flex-container">
            <div class="flex-item" style="background-color:darkblue">
                <div class="flex-item-topic">Western | බස්නාහිර</div>
                <div class="flex-item-count">Sabha : 49  &nbsp;&nbsp;|&nbsp;&nbsp;Users :</div>
                <div class="flex-item-count1">Total Income : Rs.{{$formatNumber(provinceObj.western)}}</div>
                <button @click="showwestern()"> More Deatils</button>
            </div>
           
            <div class="flex-item" style="background-color:#185c08" >
                <div class="flex-item-topic">Central | මධ්‍යම </div>
                <div class="flex-item-count">Sabha : 47  &nbsp;&nbsp;|&nbsp;&nbsp;Users : 40</div>
                <div class="flex-item-count1">Total Income : Rs.{{$formatNumber(provinceObj.central)}}</div>
                <button @click="showCentral()"> More Deatils</button>
            </div>
             
            <div class="flex-item" style="background-color:#f2113a">
                <div class="flex-item-topic">Southern | දකුණ</div>
                <div class="flex-item-count">Sabha : 49  &nbsp;&nbsp;|&nbsp;&nbsp;Users : 0</div>
                <div class="flex-item-count1">Total Income : Rs.{{ $formatNumber(provinceObj.southern )}}</div>
                <button @click="showSouthern()"> More Deatils</button>
            </div>
        </div>
        
        <div class="flex-container">
            <div class="flex-item" style="background-color:#420937" >
                <div class="flex-item-topic">Nothern | උතුර</div>
                <div class="flex-item-count">Sabha : 34  &nbsp;&nbsp;|&nbsp;&nbsp;Users : 0</div>
                <div class="flex-item-count">Total Income : Rs.{{ $formatNumber(provinceObj.nothern) }}</div>
                <button @click="showNorth()"> More Deatils</button>
            </div>
            <div class="flex-item" style="background-color:#ff0505">
                <div class="flex-item-topic">Eastern | නැගෙනහිර</div>
                <div class="flex-item-count">Sabha : 45  &nbsp;&nbsp;|&nbsp;&nbsp;Users : 19</div>
                <div class="flex-item-count1">Total Income : Rs.{{ $formatNumber(provinceObj.eastern) }}</div>
                <button @click="showeastern()"> More Deatils</button>
            </div>
            <div class="flex-item" style="background-color:#990b0b">
                <div class="flex-item-topic">North Western | වයඹ</div>
                <div class="flex-item-count">Sabha : 33  &nbsp;&nbsp;|&nbsp;&nbsp;Users : 0</div>
                <div class="flex-item-count1">Total Income : Rs.{{ $formatNumber(provinceObj.northwestern) }}</div>
                <button @click="showNWest()"> More Deatils</button>
            </div>
        </div>
        <div class="flex-container">
            <div class="flex-item" style="background-color:#990b57">
                <div class="flex-item-topic">North Central | උතුරු මැද</div>
                <div class="flex-item-count">Sabha : 27  &nbsp;&nbsp;|&nbsp;&nbsp;Users : 0</div>
                <div class="flex-item-count1">Total Income : Rs.{{ $formatNumber(provinceObj.northcentral) }}</div>
                <button @click="showNcen()"> More Deatils</button>
            </div>
            <div class="flex-item" style="background-color:#0fa611">
                <div class="flex-item-topic">Uva | ඌව</div>
                <div class="flex-item-count">Sabha : 28  &nbsp;&nbsp;|&nbsp;&nbsp;Users : 0</div>
                <div class="flex-item-count1">Total Income : Rs.{{ $formatNumber(provinceObj.uva)}}</div>
                <button @click="showUva()"> More Deatils</button>
            </div>
            <div class="flex-item" style="background-color:#008080">
                <div class="flex-item-topic">Sabaragamuwa | සබරගමුව</div>
                <div class="flex-item-count">Sabha : 29  &nbsp;&nbsp;|&nbsp;&nbsp;Users : 28</div>
                <div class="flex-item-count1">Total Income : Rs.{{ $formatNumber(provinceObj.sabaragamuwa) }}</div>
                <button @click="showSabara()"> More Deatils</button>
            </div>
        </div>
    </div>
  
</template>


<script>
import axios from 'axios';
import { mapState, mapMutations } from "vuex";
export default {
    name: 'MinistryDashboard',

    
    data() {
        return {
           provinceObj:{western:0,central:0,southern:0,nothern:0,eastern:0,northwestern:0,northcentral:0,uva:0,sabaragamuwa:0,},
          
           
        }
    },
    computed: {
        ...mapState(["user"]),
       
    },
    created() {
        this.getProvinceIncome()

    },


    methods: {
        ...mapMutations(["setUser"]),
       

        async getProvinceIncome(){
            let centraldata = (await axios.get('/proincome/PRO2')).data;
            this.provinceObj.central = centraldata[0].invoice_count;

            let westerndata = (await axios.get('/proincome/PRO1')).data;
            this.provinceObj.western = westerndata[0].invoice_count;

            let southerndata = (await axios.get('/proincome/PRO3')).data;
            this.provinceObj.southern = southerndata[0].invoice_count;

            let notherndata = (await axios.get('/proincome/PRO4')).data;
            this.provinceObj.nothern = notherndata[0].invoice_count;

            let easterndata = (await axios.get('/proincome/PRO5')).data;
            this.provinceObj.eastern = easterndata[0].invoice_count;

            let northwesterndata = (await axios.get('/proincome/PRO6')).data;
            this.provinceObj.northwestern = northwesterndata[0].invoice_count;

            let northcentraldata = (await axios.get('/proincome/PRO7')).data;
            this.provinceObj.northcentral = northcentraldata[0].invoice_count;

            let uvadata = (await axios.get('/proincome/PRO8')).data;
            this.provinceObj.uva = uvadata[0].invoice_count;

            let sabaragamuwadata = (await axios.get('/proincome/PRO9')).data;
            this.provinceObj.sabaragamuwa = sabaragamuwadata[0].invoice_count;

            
        },
        async showCentral(){
            this.$router.push({ name:'DetaildView',params:{data:'PRO2',data2:'Central Province | මධ්‍යම'}} );
            // this.$router.push('/detaildview' );
        },
        async showwestern(){
            this.$router.push({ name:'DetaildView',params:{data:'PRO1',data2:'Western Province | බස්නාහිර'}} );
            // this.$router.push('/detaildview' );
        },
        async showSouthern(){
            this.$router.push({ name:'DetaildView',params:{data:'PRO3',data2:'Southern Province | දකුණ'}} );
            // this.$router.push('/detaildview' );
        },
        async showNorth(){
            this.$router.push({ name:'DetaildView',params:{data:'PRO4',data2:'Nothern Province | උතුර'}} );
            // this.$router.push('/detaildview' );
        },
        async showeastern(){
            this.$router.push({ name:'DetaildView',params:{data:'PRO5',data2:'Eastern Province | නැගෙනහිර'}} );
            // this.$router.push('/detaildview' );
        },
        async showNWest(){
            this.$router.push({ name:'DetaildView',params:{data:'PRO6',data2:'North Western Province | වයඹ'}} );
            // this.$router.push('/detaildview' );
        },
        async showNcen(){
            this.$router.push({ name:'DetaildView',params:{data:'PRO7',data2:'North Central Province | උතුරු මැද'}} );
            // this.$router.push('/detaildview' );
        },
        async showUva(){
            this.$router.push({ name:'DetaildView',params:{data:'PRO8',data2:'Uva Province | ඌව'}} );
            // this.$router.push('/detaildview' );
        },
        async showSabara(){
            this.$router.push({ name:'DetaildView',params:{data:'PRO9',data2:'Sabaragamuwa Province | සබරගමුව'}} );
            // this.$router.push('/detaildview' );
        }
        

         
    }

}
</script>

<style scoped>
.admin-container {
    background-color: #fff;
    padding: 2rem 9%;
    font-size: 16px;
    /* min-height: 90vh; */
}

.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    /* margin-top: 20px; */
}

.flex-item {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px;
    flex: 1;
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-item:not(:last-child) {
    margin-right: 20px;
}

.flex-item-topic {
    font-size: 1.2rem;
    font-weight: bold;
    color: #dcd7d7;
    margin-bottom: 10px;
}

.flex-item-count {
    font-size: 1.5rem;
    color: #e7e5e5;
    
}
.flex-item-count1 {
    font-size: 1.3rem;
    color: #fbf8f8;
    
}

.flex-item:not(:last-child) {
    margin-right: 20px;
}

.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #022e2a;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color:  #022e2a;
    
}
/* home.vue's all styles below */
.home-main,
.home-about,
.home-banner,
.home-category {
    padding: 2rem 9%;
}

.home-main {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    gap: 2rem;
    position: relative;
    overflow: hidden;
    
}

.home-main .content {
    flex: 1 1 30rem;
    /* padding-bottom: 2.5rem; */
    /* padding-top: 2.5rem; */
    /* min-height: 90vh; */
}

.home-main .content span {
    font-size: 1.5rem;
    color: #022e2a;
}

.home-main .content h3 {
    font-size: 2rem;
    color: #130f40;
    padding-top: .5rem;
}

.home-main .content p {
    font-size: 1.6rem;
    color: #666;
    line-height: 2;
    padding: 1rem 0;
}

.home-main .image {
    flex: 1 1 41rem;
    margin: 2rem 0;
    pointer-events: none;
}

.home-main .image .home-img {
    width: 100%;
    margin-top: 5rem;
}

.home-main .home-parallax-img {
    position: absolute;
    top: -15rem;
    right: -15rem;
    width: 80vw;

}


/* home category */



/* home banner */

.home-banner .row-banner .content {
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translateY(-50%);
}

.home-banner .row-banner .content span {
    font-family: 'Satisfy', cursive;
    font-size: 2rem;
    color: #022e2a;
    color: #130f40;
}

.home-banner .row-banner .content h3 {
    font-size: 6rem;
    color: red;
    text-transform: uppercase;
}

.home-banner .row-banner .content p {
    font-size: 2rem;
    padding-bottom: 1rem;
}


.home-banner .grid-banner .grid {
    border-radius: 1rem;
    overflow: hidden;
    height: 20rem;
    
}

.home-banner .grid-banner .grid:hover img {
    transform: scale(1.2);
}

.home-banner .grid-banner .grid img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.home-banner .grid-banner .grid .content {
    position: absolute;
    top: 2rem;
    padding: 0 2rem;
}


.btn {
    background-color: #022e2a;   
    width: 20rem;
}

.home-banner .grid-banner .grid .content.center {
    text-align: center;
    width: 100%;
}

.home-banner .grid-banner .grid .content.center span {
    color: #ca9206;
}

.home-banner .grid-banner .grid .content.center h3 {
    color: #130f40;
}

.home-banner .grid-banner .grid .content span {
    font-size: 1.5rem;
    color: #fff;
}

.home-banner .grid-banner .grid .content h3 {
    font-size: 1rem;
    color: #fff;
    padding-top: .5rem;
}

.home-about {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    background: #f7f7f7;
}

.home-about .image {
    flex: 1 1 40rem;
}

.home-about .image img {
    width: 100%;
}

.home-about .content {

    flex: 1 1 40rem;
}

.home-about .content span {
    font-family: 'Satisfy', cursive;
    font-size: 3rem;
    color: #022e2a;
}

.home-about .content .title {
    font-size: 3rem;
    padding-top: .5rem;
    color: #130f40;
}

.home-about .content p {
    padding: 1rem 0;
    line-height: 2;
    font-size: 1.4rem;
    color: #666;
}

.home-about .content .icons-container {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.home-about .content .icons-container .icons {
    flex: 1 1 20rem;
    border-radius: .5rem;
    background: #fff;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}

.home-about .content .icons-container .icons h3 {
    font-size: 1.7rem;
    color: #130f40;
}

@media (max-width: 768px) {
    #menu-btn {
        display: inline-block;
    }

    .home-main .home-parallax-img {
        top: 0;
        right: 0;
        width: 100%;
    }

    .home-banner .grid-banner .content h3 {
        font-size: 15px !important;
    }

    .home-banner .grid-banner .content.center {
        padding-left: 0px !important;
    }

}

@media (max-width: 576px) {
    .home-main .content h3 {
        font-size: 1rem;
    }

    .home-main .content p {
        font-size: 1.5rem;
    }
}
</style>