<template>
    <div class="ground-container"> 
        <div class="ground-form-container" >
            <form id="gullyapplication" novalidate autocomplete="off" >
                <div id="Printcontent">
                <u><h3 style="text-align: center;">Crematoria/Cemetry Booking Application </h3></u>
                <br>
                <label for="iName" style="font-size: small;">Applicant Name:&nbsp; {{ cus_name }}
                        </label> <br> 
                        <label for="iContact" style="font-size: small;">Phone Number:&nbsp; {{ cus_tel }}
                        </label><br>
                        <label for="iAddr" style="font-size: small;">Address:&nbsp; {{ cus_add }}
                        </label> <br>
                        <label for="inic" style="font-size: small;">NIC Number of decesed:&nbsp; 
                        </label> <br>
                        <label for="iName" style="font-size: small;">Name of decesed:&nbsp;
                        </label><br>
                        <label for="iAge" style="font-size: small;">Age:&nbsp;
                        </label><br>
                        <label for="iGen" style="font-size: small;">Gender:&nbsp;
                        </label><br>
                        <label for="iDdate" style="font-size: small;">Date of Death:&nbsp;
                        </label><br>
                        <label for="iPlace" style="font-size: small;">Place of Deth:&nbsp;
                        </label><br>
                        <label for="idno" style="font-size: small;">Death Certificate Number:&nbsp;
                        </label><br>
                        <label for="iDate" style="font-size: small;">Date of Cremation/burial:&nbsp;{{formattedDate(st_date)}}
                        </label><br>
                        <label for="iTime" style="font-size: small;">Time of Cremation/burial:&nbsp;{{st_time}}
                        </label><br>
                        <label for="iTimeto" style="font-size: small;">Whether a monument is build is not:&nbsp;{{ end_time }}
                        </label><br>
                        <h5></h5>
    <table class="nobordertbl">
                        <tr>
                            <td style="text-align:left;width: 70%;">
                                Date: {{ new Date().toLocaleString() }}
                            </td>
                            <td style="text-align:left;width: 30%;">
                              .............................................................. 
                            </td>
                        </tr>
                        <tr>
                        <td style="text-align:left;width: 70%;">
                            
                            </td>
                            <td style="text-align:left;width: 30%;">
                               Signature of Applicant/ Gurdian
                            </td>
                        </tr>
                        </table>
<h5>For Office use Only</h5>
    <hr>
                    <table class="nobordertbl">
                        <tr>
                            <td style="text-align:left">
                                Please provide an assesment tax report of the .......................................
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:left;width: 70%;">
                               
                            </td>
                            <td style="text-align:center">
                                .......................................<br>Management Assistant(Health Section)
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:left">
                                Has registerd in assesment tax document as......................................................................./ Not registered in assesment tax document.
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:left;width: 70%;">
                               
                            </td>
                            <td style="text-align:center">
                                .......................................<br>Management Assistant(Assesment tax Section)
                            </td>
                        </tr>
                        <tr >
                            <td style="text-align:left;width: 70%;">Please report after charging .....................Rupees</td>
                       
                            <td style="text-align:left;width: 30%;">
                                .......................................<br>Management Assistant(Health Section)</td>
                        </tr>
                        <tr >
                            <td style="text-align:left;width: 70%;">Receipt Number & Date</td>
                       
                            <td style="text-align:left;width: 30%;">
                                .......................................<br>Management Assistant(Accounts Section)</td>
                        </tr>
                        
    </table>
    <br>
                    <h5 style="text-align: right;">Commisioner/Mediacl officer of Health/Secretary</h5>
                    <table  style="width: 100%; border: none;" >
                        <tr>
                            <td style="text-align:left;" colspan="2">
                                Above details are correct. Kindly Submit for approval of booking crematoria/cemetry on ................................. at ..................................
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:left;width: 70%;">
                               Date:
                            </td>
                            <td style="text-align:left;width: 30%;">
                                .......................................<br>Management Assistant
                            </td>
                        </tr>
                        </table>
                        <h5 style="text-align: left">Approval</h5>
                    <table class="nobordertbl">
                        <tr>
                            <td style="text-align:left;width: 100%;">
                                Approved to cremation/burial on .......................................... at .......................... for a fee of Rs. .................... at ....................................... crematoria/cemetry
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:left;width: 50%;">
                               Date:
                            </td>
                            <td style="text-align:center">
                                .......................................<br>Secretary/MOH
                            </td>
                        </tr>
                        </table>
                </div>
            </form>
       
        </div>
        <br>
        <div class="btnrow">
                <div style="padding-left: 25rem;">
                    <router-link to="/bkgully" class="btn" style="align-content: center;">Cancel</router-link>&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="button" value="Print" class=" btn" style="align-content: center;" @click="printContent">
                </div>
            </div>
    </div>
    
</template>
<script>

    import axios from 'axios';
    export default {
        name: "ApplicationCrematoria",
    
       data (){
    
        return{
                nic:"",
                sabha: "",
                userLevel:"",
                // invoicesavedetails:[],
                invoicedetails:[],
                searchinvoice:'',
                sabhadetail:[],
                matchUser:'',
                bid:'',
                cus_name:"",
                cus_add:"",
                cus_tel:"",
                sport_:"",
                st_date:"",
                end_date:"",
                st_time:"",
                end_time:"",
                no_chairs:"",
                no_host:"",
                is_paid:"",
        }
       },
    
       created(){
        this.getDataFromSessionStorage()
       
       },
       computed : {
               
                
            },
    
       methods:{
       
        async getDataFromSessionStorage(){
                const data = JSON.parse(sessionStorage.getItem('userData'))
                if(data){
                                this.nic = data.nic 
                                this.sabha = data.sabha
                                this.userLevel =data.userLevel
                                // this.userName = data.userName
                            }
    
                this.searchinvoice = this.$route.params.data
                 this.sabhadetail= (await axios.get('/pra_sabha/' +this.sabha)).data
                 //get invoice save table data
                //  this.invoicesavedetails= (await axios.get('/pra_sabha/' +this.sabha)).data
                 this.invoicedetails = (await axios.get('/invoicesaved/' +this.sabha+'/'+this.searchinvoice)).data
                this.getMatchUser(this.nic)
               
               const bookingData = JSON.parse(sessionStorage.getItem('bookingData'))
                if(bookingData){
                                this.cus_name = bookingData.req_name 
                                this.cus_add = bookingData.req_address
                                this.cus_tel =bookingData.tel
                                this.bid=bookingData.reqid
                                this.sport_=bookingData.req_reason
                                this.st_date =bookingData.req_date_from
                                this.end_date=bookingData.req_date_to
                                this.st_time=bookingData.req_start_time
                                this.end_time=bookingData.req_end_time
                                this.no_chairs=bookingData.req_seats
                                this.no_host=bookingData.req_guests
                                this.is_paid=bookingData.req_get_payments
                                // this.userName = data.userName
                            }
             
            },
            async getMatchUser(nic) {
                // await axios.get('/employee/' + nic);
                let data = await axios.get('/employee/' + nic);
                this.matchUser = data.data;
            },
            formattedDate(bkdate) {
                const date = new Date(bkdate)
            const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
          },
            async printContent() {
                
                let updateprocess={
                   payment_status : "processing"
               }
               await axios.put("/gullyrequestupda/"+this.bid,updateprocess, )
       
               const prtHtml = document.getElementById('Printcontent').innerHTML;
               const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                WinPrint.document.write(`    
        ${prtHtml}
    `);
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
                   this.$router.push('/bkgully');
               }
        },
    
    
            
    
    };
    </script>
    <style>
    .nobordertbl{
        border-collapse: collapse;
        border: none;
        font-size: 1.2rem;
        width:100%;
    }
    .nobordertbl td{
        border: none;
    }
    .ttable {
         
         width: 100%;
         border-width:1px;
         border-color : #130f40;
         /* border-style: solid; */
         font-size: 0.8rem;
        text-align: right;
         /* background-color :white; */
         /* padding-left: 3rem; */
          /* height: 3rem; */
          border-collapse: collapse;
          
     }
     th  {
         text-align: center;
    }
    .ttable, th, td {
    border: 1px solid;
    /* padding-right: 0.7rem; */
    }
    .nobordertbl{
        border-collapse: collapse;
        border: none;
    }
    .nobordertbl td{
        border: none;
    }
    .ground-container {
        background-color: #fff;
        /* height: 100vh; */
    /* form width controls from padding 20% */
        padding: 2rem 20%; 
        font-size: 16px;
        align-content: center;
        /* min-height: 72.3vh; */
    }
    .ground-container .ground-form-container {
        background: #fff;
    
    }
    
    .ground-container .ground-form-container .heading{
       padding: 0%;
    
    }
    
    .ground-container .ground-form-container form {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%);
        max-width: 100rem;
        width: 100%;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
        border: 0.1rem solid rgba(0, 0, 0, 0.2);
        padding: 2rem;
        border-radius: 0.5rem;
        animation: fadeUp 0.4s linear;
        padding-left: 2rem;
    }
    .h4 {
        /* padding-bottom: 1rem; */
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #130f40;
        margin: 0;
    }
    .ground-container .ground-form-container form h3 {
        /* padding-bottom: 1rem; */
        font-size: 1.3rem;
        text-transform: uppercase;
        color: #130f40;
        margin: 0;
    }
    .ground-container .ground-form-container form .table {
        /* margin: 1rem 0; */
        width: 100%;
        /* border-style: solid; */
        font-size: 1.2rem;
        /* background-color : #e9e4e9; */
        padding-left: 2rem;
         /* height: 3rem; */
         border-color : #130f40;
         /* border-width:2px; */
         border-top: 0.5px solid;
         border-left: 0.5px solid;
         border-right: 0.5px solid;
         border-bottom: 0.5px solid;
    }
    .ground-container .ground-form-container form .table .td {
        /* margin: 1rem 0; */
        width: 40%;
        vertical-align: middle;
        border: 0.5px solid;
        border-bottom: 0.5px solid;
    }
    .ground-container .ground-form-container .form-group {
        margin:0;
        font-size: 1.3rem;
        padding-top: 0%;
        padding-bottom: 0%;
    } 
    .ground-container .ground-form-container .form-group1 {
        margin:0;
        font-size: 1.2rem;
    } 
    </style>