import axios from "axios";

window.axios = axios
axios.defaults.withCredentials = false
// axios.defaults.baseURL = "http://localhost:80/api"
//  let backendUrl = "http://" + window.location.hostname.toString() + ":4000/api"
// let backendUrl = "https://apps.matale.dist.gov.lk/api"
// let backendUrl = "https://elgservices.lk/api"
let backendUrl = "https://elgservices.lk/acc" // newacc 

axios.defaults.baseURL = backendUrl


