
<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <section>
    <div class="register-container">
        <div class="row1">
           <router-link to="/asesdash" class="close-btn" > Close </router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h1>Manage Properties</h1>
       </div>
        <div class="register-form-container">
            <form id="ShopDetailForm" novalidate autocomplete="off" >
                <div class="heading">
            <h3><b>Add Properties</b></h3>
            </div>
            <div class="row">
            <div class="form-group col-sm-4">
                   <label for="ward"><b>Ward:</b> 
                   </label>
                   <div><select v-model="obj.oward" id="wrd" class="form-control" >
                    <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in savedWards" :key="m.ward_id" id="Hid"  
                            v-bind:value =m.ward_id>
                               <div>{{m.ward }}</div> 
                            </option>
                        </select>
                    <p class="error-mess" v-if="errorObj.wardErr.length > 0">{{ errorObj.wardErr[0] }}</p>
               </div>
            </div>
               <div class="form-group col-sm-4">
                   <label for="street"><b>Street:</b>
                   </label>
                   <div>
                   <select v-model="obj.ostreet" id="street" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                             <option v-for="m in savedStreets" :key="m.street_id" :value="m.street_id">
                               <div>{{m.street_name }} - {{ m.street_code }}</div> 
                            </option>
                        </select>
                    <p class="error-mess" v-if="errorObj.streetErr.length > 0">{{ errorObj.streetErr[0] }}</p>
               </div>
            </div>
               <div class="form-group col-sm-4">
                   <label for="sideo"><b>Side of Street:</b>
                   </label>
                   <div><select v-model="obj.oside" id="sideo" class="form-control">
                       <option  selected disabled>choose</option>
                       <option>Left</option>
                       <option>Right</option>
                   </select>
                </div> 
                    <p class="error-mess" v-if="errorObj.sideErr.length > 0">{{ errorObj.sideErr[0] }}</p>
               </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-4">
                   <label for="asnum"><b>Assessment Number:</b></label>
                    <input type="text" name="oass"  id="assesn" class="form-control" 
                    v-model="obj.oassessnum" />
                    <p class="error-mess" v-if="errorObj.assnumErr.length > 0">{{ errorObj.assnumErr[0] }}</p>
               </div>
               <div class="form-group col-sm-4">
                <label for="usedn"><b>Used Number:</b></label>
                <input type="text" id="usednum" class="form-control" :value="generatedUsedNumber" readonly />
                <p class="error-mess" v-if="errorObj.useErr.length > 0">{{ errorObj.useErr[0] }}</p>
              </div>
               <div class="form-group col-sm-4">
                   <label for="unuse"><b>Unused Number:</b></label>
                   <input type="text" name="unused" id="unu" class="form-control" 
                   v-model="obj.unusednum" />
                    <!-- <p class="error-mess" v-if="errorObj.unuseErr.length > 0">{{ errorObj.unuseErr[0] }}</p> -->
               </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-4">
                    <label id="cate"><b>Category:</b></label>
                            <select class="form-control" name="ctegory" id="catg" v-model="obj.catgory" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ms in maincate" :key="ms.cat_id" :value="ms.cat_id">{{ ms.category_name}} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.catErr.length > 0">{{ errorObj.catErr[0] }}</p>      
                </div>
                <div class="form-group col-sm-4">
                    <label id="subcat"><b>Sub Category:</b></label>
                            <select class="form-control"  name="subca" id="subcatg" v-model="obj.subcat" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="s in subCatall" :key="s.sub_id" :value="s.sub_id">{{ s.sub_cat_name}} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.subcatErr.length > 0">{{ errorObj.subcatErr[0] }}</p>         
                </div>
                <div class="form-group col-sm-4">
                    <label for="val"><b>Annual Assessment Value:</b></label>
                    <input type="number" name="value" id="valu" class="form-control"
                        v-model="obj.pvalue" />
                    <p class="error-mess" v-if="errorObj.valueErr.length > 0">{{ errorObj.valueErr[0] }}</p>  
                </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-4">
                <label for="rate"><b>Rate: (%)</b></label>
                <input type="number" name="rate" id="rrate" class="form-control" v-model="obj.prate" />
                <p class="error-mess" v-if="errorObj.rateErr.length > 0">{{ errorObj.rateErr[0] }}</p>
            </div>
            <div class="form-group col-sm-4">
                   <label for="rate"> <b>Warrant cost Rate: (%)</b></label>
                   <input type="number" name="wrate" id="wrate" class="form-control"
                   v-model="obj.pwarrenrate" disabled />
                    <p class="error-mess" v-if="errorObj.warrentErr.length > 0">{{ errorObj.warrentErr[0] }}</p>
              </div>
              <div class="form-group col-sm-4">
                   <label for="states"> <b>Status:</b></label>
                    <select v-model="obj.pstate" id="ostate" class="form-control">
                       <option  selected disabled>choose</option>
                       <option>Used</option>
                       <option>Unused</option>
                   </select>
                    <p class="error-mess" v-if="errorObj.stateErr.length > 0">{{ errorObj.stateErr[0] }}</p>
              </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12">
                   <label for="addr"><b>Property Address:</b></label>
                   <input type="text" name="proadd" id="proadd" class="form-control" 
                   v-model="obj.propaddr" />
                    <p class="error-mess" v-if="errorObj.addErr.length > 0">{{ errorObj.addErr[0] }}</p>
               </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-4">
                   <label for="yrval"><b>Yearly Value:(Annual Tax)</b></label>
                   <input type="text" name="yrval" id="yval" class="form-control" 
                   v-model="obj.yval" />
                    <p class="error-mess" v-if="errorObj.valErr.length > 0">{{ errorObj.valErr[0] }}</p>
               </div>
                <div class="form-group col-sm-4">
                        <label for="q1"><b>First Quarter Payment:</b></label>
                        <input type="number" name="q1p" id="q1pay" class="form-control"
                            v-model="obj.qone" />
                        <p class="error-mess" v-if="errorObj.qoneErr.length > 0">{{ errorObj.qoneErr[0] }}</p>  
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="q2"><b>Second Quarter Payment:</b></label>
                        <input type="number" name="q2p" id="q2pay" class="form-control"
                            v-model="obj.qwto" />
                            <p class="error-mess" v-if="errorObj.qtwoErr.length > 0">{{ errorObj.qtwoErr[0] }}</p>  
                    </div>
                    </div>
            <div class="row">
                <div class="form-group col-sm-4">
                        <label for="q3"><b>Third Quarter Payment:</b></label>
                        <input type="number" name="q3p" id="q3pay" class="form-control"
                            v-model="obj.qthree" />
                        <p class="error-mess" v-if="errorObj.qthreeErr.length > 0">{{ errorObj.qthreeErr[0] }}</p>  
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="q4"><b>Fourth Quarter Payment:</b></label>
                        <input type="number" name="q4p" id="q4pay" class="form-control"
                            v-model="obj.qfour" />
                        <p class="error-mess" v-if="errorObj.qfourErr.length > 0">{{ errorObj.qfourErr[0] }}</p>  
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="q4"><b>Arrears:</b></label>
                        <input type="number" name="arreas" id="arreas" class="form-control"
                            v-model="obj.ares" />
                        <p class="error-mess" v-if="errorObj.aresErr.length > 0">{{ errorObj.aresErr[0] }}</p>  
                    </div>
            </div>
            <!-- <div class="row">
                <div class="form-group col-sm-12">
                    <input type="checkbox" v-model="isChecked"  class="large-checkbox" /> 
                    <label for="para"><b>&nbsp;&nbsp;Owner details not known?</b></label>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="form-group col-sm-3"></div>
                <div class="form-group col-sm-3">
                    <label for="yrval"><b>Ownership Starts at:</b></label>
                    <input type="date" name="yrval" id="yval" class="form-control" 
                    v-model="obj.startd" />
                        <p class="error-mess" v-if="errorObj.startErr.length > 0">{{ errorObj.startErr[0] }}</p>
                </div>
            </div>
            <div v-for="(owner, index) in owners" :key="index" class="row">
                <div class="form-group col-sm-3">
                <label for="val"><b>Owner's NIC:</b></label>
                <input type="text"  @input ="showCustomer(owner, index)"   v-model="owner.nic" class="form-control" />
                <p class="error-mess" v-if="errorObj.nicErr[index]?.length > 0">{{ errorObj.nicErr[index][0] }}</p>
            </div>
            <div class="form-group col-sm-3">
                <label for="usedn"><b>Owner's Name:</b></label>
                <input type="text"  v-model="owner.owname" class="form-control" />
                <p class="error-mess" v-if="errorObj.ownameErr[index]?.length > 0">{{ errorObj.ownameErr[index][0] }}</p>
            </div>
            <div class="form-group col-sm-3">
                <label for="states"><b>Address:</b></label>
                <input type="text" v-model="owner.addr" class="form-control" />
                <p class="error-mess" v-if="errorObj.addrErr[index]?.length > 0">{{ errorObj.addrErr[index][0] }}</p>
            </div>
            <div class="form-group col-sm-2">
                <label for="rate"><b>Contact Number:</b></label>
                <input type="text" v-model="owner.contact" class="form-control" />
                <p class="error-mess" v-if="errorObj.contactErr[index]?.length > 0">{{ errorObj.contactErr[index][0] }}</p>
            </div>
            <div class="form-group col-sm-1">
             //   <label for="btnplus" style="color: transparent;"> <b> Add </b></label>
            <input type="button" @click="addNew()" value="+" class="btn2" />
        </div>
        </div> -->
            
            <div class="x">
                <div class="form-group">
                    <input type="button" @click="saveProperty()" value="Save" class="btn" />
                    
                </div>
            </div>
            </form>
        </div>
    </div>
</section>
<div class="Shop-container">
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
            
            <div class="heading">
            <h3><b>Properties of {{ this.sabha }}</b></h3>
            </div>
           <div class="row">
            <table style="width: 98%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
               <thead>
                   <tr>   
                       <th>Index</th>
                       <th>Property ID</th>
                       <th>Assessment Number</th>
                       <th>Value</th>
                       <th>Rate</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">

                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                       <td style="text-align: left;">{{ s.property_id  }}</td>
                       <td style="text-align: left;">{{ s.asses_num  }}</td>
                       <td style="text-align: left;">{{ s.prop_value  }}</td>
                       <td style="text-align: left;">{{  s.prop_rate }}</td>         
                   </tr>
               </tbody>
           </table>
           </div>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
           </form>
       </div>
</div>

    
</template>
<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
// import { ref } from 'vue';
export default {
   
    name: "AssessProperty",
  
    data() {
        return {
            obj:{oward:"",ostreet:"", oside:"",oassessnum:"",catgory:"",subcat:"",unusednum:"",pvalue:"",prate:"",pstate:"",propaddr:"",yval:"",powner:"",lifent:"",mortg:"",PropID:"",qone:"",qwto:"",qthree:"",qfour:"",ares:"0",startd:"",pwarrenrate:""},
            errorObj: { wardErr: [],streetErr: [], sideErr:[], assnumErr: [],catErr: [], subcatErr: [], unuseErr: [], useErr: [],valueErr:[],rateErr:[],stateErr:[],addErr:[],valErr:[],ownerErr:[],lifeErr:[],mortagErr:[],qoneErr:[],qtwoErr:[], qthreeErr:[], qfourErr:[],aresErr:[],propErr: [], ownameErr:[], nicErr:[],contactErr:[],addrErr:[],startErr:[],warrentErr:[]},
            savedWards:[],
            savedStreets:[],
            maincate:[],
            subCatall:[],
            rwcount:"",
            propertyid:"",
            rwcountData:0,
            sabaProp:[],
            itemsPerPage: 50,
            currentPage: 1,
        }
    },

        created() {
            this.getDataFromSessionStorage()
        },
  
        computed : {
            paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.sabaProp)) {
        return this.sabaProp.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.sabaProp.length / this.itemsPerPage);
    },

    //used number
    generatedUsedNumber() {
        if (
            this.obj.oward &&
            this.obj.ostreet &&
            this.obj.oside &&
            this.obj.oassessnum
        ) {
            // Extract the numerical part of the ward (e.g., "05" from "ward 05")
            const wardMatch = this.savedWards.find(
                ward => ward.ward_id === this.obj.oward
            )?.ward.match(/\d+/);
            const wardNumber = wardMatch ? wardMatch[0].padStart(2, '0') : '';

            const streetCode = this.savedStreets.find(
                street => street.street_id === this.obj.ostreet
            )?.street_code || '';
            const side = this.obj.oside === 'Left' ? 'L' : 'R';
            const assessNum = this.obj.oassessnum;

            return `${wardNumber}${streetCode}${side}${assessNum}`;
        }
        return '';
    }

},     
watch: {
        // Watch for changes in the relevant fields
        'obj.oward': 'fetchRateValue',
        'obj.ostreet': 'fetchRateValue',
        'obj.catgory': 'fetchRateValue',
        'obj.pvalue': 'getYearlyValue',
        'obj.prate': 'getYearlyValue',

    },
    methods: {
        async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }

           this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
           this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;
           this.maincate = (await axios.get('/maincat/')).data
           this.subCatall = (await axios.get('/scatbysabha/' +this.sabha)).data
           this.sabaProp = (await axios.get('/props/' +this.sabha)).data
       },
       getWardName(ward_id) {
        const ward = this.savedWards.find(m => m.ward_id === ward_id);
        return ward ? ward.ward : 'Unknown';  // Return 'Unknown' if ward_id is not found
    },
    getStreetName(street_id) {
        const street = this.savedStreets.find(m => m.street_id === street_id);
        return street ? street.street_name : 'Unknown';
    },
    //get rate
    fetchRateValue() {
    const { oward, ostreet, catgory } = this.obj;

    // Ensure all fields are selected before making the API call
    if (oward && ostreet && catgory) {
        // Use axios.get with URL parameters
        axios.get(`/rstefiltered/${this.sabha}/${oward}/${ostreet}/${catgory}`)
            .then(response => {
                if (response.data.length > 0) {
                    this.obj.pwarrenrate = response.data[0].warrant_cost_rate; // Assign rate value
                } else {
                    this.errorObj.warrentErr.push("Warrant cost percentage not found.");
                }
            })
            .catch(error => {
                console.error("Error fetching rate value:", error);
                this.errorObj.warrentErr.push("Unable to fetch rate value.");
            });
    }
    },
    getYearlyValue(){
        const {pvalue, prate} = this.obj;
        if(pvalue && prate){
            this.obj.yval= (pvalue*(parseFloat(prate/100))).toFixed(2) //yearly value
        }
        if(this.obj.yval){
            let oneQuatervalue = parseFloat(this.obj.yval/4)
            let threeQuartersum = parseFloat(oneQuatervalue*3)
            this.obj.qone = oneQuatervalue.toFixed(2)
            this.obj.qwto = oneQuatervalue.toFixed(2)
            this.obj.qthree = oneQuatervalue.toFixed(2)
            this.obj.qfour= (this.obj.yval-threeQuartersum).toFixed(2)
        }
    },
       formattedDate() {
           let date = new Date()
           const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
        const fdate=`${year}-${month}-${day}`;
        const options = { timeZone: 'Asia/Colombo', year: 'numeric', month: '2-digit', day: '2-digit' };
            return fdate.toString('en-US', options);
         },
         nextPage(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        prevPage(event) {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        
       resetCheckErr: function () {
        this.errorObj.wardErr =[];
        this.errorObj.streetErr=[];
        this.errorObj.sideErr=[];
        this.errorObj.assnumErr=[];
        this.errorObj.catErr=[];
        this.errorObj.subcatErr=[];
        // this.errorObj.unuseErr=[];
        // this.errorObj.useErr=[];
        this.errorObj.valueErr=[];
        this.errorObj.rateErr=[];
        this.errorObj.stateErr=[];
        this.errorObj.addErr=[];
        this.errorObj.valErr=[];
        this.errorObj.qoneErr=[];
        this.errorObj.qtwoErr=[];
        this.errorObj.qthreeErr=[];
        this.errorObj.qfourErr=[]
        this.errorObj.aresErr=[]
        // this.errorObj.lifeErr=[];
        // this.errorObj.mortagErr=[];

       },
       checkEmptyErr: function () {
           for (var typeErr in this.errorObj) {
               if (this.errorObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           if (!this.obj.oward) {
               this.errorObj.wardErr.push("required");
           }
           if (!this.obj.ostreet) {
               this.errorObj.streetErr.push("required");
           }
           if (!this.obj.oside) {
               this.errorObj.sideErr.push("required");
           }
           if (!this.obj.oassessnum) {
               this.errorObj.assnumErr.push("required");
           }
           if (!this.obj.catgory) {
               this.errorObj.catErr.push("required");
           }
           if (!this.obj.subcat) {
               this.errorObj.subcatErr.push("required");
           }
        //    if (!this.obj.unusednum) {
        //        this.errorObj.unuseErr.push("required");
        //    }
        //    if (!this.obj.usednum) {
        //        this.errorObj.useErr.push("required");
        //    }
           if (!this.obj.pvalue) {
               this.errorObj.valueErr.push("required");
           }
           if (!this.obj.prate) {
               this.errorObj.rateErr.push("required");
           }
           if (!this.obj.pstate) {
               this.errorObj.stateErr.push("required");
           }
           if (!this.obj.propaddr) {
               this.errorObj.addErr.push("required");
           }
           if (!this.obj.yval) {
               this.errorObj.valErr.push("required");
           }
           if (!this.obj.qone) {
               this.errorObj.qoneErr.push("required");
           }
           if (!this.obj.qwto) {
               this.errorObj.qtwoErr.push("required");
           }
           if (!this.obj.qthree) {
               this.errorObj.qthreeErr.push("required");
           }
           if (!this.obj.qfour) {
               this.errorObj.qfourErr.push("required");
           }
           if (!this.obj.ares) {
               this.errorObj.aresErr.push("if  there is no arrears, enter 0");
           }
        //    if (!this.obj.lifent) {
        //        this.errorObj.lifeErr.push("required");
        //    }if (!this.obj.mortg) {
        //        this.errorObj.mortagErr.push("required");
       },
        //get asses_property table count for one sabha. if count is zero insert the property_id as sabhanum0 (730)
        //else add one to the sabha count 730+1= 731
        async sabhaCount() {
    // Get the row count for the specific sabha from asses_property table
    let response = await axios.get('/rows/' + this.sabha);

    // Access the row count from the response (assuming it is the first object in the array)
    let rwcount = response.data && response.data[0] && response.data[0]["COUNT(*)"] ? parseInt(response.data[0]["COUNT(*)"]) : 0;

    // Extract the numeric part from sabha (default to '000' if extraction fails)
    let sabhaNumber = this.sabha.replace(/\D/g, '');

    // Determine the row number
    let rownumber = (rwcount == 0) ? 0 : rwcount + 1;

    // Return the constructed property ID
    return `${sabhaNumber}${rownumber}`;
},

       async saveProperty(){
        this.checkForm();
            if (!this.checkEmptyErr()) {
                
                return;
            } else {
                let sabhaCountResult = await this.sabhaCount();
                let data = {
                        sb_code:this.sabha,
                        ward_id:this.obj.oward,
                        street_id:this.obj.ostreet,
                        road_side:this.obj.oside,
                        asses_num:this.obj.oassessnum,
                        property_id:sabhaCountResult,
                        cat_id:this.obj.catgory,
                        sub_cat_id: this.obj.subcat,
                        unused_num: this.obj.unusednum,
                        used_num:this.obj.usednum,
                        prop_value:this.obj.pvalue,
                        prop_rate:this.obj.prate,
                        warrant_cost_rate:this.obj.pwarrenrate,
                        use_status:this.obj.pstate,
                        prop_address:this.obj.propaddr,
                        yearly_value:this.obj.yval,
                        q_one_val:this.obj.qone,
                        q_two_val:this.obj.qwto,
                        q_three_val:this.obj.qthree,
                        q_four_val:this.obj.qfour,
                        arrears_val:this.obj.ares,
                        // prop_owner:this.obj.powner,
                        // life_entrust:this.obj.lifent,
                        // mortgage:this.obj.mortg,
                        save_date:this.formattedDate(),
                    }
                    await axios.post("/addpropty/", data)
                    this.$refs.alert.showAlert('success', 'Added Successfully!');
                    this.sabaProp = (await axios.get('/props/' + this.sabha)).data;
                    // this.$refs.alert.showAlert('success', 'Added Successfully !')
                        this.obj.oward="",
                        this.obj.streetErr="",
                        this.obj.oside="",
                        this.obj.oassessnum="",
                        sabhaCountResult="",
                        this.obj.catgory="",
                        this.obj.subcat="",
                        this.obj.unusednum="",
                        this.obj.usednum="",
                        this.obj.pvalue="",
                        this.obj.prate="",
                        this.obj.pwarrenrate="",
                        this.obj.pstate="",
                        this.propaddr="",
                        this.obj.yval="",
                        this.obj.qone="",
                        this.obj.qwto="",
                        this.obj.qthree="",
                        this.obj.qfour="",
                        this.obj.ares="0"
                        // this.obj.powner="",
                        // this.obj.lifent="",
                        // this.obj.mortg=""
            }

       },
    //    resetForm() {
    //     this.obj.startd = '';
    //     this.owners = [{ owname: '', nic: '', contact: '', addr: '' }];
    //     this.resetCheckErr1(); // Clear errors
    // }
      
    },
    components: {
        VueBasicAlert
    }
}

</script>

<style scoped>
.register-container .register-form-container form .btn2 {
    margin: 2rem 0;
    width: 100%;
    text-align: center;
    align-items: center;
    /* background: #022e2a; */
    background-color : #032a2c;
    /* height: 3rem; */
    color: #e9e4e9;
    padding: 0.5rem;
    
}
.large-checkbox {
  /* Increase checkbox size */
  width: 20px;
  height: 20px;
}
.x{
    display: flex;          /* Enable flexbox */
    justify-content: center; /* Center content horizontally */
}

.row1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .close-btn {
  /* align-items: right; */
  background-color:rgb(121, 23, 12);
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #f8f4f4;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 8px;
  border-radius: 8px;
  width:6%;
  height: 4%;
  text-align: center;
}

.close-btn:hover {
  color: rgb(220, 18, 18);
  transform: scale(1.2);
  background-color:  #022e2a; 
}

.close-btn:focus {
  outline:none; /* Remove default focus outline */
}
.register-container {
    padding: 1rem 2%;
     /* min-height: 72.3vh; */
}

.register-container .register-form-container {
    background: #fff;
    width: 100%;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 98rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}

.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 40%;
    border: none;
    padding-bottom: 0.5rem;
}
.register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;

    width: 10%;
    border: none;
    margin-left: 10%;
}
.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #022e2a;

}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 200%; 
    text-align: center;
    align-items: center;
    /* background: #022e2a; */
    background-color : #032a2c;
    /* height: 3rem; */
    color: #e9e4e9;
    
}

.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 90%;

} 
.register-container .register-form-container form .hide {
    display: none;

} 
/* for second container */
.Shop-container {
   background-color: #ffffff09;
   /* height: 50vh; */
   padding: 1rem 2%;
   font-size: 16px;
}

.Shop-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}
/* newly added from income heads */
.Shop-container {
   padding: 1rem 2%;
}
/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #af74a7;
    height: 3rem;
  
   
}
/* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
} */
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: rgb(22, 21, 21);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}

</style>
