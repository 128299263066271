<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="Shop-container">
        <div class="row1">
           <router-link to="/asesdash" class="close-btn" > Close </router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h1>Manage Quater Payments</h1>
       </div>
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
                <div class="heading">
                    <b><h3>Add Quater Payments</h3></b>
                </div>
               
               <div class="row">
                    <div class="col-sm-4">
                        <label for="usedn"> <b>Property ID:</b>
                        </label>
                        <div>
                        <input 
                        v-model="CatObj.propsearch" 
                        id="serchprop" 
                        list="sabaPropList" 
                        class="form-control"
                        placeholder="Choose or type a property ID"
                        />
                        
                        <datalist id="sabaPropList">
                        <option 
                            v-for="p in sabaProp" 
                            :key="p.property_id" 
                            :value="p.property_id">
                        </option>
                        </datalist>
                        <p class="error-mess" v-if="errObj.propErr.length > 0">{{ errObj.propErr[0] }}</p>
                    </div>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="q1"><b>First Quarter Payment:</b></label>
                        <input type="number" name="q1p" id="q1pay" class="form-control"
                            v-model="CatObj.qone" />
                        <p class="error-mess" v-if="errObj.qoneErr.length > 0">{{ errObj.qoneErr[0] }}</p>  
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="q2"><b>Second Quarter Payment:</b></label>
                        <input type="number" name="q2p" id="q2pay" class="form-control"
                            v-model="CatObj.qwto" />
                            <p class="error-mess" v-if="errObj.qtwoErr.length > 0">{{ errObj.qtwoErr[0] }}</p>  
                    </div>
                    
                </div>
                
                <div class="row">
                    <div class="form-group col-sm-4">
                        <label for="q3"><b>Third Quarter Payment:</b></label>
                        <input type="number" name="q3p" id="q3pay" class="form-control"
                            v-model="CatObj.qthree" />
                        <p class="error-mess" v-if="errObj.qthreeErr.length > 0">{{ errObj.qthreeErr[0] }}</p>  
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="q4"><b>Fourth Quarter Payment:</b></label>
                        <input type="number" name="q4p" id="q4pay" class="form-control"
                            v-model="CatObj.qfour" />
                        <p class="error-mess" v-if="errObj.qfourErr.length > 0">{{ errObj.qfourErr[0] }}</p>  
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="q4">

                        </label><div>

                        </div>
                        <input type="button" value="Save" id="Addpay" class=" btn" @click="SaveDetails()" />  
                    </div>
                </div>
              
           <br>
           <div style="width: 100%; text-align: center;">
                <h3>Quarter Payments</h3>
            </div>
           <div class="row">
            <table style="width: 90%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
               <thead>
                   <tr>   
                        <th>Index</th>
                       <th>Property ID</th>
                       <th>First Quarter</th>
                       <th>Second Quarter</th>
                       <th>Third Quarter</th>
                       <th>Fourth Quarter</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">
                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                    <td style="text-align: left;">{{ s.property_id }}</td>
                    <td style="text-align: left; ">{{ s.q_one_pay}}</td>
                       <td style="text-align: left;">{{ s.q_two_pay  }}</td>
                       <td style="text-align: left;">{{ s.q_three_pay  }}</td>
                       <td style="text-align: left;">{{ s.q_four_pay  }}</td>
                       <!-- <td><router-link to="" @click="openPopup(s.sub_id)"  class="fas fa-edit" style="color: darkgreen;"> </router-link></td> -->
                   </tr>
               </tbody>
           </table>
           </div>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
           </form>
       </div>
</div>
<!-- end -->
</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {

    name: "AssessQuarterPay",

    data() {
       return {
           nic:"",
           sabha: "",
           userLevel:"",
           CatObj : { propsearch:"",qone:"",qwto:"",qthree:"",qfour:""},
           errObj:{ propErr:[], qoneErr:[],qtwoErr:[], qthreeErr:[], qfourErr:[]},
           itemsPerPage: 10,
            currentPage: 1,
            sabaProp:[],
            checkexistence:[],
            propdetails:[]
       };
   },
   created() {
       this.getDataFromSessionStorage()

   },
   computed:{
       ...mapState(["user"]),
      
    paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.propdetails)) {
        return this.propdetails.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.propdetails.length / this.itemsPerPage);
    },

   },
   async mounted() {

},
   methods: {
       ...mapMutations(["setUser"]),

       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }
                       this.propdetails = (await axios.get('/qpdetails/' +this.sabha)).data
                    try{
                        this.sabaProp = (await axios.get('/props/' +this.sabha)).data                       
                    } catch (error) {
                        console.error("Error fetching data", error);
                    }
       },

nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  event.preventDefault();
},
prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  event.preventDefault();
},
async checkExist(prop){

this.checkexistence = (await axios.get('/chkqd/'+prop+"/"+this.sabha )).data
},

       resetCheckErr: function () {
        this.errObj.propErr =[];
        this.errObj.qoneErr=[];
        this.errObj.qtwoErr=[];
        this.errObj.qthreeErr=[];
        this.errObj.qfourErr=[]
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           if (!this.CatObj.propsearch) {
               this.errObj.propErr.push("required");
           }
           if (!this.CatObj.qone) {
               this.errObj.qoneErr.push("required");
           }
           if (!this.CatObj.qwto) {
               this.errObj.qtwoErr.push("required");
           }
           if (!this.CatObj.qthree) {
               this.errObj.qthreeErr.push("required");
           }
           if (!this.CatObj.qfour) {
               this.errObj.qfourErr.push("required");
           }

       },
       async SaveDetails(e) {
            this.checkForm();
            if (!this.checkEmptyErr()) {
                e.preventDefault();
            }else{
                await this.checkExist(this.CatObj.propsearch);
                if (this.checkexistence.length>0) { 
                    this.$refs.alert.showAlert("error","Payment details already exists for this property.");
            } else {
                    let data = {
                        property_id:this.CatObj.propsearch,
                        q_one_pay:this.CatObj.qone,
                        q_two_pay:this.CatObj.qwto,
                        q_three_pay:this.CatObj.qthree,
                        q_four_pay:this.CatObj.qfour,
                        sb_code:this.sabha
                    }
                    await axios.post("/saveqamount/", data)
                    this.$refs.alert.showAlert('success', 'Added Successfully !')
                    this.propdetails = (await axios.get('/qpdetails/' +this.sabha)).data
                        this.CatObj.propsearch="",
                        this.CatObj.qone="",
                        this.CatObj.qwto="",
                        this.CatObj.qthree="",
                        this.CatObj.qfour=""
                }
                }
        },
   },
   components: {
        VueBasicAlert
    }
};

</script>

<style scoped>
.row1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .close-btn {
  /* align-items: right; */
  background-color:rgb(121, 23, 12);
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #f8f4f4;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 8px;
  border-radius: 8px;
  width:6%;
  height: 4%;
  text-align: center;
}

.close-btn:hover {
  color: rgb(220, 18, 18);
  transform: scale(1.2);
  background-color:  #022e2a; 
}

.close-btn:focus {
  outline:none; /* Remove default focus outline */
}
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}
/* shroff form container */
.Shop-container {
   background-color: #ffffff09;
   height: 90vh;
   padding: 1rem 2%;
   font-size: 16px;
}

/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   margin: 1.8rem 0;
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
    height: 3rem;
    color: #fffbfb;
    /* padding-top: 3rem; */
  
   
}
/* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
} */
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: rgb(243, 47, 47);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
</style>