<template>
    <div class="admin-container">
        <div class="d-flex justify-content-between">
            <h3>Hello Admin!</h3>
            <!-- <button class="btn" @click="handleLogout()">Logout</button> -->
        </div>
        <div class="heading">
            <span>Office Employees</span>
            <h4><b>කාර්යාලීය නිලධාරීන්</b></h4>
        </div>

        <div class="table-responsive">
            <!-- PROJECT TABLE -->
            <table class="table colored-header datatable project-list" >
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>NIC</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Address</th>
                        <th>email</th>
                        <!-- <th>Status</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" (b,index) in allEmployee" :key="index">
                        <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                        <td>{{ b.emp_nic }}</td>
                        <td>{{ b.emp_name }}</td>
                        <td>{{ b.emp_contact }}</td>
                        <td>{{ b.emp_address }}</td>
                        <td>{{ b.emp_email }}</td>
                        <!-- <td>{{ b.emp_status }}</td> -->
                    </tr>
                </tbody>
            </table>
            <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {

    name: "ViewEmployee",

    data() {
        return {
             allEmployee: [],
           // allEmpsInSabha: [],
           itemsPerPage: 10,
            currentPage: 1,
        }
    },
    computed:{
       ...mapState(["user"]),
    paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.allShops)) {
        return this.allShops.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.allShops.length / this.itemsPerPage);
    },
   },

    created() {
      
        this.getAllUser()
    },



    methods: {
        ...mapMutations(["setUser"]),

        async getAllUser() {
        
             this.allEmployee= (await axios.get('/sabhaemps/' +this.user.emp_prs_code)).data;
          
        },

    }
}
</script>

<style scoped>
.admin-container {
    background-color: #fff;
    /* height: 100vh; */
    padding: 2rem 9%;
    font-size: 16px;
    min-height: 72.3vh;
}
.admin-container span {
    color: #4e0707;
   
}

.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #0da9ef;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color: #27ae60;
}
</style>