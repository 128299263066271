<template>
      <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="admin-container">
        
        <div class="heading">
            <h3><b>Customers of Sabha</b></h3>
        </div>
        <div class="table-responsive">
            <!-- PROJECT TABLE -->
            <table class="ttable" >
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>NIC</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Address</th>
                        <th>Edit</th> 
                     </tr>
                    </thead>
                    <tbody>
                            
                    <tr v-for=" (b,index) in paginatedData" :key="index">

                        <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                        <td>{{ b.cus_nic }}</td>
                        <td>{{ b.cus_name }}</td>
                        <td>{{ b.cus_contact }}</td>
                        <td>{{ b.cus_address }}</td>
                        <td><router-link to="" @click="openPopup(b.id)"  class="fas fa-edit" style="color: darkgreen;"> </router-link></td>
                    </tr>
                </tbody>
            </table>
            <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
        </div>
    </div>
    <!-- popup edit -->
    <div class="modal" v-if="showPopup">
      <div class="modal-content" ref="modalRef">
        <button class="close-x" @click="closePopup"> X</button>
        <div class="modal-header">
          <h1>Edit Customer</h1>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="model-group">
                   <label for="sTaxNic"><b>Customer NIC:</b>
                   </label>
                   <input type="text" name="sTaxNic"  id="sTaxNic" class="form-control" 
                   v-model="popObj.cNic" readonly/>
                  
               </div>
               <div class="model-group">
                   <label for="scusName"><b>Customer Name:</b>
                   </label>
                   <input type="text" name="scusName"  id="scusName" class="form-control" 
                   v-model="popObj.cName"/>
                   <p class="error-mess" v-if="errorObj.nameErr.length > 0">{{ errorObj.nameErr[0] }}</p>
                  </div>  
               </div>
               <div class="row">
               <div class="model-group">
                   <label for="scusCon"><b>Customer Contact:</b>
                   </label>
                   <input type="text" name="scusCon"  id="scusCon" class="form-control" 
                   v-model="popObj.cContact"/>
                   <p class="error-mess" v-if="errorObj.conErr.length > 0">{{ errorObj.conErr[0] }}</p>
               </div>
               <div class="model-group">
                   <label for="scusAddr"><b>Customer Address:</b>
                   </label>
                   <input type="text" name="scusAddr"  id="scusAddr" class="form-control" 
                   v-model="popObj.caddress"/>
                   <p class="error-mess" v-if="errorObj.addrErr.length > 0">{{ errorObj.addrErr[0] }}</p>
               </div>
            </div>
        </div>

        <div class="modal-footer">

          <button class="close-button" @click="closePopup">Close</button>
          <button class="save-button" @click="saveModal">Update</button>
        </div>

      </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import VueBasicAlert from 'vue-basic-alert'
export default {
    name: "ViewCustomers",

    data() {
        return {
            allCustomer:[],
            itemsPerPage: 10,
            currentPage: 1,
            showPopup:false,
            customer:[],
            popObj:{cNic:"",cName:"",cContact:"",caddress:""},
            errorObj:{nameErr:[],conErr:[],addrErr:[]},
            selectedCus:'',
        }
    },

    computed:{
        ...mapState(["user"]),

paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.allCustomer)) {
        return this.allCustomer.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.allCustomer.length / this.itemsPerPage);
    },
    },
    created() {
      
      this.getAllCustomer()
  },

    methods: {
        ...mapMutations(["setUser"]),


nextPage(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        // Prevent default form submission behavior
        event.preventDefault();
},
prevPage(event) {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        // Prevent default form submission behavior
        event.preventDefault();
},
   
async getAllCustomer() {
        
        this.allCustomer= (await axios.get('/allcusofsabha/' +this.user.emp_prs_code)).data;
     
   },

        scrollToTop() {
            window.scrollTo(0, 0);
        },
        
        addZero(num) {

        num = num.toString();
            return '19' + num.slice(0, 5) + '0' + num.slice(5, -1);
        },
        addZeroPadding: function() {
        this.len= this.registerObj.nic.charAt(9).toUpperCase()
        // Check if the user input is a valid number
        if (!isNaN(parseInt(this.registerObj.nic)) && this.registerObj.nic !== null && (this.registerObj.nic.charAt(9).toUpperCase() === 'V' || this.registerObj.nic.charAt(9).toUpperCase() === 'X')) {
            
            this.paddedNum = this.addZero(this.registerObj.nic);
        
        }else{
            this.paddedNum=parseInt(this.registerObj.nic);
        }
        return this.paddedNum
        },

        async openPopup(id) {
            this.showPopup = true;
            document.addEventListener('mouseup', this.closeModalOnClickOutside);
            this.customer= (await axios.get('/cusbyid/'+id)).data;
            this.popObj.cNic= this.customer[0].cus_nic;
            this.popObj.cName =this.customer[0].cus_name;
            this.popObj.cContact =this.customer[0].cus_contact;
            this.popObj.caddress=this.customer[0].cus_address;
            this.selectedCus=id;
        },
        closePopup() {
            this.showPopup = false;
            document.removeEventListener('mouseup', this.closeModalOnClickOutside);
        },
        resetCheckPopErr: function () {
            this.errorObj.nameErr = [];
            this.errorObj.conErr = [];
            this.errorObj.addrErr = [];
          
        },
        checkPopEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
        checkPopup: function () {
            this.resetCheckPopErr();

            if (!this.popObj.cName) {
                this.errorObj.nameErr.push("Name is required");
            }

            if (!this.popObj.cContact){
                this.errorObj.conErr.push("Contact number is required");
            }
            if (!this.popObj.caddress){
                this.errorObj.addrErr.push("Address is required");
            }
        },
        async saveModal(s){
            this.checkPopup();

            if (!this.checkPopEmptyErr()) {
                s.preventDefault();
            }else{
                let popdata ={
                    cus_name : this.popObj.cName,
                    cus_contact : this.popObj.cContact,
                    cus_address : this.popObj.caddress,
                }
                await axios.put("/upcustdetail/"+this.selectedCus,popdata)
                this.$refs.alert.showAlert('success', 'Customer details updated Successfully !')
                this.allCustomer= (await axios.get('/allcusofsabha/' +this.user.emp_prs_code)).data;
                this.closePopup();
            }
        }
},
components: {
        VueBasicAlert
    }
}
</script>


<style scoped>
 .ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
 }
 th  {
     text-align: center;
}
.ttable, th, td {
border: 1px solid;
}
.register-container {
    padding: 2rem 9%;
    /* min-height: 72.3vh; */
}

.register-container .register-form-container {
    background: #fff;
    margin-bottom: 1.0rem;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.6rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
     border: margin-bottom; 
}

.register-container .register-form-container form label {
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
    color:#022e2a;
}

.register-container .register-form-container form .btn {
    /* margin: 1rem 0; */
    width: 25%;
    text-align: center;
    background: #022e2a;
}

.register-container .register-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group {
    margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
/* for table */
span{
    font-size: 2.0rem;
}
.admin-container {
    background-color: #fff;
    /* height: 100vh; */
    padding: 2rem 9%;
    font-size: 1.5rem;
    min-height: 73vh;
}
.admin-container span {
    color: #4e0707;
   
}

.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}
.popup-button {
      padding: 10px 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  }

  .modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
  }

  .modal-content {
      background-color: white;
      padding: 5px;
      border-radius: 1px;
      /* max-width: 500px; */
      width: 40%;
      position: relative;
      font-size: 1.3rem;
  }

  .close x {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 18px;
      color: #555;
  }

  .close-x:hover {
      color: #ff0000;
      /* Change to your desired hover color */
  }


  .modal-header {
      padding-bottom: 2px;
      border-bottom: 1px solid #ccc;
  }

  .modal-body {
      /* padding: 10px 20px 20px 20px; */
      padding-left: 5%; 

  }
  .model-group {
      padding: 10px ;
      /* padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 10%; */
      /* margin: 10px; */
      /* justify-content: left; */
      margin-right: 10px;
  }
  .modal-body.model-group .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 10%;
    padding: 0;
    width: 90%;

} 
.modal-body.row {
    display: flex;
    margin: 10%;
  }
  .modal-footer {
      padding-top: 5px;
      border-top: 1px solid #ccc;
      display: flex;
      justify-content: flex-end;
  }

  .close-button,
  .save-button {
      padding: 5px 10px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 4px;
      transition: background-color 0.3s ease;
  }

  .close-button {
      background-color: #f44336;
      color: white;
  }

  .save-button {
      background-color: #4CAF50;
      color: white;
      margin-left: 5px;
  }

  .close-button:hover {
      background-color: #d32f2f;
  }

  .save-button:hover {
      background-color: #45a049;
  }
 .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}
.error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
</style>
